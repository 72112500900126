import { Container, Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { InputCheck } from "../../controls";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import { action, controller, Server } from "../../api/Server";
import { ToFormData } from "../../hooks/Method";
import SearchProds from "./SearchProds";

const PosSettings = () => {
  const { t } = useTranslation();
  const { setLoader, selStore, setPageTitle } = useContext(AppContext);
  const [fltPosItems, setFltPosItems] = useState([]);
  const [posItems, setPosItems] = useState([]);

  useEffect(() => {
    setPageTitle && setPageTitle(t("POSItem"));
    if (selStore) {
      setLoader(true);
      Server(controller.PosItem + action.GetPosItems).getById(
        selStore,
        (res) => {
          setLoader(false);
          if (res && res.status === 200) {
            setPosItems([...res.result]);
            setFltPosItems([...res.result]);
          }
        }
      );
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const handleChange = (e, prodId) => {
    e.target.checked
      ? Server(controller.PosItem).post(
          ToFormData({ productId: prodId, storeId: selStore })
        )
      : Server(controller.PosItem).Delete(prodId);
  };

  return (
    <Container maxWidth="md">
      <Paper className="br-8 shadow-xs p-20">
        <Box className="tx-center mb-20">
          <Typography variant="subtitle1" className="fs-22 fw-5 gray-color">
            {t("allItems")}
          </Typography>
          <div className="bs-bottom mx-w-60 m-auto" />
        </Box>
        <Box className="tx-center mb-20">
          <SearchProds {...{ posItems, setFltPosItems }} />
        </Box>

        <Box
          sx={{
            height: "calc(100vh - 300px)",
          }}
        >
          {fltPosItems.map((prod, i) => (
            <div key={i}>
              <InputCheck
                onChange={(e) => handleChange(e, prod.id)}
                label={prod.name}
                defaultChecked={prod.isPosItem}
              />
              <Divider />
            </div>
          ))}
        </Box>
      </Paper>
    </Container>
  );
};

export default PosSettings;
