import { useState } from "react";

export default function UseForm(getFreshMdlObj) {
  const [values, setValues] = useState(getFreshMdlObj());
  const [errors, setErrors] = useState({});
  const [passValid, setPassValid] = useState({
    match: null,
    charNumberValid: false,
    specialCharValid: false,
    uppercaseValid: false,
    numberValid: false,
    passValid: false,
  });

  const isEmpty = (value, errorMessage) => (value !== "" ? "" : errorMessage);

  const checkNoErrors = (obj) => {
    return Object.values(obj).every((val) =>
      typeof val === "object" ? checkNoErrors(val) : val === ""
    );
  };

  const handleInpChg = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleFile = (obj) => {
    const { name, value } = obj;
    setValues({
      ...values,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  // Handle Input Password
  const handleInpPass = (e, confirmPass = false) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    if (!confirmPass) {
      setPassValid({
        charNumberValid: value.length >= 6 ? true : false,
        specialCharValid: /[ !@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/g.test(
          value
        )
          ? true
          : false,
        uppercaseValid: /[A-Z]/.test(value) ? true : false,
        numberValid: /[0-9]/.test(value) ? true : false,
        passValid:
          value.length >= 6 &&
          /[ !@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/.test(value) &&
          /[A-Z]/.test(value) &&
          /[0-9]/.test(value)
            ? true
            : false,
      });
    } else {
      setPassValid({
        ...passValid,
        match: values.password === value ? true : false,
      });
    }
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  // Handle Input Date Value
  const handleInpDate = (name, date) => {
    setValues({
      ...values,
      [name]: date,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  // Handle Input Check Value
  const handleInpCheck = (e) => {
    const { name, checked } = e.target;
    setValues({
      ...values,
      [name]: checked,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const resetFormControls = () => {
    setValues(getFreshMdlObj());
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    passValid,
    setPassValid,
    handleInpChg,
    handleFile,
    handleInpCheck,
    handleInpPass,
    handleInpDate,
    resetFormControls,
    isEmpty,
    checkNoErrors,
  };
}
