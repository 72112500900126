import { ClearRounded } from "@mui/icons-material";
import { Divider, Grid, IconButton } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Model, UseForm } from "../../../../hooks";
import { Button, Input, Select } from "../../../../controls";
import { AppContext } from "../../../../App";
import { action, controller, Server } from "../../../../api/Server";
import { Form } from "../../../../layout";

const PaymentForm = ({ setShowForm, setSellValues, needToPayAmt }) => {
  const [methods, setMethods] = useState([]);
  const { settings, selStore } = useContext(AppContext);
  const { t } = useTranslation();
  const { PaymentsMdl } = Model();
  const { values, setValues, errors, setErrors, handleInpChg } =
    UseForm(PaymentsMdl);
  const amountRef = useRef();

  useEffect(() => {
    settings &&
      setValues((j) => ({
        ...j,
        amount: needToPayAmt.toFixed(settings.decimal),
        payMethodId: settings.defaultPayMethodId || "",
      }));
    amountRef.current.focus();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToPayAmt, settings]);

  useEffect(() => {
    if (methods.length > 0) {
      var method = methods.find((j) => j.id === values.payMethodId);
      method &&
        method.id &&
        setValues((j) => ({ ...j, methodTitle: method.title }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.payMethodId, methods]);

  useEffect(() => {
    if (selStore) {
      Server(controller.PayMethod + action.GetAllByStore).getById(
        selStore,
        (res) => {
          if (res && res.status === 200) {
            var result = res.result;
            setMethods([...result.map((j) => ({ ...j, label: j.title }))]);
          }
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const validateForm = () => {
    let temp = {};
    temp.payMethodId = values.payMethodId !== "" ? "" : t("paymentMethodReq");
    temp.amount = values.amount !== "" ? "" : t("amountIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setSellValues((sell) => ({
        ...sell,
        payments: [...sell.payments, values],
      }));
      handleClose();
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <Form noValidate onSubmit={handleSubmit}>
      {/* Header */}
      <div className="d-flex a-center j-between p-5">
        <div className="border-left tx-upp fs-18 fw-5 gray-color ml-5">
          <span>{t("paymentForm")}</span>
        </div>
        <IconButton onClick={handleClose} size="small">
          <ClearRounded />
        </IconButton>
      </div>
      <Divider />
      {/* Body */}
      <div className="p-10 mt-10">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="d-flex g-15 j-between p-10 mb-10 bs-secondary br-8">
              <span className="fs-18 fw-5 gray-color tx-upp">
                {t("amountToPay")}
              </span>
              <span className="fs-20 fw-5 gray-color tx-upp">
                {settings.curSymbol} {needToPayAmt.toFixed(settings.decimal)}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              required
              label={t("amount")}
              name="amount"
              value={values.amount}
              error={errors.amount}
              onChange={handleInpChg}
              inputRef={amountRef}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              required
              label={t("method")}
              name="payMethodId"
              value={values.payMethodId}
              error={errors.payMethodId}
              onChange={handleInpChg}
              fullWidth
              options={methods}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label={t("note")}
              name="note"
              value={values.note}
              error={errors.note}
              onChange={handleInpChg}
              fullWidth
              rows={3}
              multiline
            />
          </Grid>

          <Grid item xs={12}>
            <div className="d-flex j-end g-15">
              <Button onClick={handleClose} variant="text" size="large">
                {t("close")}
              </Button>
              <Button type="submit" size="large" sx={{ minWidth: "120px" }}>
                {t("add")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </Form>
  );
};

export default PaymentForm;
