import { Grid } from "@mui/material";
import React from "react";
import { Input, Select } from "../../../controls";
import { useTranslation } from "react-i18next";
import { DropLi } from "../../../hooks";
import { MaxLength } from "../../../hooks/Method";
import ReceiptSettings from "./ReceiptSettings";

const SettingsFields = ({
  values,
  currs,
  methods,
  errors,
  handleInpChg,
  handleInpCheck,
}) => {
  const { t } = useTranslation();
  const { Decimals, EmailInvoice, ProductLabel } = DropLi();

  return (
    <div className="d-grid g-25">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Input
            label={t("salesTermForReceipt")}
            name="salesTermForReceipt"
            value={values.salesTermForReceipt}
            onChange={handleInpChg}
            fullWidth
            multiline
            rows={4}
            inputProps={{ maxLength: 500 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label={t("taxPercentage")}
            name="tax"
            value={values.tax}
            onChange={handleInpChg}
            onInput={(e) => (e.target.value = MaxLength(e.target.value, 12))}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Select
            label={t("emailInvoice")}
            name="emailInvoice"
            required={true}
            value={values.emailInvoice}
            onChange={handleInpChg}
            fullWidth
            options={EmailInvoice}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label={t("currency")}
            name="currId"
            required={true}
            value={values.currId}
            error={errors.currId}
            onChange={handleInpChg}
            options={currs}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label={t("allowDecimal")}
            name="decimal"
            required={true}
            value={values.decimal}
            error={errors.decimal}
            onChange={handleInpChg}
            options={Decimals}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label={t("defaultPaymentMethod")}
            name="defaultPayMethodId"
            required={true}
            value={values.defaultPayMethodId}
            error={errors.defaultPayMethodId}
            onChange={handleInpChg}
            options={methods}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            label={t("productLabel")}
            name="productLabel"
            required={true}
            value={values.productLabel}
            error={errors.productLabel}
            onChange={handleInpChg}
            options={ProductLabel}
          />
        </Grid>
      </Grid>
      <ReceiptSettings
        {...{
          values,
          errors,
          handleInpChg,
          handleInpCheck,
        }}
      />
    </div>
  );
};

export default SettingsFields;
