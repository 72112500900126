import React, { useContext, useState } from "react";
import { Table } from "../../layout";
import { AppContext } from "../../App";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { CSVLink } from "react-csv";
import { ChangeArrayKey } from "../../hooks/Method";
import { action, controller, Server } from "../../api/Server";

const EmployeeReport = () => {
  const { t } = useTranslation();
  const { setLoader, selStore, settings, setPageTitle } =
    useContext(AppContext);
  const [csvHeader, setCsvHeader] = useState([]);

  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    if (selStore) {
      setLoader(true);
      Server(controller.Report + action.SellByEmployee).getById(
        selStore,
        (res) => {
          setLoader(false);
          res && res.status === 200 && setEmployees([...res.result]);
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    setPageTitle(t("employeeReport"));
    setCsvHeader([
      { key: "employeeName", label: t("employeeName") },
      { key: "totalSale", label: t("totalSale") },
      { key: "totalVoidSale", label: t("voidSale") },
      { key: "totalRefund", label: t("refund") },
      { key: "totalCancelItem", label: t("itemCancel") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={employees}
          headers={csvHeader}
          filename={`${t("employeeReport")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={() => <div></div>}
        HeaderComp={HeaderComp}
        tblHead={[...ChangeArrayKey(csvHeader, "key", "id")]}
        tblData={employees.map((employee) => {
          return {
            ...employee,
            totalSale:
              settings.curSymbol +
              " " +
              employee.totalSale.toFixed(settings.decimal),
            totalVoidSale:
              settings.curSymbol +
              " " +
              employee.totalVoidSale.toFixed(settings.decimal),
            totalRefund:
              settings.curSymbol +
              " " +
              employee.totalRefund.toFixed(settings.decimal),
            totalCancelItem: employee.totalCancelItem.toFixed(settings.decimal),
          };
        })}
      />
    </>
  );
};

export default EmployeeReport;
