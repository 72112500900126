import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { controller, endpoints } from "../../api/actions/api";
import { AppContext } from "../../App";
import { Input } from "../../controls";
import * as actions from "../../api/actions/pos";
import { useTranslation } from "react-i18next";
import { Form } from "../../layout";

const CashInDrawer = (props) => {
  const { _get, setShow, _authUser } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setCLoader } = useContext(AppContext);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [totalInDrawer, setTotalInDrawer] = useState(0);

  useEffect(() => {
    setTotalInDrawer(parseFloat(value | 0));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleSubmit = () => {
    if (value !== "") {
      localStorage.setItem(
        "posOpen",
        JSON.stringify({
          userId: _authUser.id,
          cashInDrawer: totalInDrawer,
          amount: value,
          timeIn: new Date().toLocaleString(),
        })
      );
      setShow(false);
    } else setError(t("amountIsRequired"));
  };

  const handleLogout = () => {
    setCLoader(true);
    const onComplete = (res) => {
      setCLoader(false);
      navigate("/");
    };
    _get(
      endpoints.Auth + controller.Logout,
      actions.ActionType.Auth,
      onComplete
    );
  };

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={3} className="p-15 pt-30 bg-grayXS">
        <Grid item xs={12} className="tx-center">
          <Typography variant="h5" className="fs-22 gray-color fw-5 tx-upp">
            {t("cashInDrawer")}
          </Typography>
          <div className="bs-bottom mx-w-80 m-auto mt-5 mb-20" />
        </Grid>
        <Grid item xs={12}>
          <Input
            onChange={(e) => setValue(e.target.value)}
            label={t("amount")}
            name="amount"
            type="number"
            error={error}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} className="d-flex g-10">
          <Button
            type="submit"
            onClick={handleSubmit}
            className="btn-secondary w-100pr"
            size="large"
          >
            {t("Enter")}
          </Button>
          <Button
            onClick={() => navigate("/")}
            className="bg-graySM dark-color w-100pr"
            size="large"
          >
            {t("back")}
          </Button>
          <Button
            onClick={handleLogout}
            className="error-color bg-errorSM w-100pr"
            size="large"
          >
            {t("logout")}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(CashInDrawer);
