import React, { useContext, useEffect, useState } from "react";
import { Dialog, Table } from "../../../layout";
import { Box, IconButton, Tooltip } from "@mui/material";
import { InputDate } from "../../../controls";
import { subDays } from "date-fns";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/pos";
import { controller, endpoints } from "../../../api/actions/api";
import { AppContext } from "../../../App";
import { PaymentsRounded, VisibilityRounded } from "@mui/icons-material";
import ViewProd from "../../sells/ViewProd";
import MakePayment from "./MakePayment";

const Receipt = ({ cstmr, _getById }) => {
  const { t } = useTranslation();
  const { settings } = useContext(AppContext);
  const [sells, setSells] = useState([]);
  const [viewData, setViewData] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [paymentData, setPaymentData] = useState();
  const [showPayForm, setShowPayForm] = useState(false);
  const [fltSells, setFltSells] = useState([]);
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/31/${new Date().getFullYear()}`,
  });

  useEffect(() => {
    setFltSells([
      ...sells.filter(
        (j) =>
          moment(new Date(j.sellDate), "MM/DD/YYYY") >=
            moment(sortDate.fromDate, "MM/DD/YYYY") &&
          moment(new Date(j.sellDate), "MM/DD/YYYY") <=
            moment(sortDate.toDate, "MM/DD/YYYY")
      ),
    ]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDate, sells]);

  useEffect(() => {
    cstmr &&
      _getById(
        endpoints.Sell + controller.GetSellsByCstmr,
        cstmr.id,
        null,
        (res) => {
          setSells([...res.result]);
        }
      );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cstmr]);

  const handleDate = (name, newVal) => {
    setSortDate((x) => ({ ...x, [name]: newVal }));
  };

  const handleView = (data) => {
    setViewData(data);
    setShowForm(!showForm);
  };

  const handleViewPay = (data) => {
    setPaymentData(data);
    setShowPayForm(true);
  };

  const HeaderComp = () => (
    <Box className="d-flex a-center w-100pr">
      <InputDate
        variant="outlined"
        fullWidth
        size="small"
        name="fromDate"
        value={sortDate.fromDate}
        views={["day", "month", "year"]}
        openTo="day"
        minDate={new Date("01/01/2000")}
        maxDate={subDays(new Date(sortDate.toDate), 1)}
        onChange={(date) =>
          handleDate("fromDate", new Date(date).toLocaleDateString())
        }
      />
      <span className="ml-10 mr-10 fs-16 dark-color">TO</span>
      <InputDate
        variant="outlined"
        fullWidth
        size="small"
        name="toDate"
        value={sortDate.toDate}
        minDate={moment(sortDate.fromDate, "MM/DD/YYYY").add(1, "days")}
        views={["day", "month", "year"]}
        openTo="day"
        onChange={(date) =>
          handleDate("toDate", new Date(date).toLocaleDateString())
        }
      />
    </Box>
  );

  const ActionComp = (props) => (
    <Box className="d-flex g-5">
      <Tooltip arrow title={t("view")}>
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          color="primary"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      {props.sellType === 2 && (
        <Tooltip arrow title={t("payment")}>
          <IconButton
            onClick={() => handleViewPay(props)}
            size="small"
            color="success"
          >
            <PaymentsRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          { id: "status", label: t("status") },
          { id: "date", label: t("date") },
          { id: "inv", label: t("invoice") },
          { id: "accounts", label: t("accounts") },
        ]}
        tblData={fltSells.map((j) => {
          var status = j.sellType === 1 ? t("paid") : t("due");
          var totalItem = j.sellItems
            .map((x) => parseFloat(x.qty))
            .reduce((x, y) => x + y, 0);

          var isOverdue =
            j.sellType === 2 &&
            moment(new Date()) > moment(new Date(j.dueDate), "MM/DD/YYYY")
              ? true
              : false;
          return {
            ...j,
            statusTax: `status ${status}`,
            inv: (
              <div className="fs-14 d-grid g-5">
                <div>
                  <span>{t("invoiceID")}: </span>
                  <span className="fw-5">{j.id}</span>
                </div>
                <div>
                  <span>{t("totalItem")}: </span>
                  <span className="fw-5">{totalItem}</span>
                </div>
              </div>
            ),
            status: (
              <div className="fw-5">
                {j.sellType === 2 ? (
                  <span className="bg-errorSM error-color pr-10 pl-10 pt-5 pb-5 br-25 fs-12 tx-upp">
                    {status}
                  </span>
                ) : (
                  <span className="bg-successXS success-color pr-10 pl-10 pt-5 pb-5 br-25 fs-12 tx-upp">
                    {status}
                  </span>
                )}
              </div>
            ),
            date: (
              <div className="fs-14 d-grid">
                <div>
                  <span>{t("sellDate")}: </span>
                  <span className="fw-5">
                    {new Date(j.sellDate).toLocaleString()}
                  </span>
                </div>
                <div className="d-flex g-10">
                  {j.dueDate && (
                    <div className={isOverdue ? "error-color" : ""}>
                      <span>{t("dueDate")}: </span>
                      <span className="fw-5">
                        {new Date(j.dueDate).toLocaleString()}
                      </span>
                    </div>
                  )}
                  {j.paidDate && (
                    <>
                      |
                      <div>
                        <span>{t("paidDate")}: </span>
                        <span className="fw-5">
                          {new Date(j.paidDate).toLocaleString()}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ),

            accounts: (
              <div className="fs-14 d-grid g-5 tx-upp">
                <div className="d-flex g-10">
                  <div className={isOverdue ? "error-color" : ""}>
                    <span>{t("due")}:&nbsp;&nbsp;</span>
                    <span
                      className={`fw-5 ${j.paymentDue > 0 && "error-color"}`}
                    >
                      {settings.curSymbol + " " + j.dueAmt.toLocaleString()}{" "}
                    </span>
                  </div>
                  |
                  <div>
                    <span>{t("paid")}:&nbsp;&nbsp;</span>
                    <span className="fw-5">
                      {settings.curSymbol +
                        " " +
                        parseFloat(
                          (
                            parseFloat(j.totalPay) - parseFloat(j.dueAmt)
                          ).toFixed(3)
                        ).toLocaleString()}
                    </span>
                  </div>
                </div>
                <div className="d-flex g-10">
                  <div className="fw-5">
                    <span>Total:&nbsp;&nbsp;</span>
                    <span>
                      {settings.curSymbol + " " + j.totalPay.toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
            ),
          };
        })}
      />

      {/* Edit Dialog */}
      {viewData.id && (
        <Dialog show={showForm} maxWidth="lg" onClose={false}>
          <ViewProd {...{ setShowForm, id: viewData.id }} />
        </Dialog>
      )}

      {showPayForm && (
        <Dialog show={showPayForm} onClose={false}>
          <MakePayment
            {...{ setShowForm: setShowPayForm, paymentData, setSells }}
          />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(Receipt);
