import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Input, InputAuto, InputMultImg, Select } from "../../../controls";
import { useTranslation } from "react-i18next";
import { Server, action, controller } from "../../../api/Server";
import { AppContext } from "../../../App";
import { DropLi } from "../../../hooks";
import { MaxLength } from "../../../hooks/Method";

const AdditionalFields = ({
  imgSrc,
  values,
  setValues,
  errors,
  handleFile,
  handleInpChg,
}) => {
  const { t } = useTranslation();
  const { authUser, selStore } = useContext(AppContext);
  const [cats, setCats] = useState([]);
  const [mainCats, setMainCats] = useState([]);
  const [subCat, setSubCat] = useState([]);
  const { UnitType, BoolType } = DropLi();

  useEffect(() => {
    setMainCats([
      ...cats
        .filter((j) => !j.parentCatId)
        .map((j) => ({ ...j, label: j.name })),
    ]);
    return () => {};
  }, [cats]);

  // Set Subcategory
  useEffect(() => {
    setSubCat([
      ...cats
        .filter((j) => j.parentCatId === values.categoryId)
        .map((j) => ({ ...j, label: j.name })),
    ]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.categoryId]);

  useEffect(() => {
    // Get Categories
    if (authUser && authUser.id) {
      Server(controller.Category + action.GetAllByStore).getById(
        selStore,
        (res) => {
          res.status === 200 && setCats([...res.result]);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  return (
    <Grid container spacing={2.5}>
      <Grid item xs={12} md={6}>
        <InputAuto
          label={t("category")}
          name="categoryId"
          fullWidth
          value={values.categoryId}
          error={errors.categoryId}
          onChange={handleInpChg}
          options={mainCats}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputAuto
          fullWidth
          label={t("subcategory")}
          name="parentCatId"
          value={values.parentCatId}
          error={errors.parentCatId}
          onChange={handleInpChg}
          options={subCat}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <Input
          label={t("alertQty")}
          type="number"
          name="alertQty"
          onInput={(e) => (e.target.value = MaxLength(e.target.value, 10))}
          value={values.alertQty}
          onChange={handleInpChg}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Input
          label={t("reOrderPoint")}
          type="number"
          name="reOrderPoint"
          onInput={(e) => (e.target.value = MaxLength(e.target.value, 10))}
          value={values.reOrderPoint}
          onChange={handleInpChg}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Select
          label={t("unitType")}
          name="unit"
          fullWidth
          value={values.unit}
          error={errors.unit}
          onChange={handleInpChg}
          options={UnitType}
          required
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Select
          label={t("tax")}
          name="tax"
          fullWidth
          value={values.tax}
          error={errors.tax}
          onChange={handleInpChg}
          options={BoolType}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label={t("productDescription")}
          multiline
          rows={4}
          name="descr"
          value={values.descr}
          error={errors.descr}
          onChange={handleInpChg}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <div className="d-flex flex-wrap g-10">
          <InputMultImg
            label={t("productImages")}
            name="imgsFile"
            maxUpl={5}
            src={imgSrc}
            values={values.imgsFile}
            onChange={handleFile}
            rmv={(file) => {
              setValues((x) => ({
                ...x,
                imgsFile: x.imgsFile.filter(
                  (j) => j.lastModified !== file.lastModified
                ),
                imgs: x.imgs.filter((j) => j.id !== file.id),
              }));
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default AdditionalFields;
