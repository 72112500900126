import { LocalPrintshopRounded, VisibilityRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Alert, Dialog, Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { controller, endpoints } from "../../api/actions/api";
import { IsEmptyObj, PrintDiv } from "../../hooks/Method";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import ViewSellReturn from "./ViewSellReturn";
import ThermalPrinterRecept from "./printReceipt/ThermalPrinterReceipt";
import GeneralPrinterReceipt from "./printReceipt/GeneralPrinterReceipt";

const SellReturns = (props) => {
  const { _getById } = props;
  const { t } = useTranslation();
  const { setLoader, selStore, settings, setPageTitle, pageTitle } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [viewData, setViewData] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (selStore && settings) {
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 &&
          setTblData([
            ...res.result.map((sell) => {
              return {
                ...sell,
                totalItem: sell.items.length.toFixed(settings.decimal),
                refundAmt: `${settings.curSymbol} ${sell.refundAmt.toFixed(
                  settings.decimal
                )}`,
                refundTax: `${settings.curSymbol} ${sell.refundTaxAmt.toFixed(
                  settings.decimal
                )}`,
                cutDiscount: `${settings.curSymbol} ${sell.cutDiscount.toFixed(
                  settings.decimal
                )}`,
                payableRefund: `${
                  settings.curSymbol
                } ${sell.payableRefund.toFixed(settings.decimal)}`,
                cashier: sell.user && sell.user.fullName,
              };
            }),
          ]);
      };

      setLoader(true);
      _getById(
        endpoints.SellReturn + controller.GetAllByStore,
        selStore,
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore, settings]);

  useEffect(() => {
    setPageTitle(t("sellReturns"));
    setCsvHeader([
      { key: "id", label: t("returnId") },
      { key: "sellId", label: t("invoiceID") },
      { key: "refundDate", label: t("refundDate") },
      { key: "totalItem", label: t("totalItem") },
      { key: "totalSellable", label: t("sellable") },
      { key: "totalUnsellable", label: t("unsellable") },
      { key: "refundAmt", label: t("refundAmount") },
      { key: "refundTax", label: t("tax") },
      { key: "cutDiscount", label: t("discount") },
      { key: "payableRefund", label: t("payableRefund") },
      { key: "cashier", label: t("cashier") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleView = (data) => {
    setViewData(data);
    setShowForm(!showForm);
  };

  const handlePrint = (val) => {
    setViewData(val);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      PrintDiv("printSellReturnRecept");
    }, 1500);
  };

  const ActionComp = (props) => (
    <Box className="d-flex g-10 a-center">
      <Tooltip arrow title={t("view")}>
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          className="btn-primary mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>

      <IconButton
        disabled={loading}
        onClick={() => handlePrint(props)}
        size="small"
        color="primary"
        className=" mr-5 bg-primaryXS"
      >
        <LocalPrintshopRounded className="fs-18 m-2" />
      </IconButton>
    </Box>
  );

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={tblData}
          headers={csvHeader}
          filename={`${t("sellReturn")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      {/* Page Title */}
      <div className="mb-15 mt-20">
        <span className="border-left fs-22 gray-color">{pageTitle}</span>
      </div>

      {viewData.id && (
        <div className="d-none" id="printReceitp">
          {settings && settings.store && settings.store.receiptType === 2 ? (
            <GeneralPrinterReceipt data={viewData} />
          ) : (
            <ThermalPrinterRecept data={viewData} />
          )}
        </div>
      )}

      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          { id: "ids", label: t("id") },
          { id: "totalReturn", label: t("totalReturn") },
          { id: "taxAndDiscount", label: t("taxAndDiscount") },
          { id: "refund", label: t("refund") },
          { id: "cashier", label: t("cashierAndRefundDate") },
        ]}
        tblData={tblData.map((item) => {
          return {
            ...item,
            ids: (
              <div className="fs-14 gray-color d-grid g-5">
                <div>
                  <span>{t("returnId")}: </span>
                  <span className="fw-5">{item.id}</span>
                </div>
                <div>
                  <span>{t("invoiceID")}: </span>
                  <span className="fw-5"> {item.sellId}</span>
                </div>
              </div>
            ),
            totalReturn: (
              <div className="fs-14 gray-color d-grid g-5">
                <div className="d-flex g-5">
                  <div>
                    <span>{t("total")}: </span>
                    <span className="fw-5">{item.totalItem}</span>
                  </div>
                  |
                  <div>
                    <span>{t("sellable")}: </span>
                    <span className="fw-5"> {item.totalSellable}</span>
                  </div>
                </div>

                <div className={`${item.totalUnsellable && "error-color"}`}>
                  <span>{t("unsellable")}: </span>
                  <span className="fw-5"> {item.totalUnsellable}</span>
                </div>
              </div>
            ),
            taxAndDiscount: (
              <div className="fs-14 gray-color d-grid g-5">
                <div>
                  <span>{t("refundTax")}: </span>
                  <span className="fw-5">{item.refundTax}</span>
                </div>
                <div>
                  <span>{t("revDiscount")}: </span>
                  <span className="fw-5">{item.cutDiscount}</span>
                </div>
              </div>
            ),
            refund: (
              <div className="fs-14 gray-color d-grid g-5">
                <div>
                  <span>{t("refund")}: </span>
                  <span className="fw-5">{item.refundAmt}</span>
                </div>
                <div className="primary-color fw-5">
                  <span>{t("payableRefund")}: </span>
                  <span className="fw-5">{item.payableRefund}</span>
                </div>
              </div>
            ),
            cashier: (
              <div className="fs-14 gray-color d-grid">
                <span className="fw-5">{item.cashier}</span>
                <span className="fw-5 fs-12">
                  {new Date(item.refundDate).toLocaleString()}
                </span>
              </div>
            ),
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {/* Edit Dialog */}
      <Dialog show={showForm} maxWidth="lg" onClose={false}>
        {!IsEmptyObj(viewData) && (
          <ViewSellReturn
            {...{
              _getById,
              setShowForm,
              id: viewData.id,
            }}
          />
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(SellReturns);
