import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { useTranslation } from "react-i18next";

const ThermalPrinterRecept = ({ data }) => {
  const { settings, selStore, stores } = useContext(AppContext);
  const [store, setStore] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setStore({ ...stores.find((j) => j.id === selStore) });
    return () => {};
  }, [selStore, stores]);

  return data.id && store.id ? (
    <div
      style={{
        maxWidth: "58mm",
        width: "100%",
        fontFamily: "'Courier New', Courier, monospace",
        fontSize: "10px",
        padding: "15px 10px",
        lineHeight: 1.4,
      }}
    >
      {/* Store Header */}
      <div
        style={{
          textAlign: "center",
          marginBottom: "15px",
          paddingBottom: "10px",
          borderBottom: "2px double #333",
        }}
      >
        {store.logo && (
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={store.logo}
              alt="store_logo"
              style={{
                maxHeight: "60px",
                maxWidth: "120px",
                objectFit: "contain",
                filter: "grayscale(100%)",
              }}
            />
          </div>
        )}
        <div
          style={{
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "0.5px",
          }}
        >
          {store.storeLocalName || store.storeName}
        </div>
        <div
          style={{
            fontSize: "10px",
            color: "#444",
            lineHeight: 1.3,
          }}
        >
          {store.city ? `${store.city}, ` : ""}
          {store.state ? `${store.state} ` : ""}
          {store.zip ? `${store.zip}` : ""}
          <br />
          {store.address}
          <br />
          {store.phone}
        </div>
      </div>

      {/* Transaction Details */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "8px",
          marginBottom: "15px",
        }}
      >
        <div>
          <div style={{ fontWeight: "600" }}>{t("invoiceNo")}</div>
          <div>#{data.sellId}</div>
        </div>
        <div>
          <div style={{ fontWeight: "600" }}>{t("sellReturnId")}</div>
          <div>#{data.id}</div>
        </div>
        <div>
          <div style={{ fontWeight: "600" }}>{t("sellDate")}</div>
          <div>
            {data.sell && new Date(data.sell.sellDate).toLocaleString()}
          </div>
        </div>
        <div>
          <div style={{ fontWeight: "600" }}>{t("refundDate")}</div>
          <div>{new Date(data.refundDate).toLocaleString()}</div>
        </div>
      </div>

      {/* Customer Details */}
      {data.sell.cstmr && (
        <div
          style={{
            marginBottom: "15px",
          }}
        >
          <div style={{ fontWeight: "600" }}>{t("customer")}</div>
          <div>{data.sell.cstmr.fullName}</div>
          <div>{data.sell.cstmr.phone}</div>
        </div>
      )}

      {/* Items Table */}
      <div
        style={{
          marginBottom: "15px",
          borderBottom: "2px double #333",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "3fr 1fr 1.5fr",
            gap: "5px",
            fontWeight: "600",
            marginBottom: "8px",
          }}
        >
          <div>{t("itemName")}</div>
          <div style={{ textAlign: "center" }}>{t("qty")}</div>
          <div style={{ textAlign: "right" }}>{t("price")}</div>
        </div>

        {data.items?.map((item, i) => (
          <div
            key={i}
            style={{
              display: "grid",
              gridTemplateColumns: "3fr 1fr 1.5fr",
              gap: "5px",
              marginBottom: "6px",
            }}
          >
            <div>{item.product.label}</div>
            <div style={{ textAlign: "center" }}>{item.returnQty}</div>
            <div style={{ textAlign: "right" }}>
              {settings.curSymbol} {item.price.toFixed(settings.decimal)}
            </div>
          </div>
        ))}
      </div>

      {/* Totals Section */}
      <div
        style={{
          display: "grid",
          gap: "6px",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{t("refund")}:</span>
          <span>
            {settings.curSymbol} {data.refundAmt.toFixed(settings.decimal)}
          </span>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{t("discount")}:</span>
          <span>
            -{settings.curSymbol} {data.cutDiscount.toFixed(settings.decimal)}
          </span>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{t("tax")}:</span>
          <span>
            +{settings.curSymbol} {data.refundTaxAmt.toFixed(settings.decimal)}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "600",
            paddingTop: "8px",
            borderTop: "1px solid #ddd",
          }}
        >
          <span>{t("payableRefund")}:</span>
          <span>
            {settings.curSymbol} {data.payableRefund.toFixed(settings.decimal)}
          </span>
        </div>
      </div>

      {/* Footer */}
      <div
        style={{
          fontSize: "10px",
          color: "#666",
          lineHeight: 1.3,
          borderTop: "2px double #333",
          paddingTop: "10px",
          whiteSpace: "pre-wrap",
          overflowWrap: "break-word",
          maxWidth: "100%",
        }}
      >
        {settings.salesTermForReceipt}
      </div>
    </div>
  ) : null;
};

export default ThermalPrinterRecept;
