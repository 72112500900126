import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Input } from "../../controls";
import { Form } from "../../layout";
import { Model, UseForm } from "../../hooks";
import { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { endpoints } from "../../api/actions/api";
import { ToFormData } from "../../hooks/Method";
import { useTranslation } from "react-i18next";

const AddEditCat = (props) => {
  const { updData, setShowForm, _post, _put } = props;
  const { ExpsCatMdl } = Model();
  const { t } = useTranslation();

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    resetFormControls,
  } = UseForm(ExpsCatMdl);

  const validateForm = () => {
    let temp = {};
    temp.name = values.name !== "" ? "" : t("expenseNameIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const onComplete = () => {
        setShowForm(false);
      };
      if (updData === undefined) {
        _post(
          endpoints.ExpenseCat,
          ToFormData(values),
          actions.ActionType.AddItem,
          onComplete
        );
      } else if (updData !== undefined && updData.id !== undefined) {
        _put(
          endpoints.ExpenseCat,
          ToFormData(values),
          actions.ActionType.UpdateItem,
          onComplete
        );
      }
    }
  };

  useEffect(() => {
    updData && setValues(updData);
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData]);

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={2} className="p-20 pt-40 bg-grayXS">
        <Grid item xs={12} className="tx-center">
          <Typography variant="h5" className="fs-22 gray-color fw-5 tx-upp">
            {updData === undefined ? t("add") : t("update")}{" "}
            {t("expenseCategory")}
          </Typography>
          <div className="bs-bottom mx-w-80 m-auto mt-10 mb-30" />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={t("expenseName")}
            name="name"
            value={values.name}
            error={errors.name}
            onChange={handleInpChg}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={t("expenseDescription")}
            multiline
            rows={4}
            name="descr"
            value={values.descr}
            error={errors.descr}
            onChange={handleInpChg}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          className="tx-right d-flex mt-20"
          sx={{ flexWrap: { xs: "wrap", md: "initial" } }}
        >
          <Button type="submit" className="w-100pr" size="large">
            {updData === undefined ? t("submit") : t("saveChanges")}
          </Button>
          <Button
            onClick={() => setShowForm(false)}
            className="error-color bg-errorSM w-100pr"
            size="large"
            sx={{ ml: { md: "10px" }, mt: { xs: "15px", md: "0px" } }}
          >
            {t("close")}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditCat);
