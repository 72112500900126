import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, MenuPopup } from "../../layout";
import { Box, Grid, ListItemIcon, MenuItem } from "@mui/material";
import { ExpenseForm, CloseReg } from "../pos";
import {
  HomeRounded,
  PointOfSale,
  AttachMoneyRounded,
  CategoryRounded,
  CloseRounded,
} from "@mui/icons-material";
import { useContext } from "react";
import { AppContext } from "../../App";
import { Button, Select } from "../../controls";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  const { setSelStore, selStore, stores, setStores, authUser } =
    useContext(AppContext);
  const navigate = useNavigate();
  const [posEl, setPosEl] = useState(null);
  const [expForm, setExpForm] = useState(false);
  const [regForm, setRegForm] = useState(false);

  useEffect(() => {
    if (authUser && authUser.id) {
      // Stores
      setStores([
        ...authUser.stores.map((j) => ({
          ...j.store,
          label: j.store ? j.store.storeName : "",
        })),
      ]);

      // Default Store
      var defStore =
        authUser.stores.find((j) => j.store && j.store.defStore) ||
        authUser.stores[0];
      defStore && setSelStore(defStore.storeId);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const handleChgStore = (e) => {
    var store = stores.find((j) => j.id === e.target.value);
    if (store) {
      setSelStore(store);
      setSelStore(store.id);
    }
  };

  const handleNavigate = (url) => {
    navigate(url);
  };
  const handlePos = (e) => {
    setPosEl(e.currentTarget);
  };

  return (
    <Box
      className="d-flex a-center w-100pr p-8"
      sx={{ justifyContent: { md: "space-between", xs: "end" } }}
    >
      <Box
        className="ml-10 gray-color"
        sx={{
          display: { md: "block", xs: "none" },
        }}
      >
        <div className="d-grid ">
          <span className="fs-14">{t("welcome")} 🎉</span>
          <span className="fw-5 fs-20">{authUser && authUser.fullName}</span>
        </div>
      </Box>
      <div className="d-flex a-center">
        {/* Shop */}
        {authUser.role === 1 && (
          <Grid className="mr-10">
            <Select
              sx={{ minWidth: "150px" }}
              variant="outlined"
              size="small"
              name="shop"
              fullWidth
              value={selStore}
              onChange={handleChgStore}
              options={stores}
            />
          </Grid>
        )}
        {/* POS Menu */}
        {authUser &&
          authUser.featuresAccess &&
          authUser.featuresAccess.isHavePosAccess && (
            <Grid>
              <Button
                size="large"
                id="pos-menu"
                onClick={handlePos}
                startIcon={<PointOfSale />}
              >
                {t("POS")}
              </Button>
              <MenuPopup anchorEl={posEl} setAnchorEl={setPosEl} id="pos-menu">
                <MenuItem onClick={() => handleNavigate("/pos")}>
                  <ListItemIcon>
                    <HomeRounded fontSize="small" />
                  </ListItemIcon>
                  {t("POS")}
                </MenuItem>
                <MenuItem onClick={() => handleNavigate("/pos/categories")}>
                  <ListItemIcon>
                    <CategoryRounded fontSize="small" />
                  </ListItemIcon>
                  {t("category")}
                </MenuItem>
                <MenuItem onClick={() => setExpForm(true)}>
                  <ListItemIcon>
                    <AttachMoneyRounded fontSize="small" />
                  </ListItemIcon>
                  {t("expenses")}
                </MenuItem>
                <MenuItem onClick={() => setRegForm(true)}>
                  <ListItemIcon>
                    <CloseRounded fontSize="small" />
                  </ListItemIcon>
                  {t("closeRegister")}
                </MenuItem>
              </MenuPopup>
              {/* Dialog */}
              {expForm && (
                <Dialog show={expForm} onClose={false} maxWidth="xs">
                  <ExpenseForm {...{ setShowForm: setExpForm }} />
                </Dialog>
              )}
              <Dialog
                show={regForm}
                onClose={false}
                maxWidth="md"
                sx={{
                  "& .MuiDialog-paper": {
                    margin: "15px",
                  },
                }}
              >
                {regForm && <CloseReg {...{ setShowForm: setRegForm }} />}
              </Dialog>
            </Grid>
          )}
      </div>
    </Box>
  );
};

export default Header;
