import { Grid } from "@mui/material";
import React from "react";
import { Input } from "../../controls";
import { useTranslation } from "react-i18next";

const GeneralFields = ({ values, errors, org, handleInpChg }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Input
          label={t("organizationName")}
          name="name"
          required={true}
          value={values.name}
          error={errors.name}
          onChange={handleInpChg}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label={t("localeOrgName")}
          name="nameLocale"
          value={values.nameLocale}
          error={errors.nameLocale}
          onChange={handleInpChg}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label={t("email")}
          name="email"
          required={true}
          value={values.email}
          error={errors.email}
          onChange={handleInpChg}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label={t("phone")}
          name="phone"
          required={true}
          value={values.phone}
          error={errors.phone}
          onChange={handleInpChg}
        />
      </Grid>
    </Grid>
  );
};

export default GeneralFields;
