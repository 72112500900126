import {
  Autocomplete,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import {
  Button,
  Input,
  InputAuto,
  InputDate,
  InputFile,
  InputQty,
  Select,
} from "../../controls";
import { Alert, Dialog, Form } from "../../layout";
import { DropLi, Model, UseForm } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { ClearRounded, PercentRounded } from "@mui/icons-material";
import { useEffect } from "react";
import { CleanObject, MaxLength, ToFormData } from "../../hooks/Method";
import AddEditProd from "../products/ProductForm/AddEditProd";
import { useContext } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import { action, controller, Server } from "../../api/Server";

const AddMultPurch = () => {
  const { settings, authUser } = useContext(AppContext);
  const { PurchaseMdl } = Model();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoader, selStore, setPageTitle } = useContext(AppContext);
  const { PaymentType } = DropLi();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleFile,
    handleInpChg,
    handleInpDate,
  } = UseForm(PurchaseMdl);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const [searchVal, setSearchVal] = useState("");
  const [showPurchForm, setShowPurchForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [searchLi, setSearchLi] = useState([]);
  const [netAmount, setNetAmount] = useState(0);
  const [disAmt, setDisAmt] = useState(0);
  const [findErr, setFindErr] = useState("");
  const [disableAmt, setDisableAmt] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    setPageTitle("Add Purchase");
    // Set Focuse in Searchbox
    document.getElementById("searchBox").focus();

    if (selStore) {
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 &&
          setSuppliers([
            ...res.result.map((j) => {
              return {
                id: j.id,
                label: `${j.fullName} (${j.phone})`,
              };
            }),
          ]);
      };
      setLoader(true);
      Server(controller.Supplier + action.GetByStore).getById(
        selStore,
        onComplete
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    var _netAmount = values.purchItems
      .map((j) => j.qty * j.purchPrice)
      .reduce((x, y) => x + y, 0)
      .toFixed(settings.decimal);
    setNetAmount(_netAmount);
    setDisAmt(
      (
        (parseFloat(values.discount || 0) / 100) *
        parseFloat(_netAmount)
      ).toFixed(settings.decimal)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.purchItems, values.discount]);

  useEffect(() => {
    const onComplete = (res) => {
      if (res.status === 200 && res.result.purchItems) {
        setValues({
          ...values,
          ...CleanObject({ ...res.result }),
          discount: res.result.discount || "",
          paymentDue: res.result.paymentDue || "",
          recept: res.result.recept || "",
        });
      }
    };

    if (id && selStore)
      // _getByObj(
      //   endpoints.Purchase + controller.GetById,
      //   { id: id, storeId: selStore },
      //   null,
      //   onComplete
      // );
      Server(controller.Purchase + action.GetById).post(
        { id: id, storeId: selStore },
        onComplete
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selStore]);

  useEffect(() => {
    if (
      (values.paymentType && values.paymentType === 1) ||
      values.purchItems.length === 0
    )
      setDisableAmt(true);
    else setDisableAmt();
    return () => {};
  }, [values.purchItems, values.paymentType]);

  useEffect(() => {
    setValues((j) => ({
      ...j,
      paymentDue:
        values.purchItems.length > 0 && values.paymentType === 2
          ? (
              parseFloat(netAmount) -
              (parseFloat(disAmt) + parseFloat(values.paidAmt | 0))
            ).toFixed(settings.decimal)
          : "",
      payDate: values.paymentType === 1 ? "" : values.payDate,
    }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netAmount, disAmt, values.paidAmt, values.paymentType]);

  const handlePurchForm = () => {
    setShowPurchForm(!showPurchForm);
  };

  const validateForm = () => {
    let temp = {};
    temp.supplierId = values.supplierId !== "" ? "" : t("supplierIsRequired");
    temp.paymentType =
      values.paymentType !== "" ? "" : t("paymentTypeIsRequired");

    if (values.paymentType === 2) {
      temp.payDate = values.payDate !== "" ? "" : t("payDateIsRequired");
    }

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = () => {
    if (validateForm() && values.purchItems.length > 0) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        res.status === 201 || res.status === 200
          ? navigate("/purchases")
          : setAlert({
              isOpen: true,
              type: "error",
              title: t("error"),
              subTitle: res.msg,
            });
      };

      if (values.purchItems)
        values.purchItems = values.purchItems.map((j) => ({
          ...j,
          product: "",
        }));

      if (id) {
        Server(controller.Purchase + action.Put).put(
          ToFormData({ ...values, updateDate: new Date().toLocaleString() }),
          onComplete
        );
      } else {
        Server(controller.Purchase + action.Post).post(
          ToFormData({
            ...values,
            storeId: selStore,
            userId: authUser.id,
            purchDate: new Date().toLocaleString(),
          }),
          onComplete
        );
      }
    } else if (values.purchItems.length <= 0)
      setAlert({
        isOpen: true,
        type: "error",
        title: t("emptyPurchaseList"),
        subTitle: t("purchWarning"),
      });
  };

  const GetDataFromServer = (val, onComplete) => {
    // Get Data From Server
    val &&
      Server(controller.Product + action.FindProducts).post(
        { storeId: selStore, value: val },
        onComplete
      );
  };

  const handleSearch = (e, val) => {
    setSearchVal(val);
    const onComplete = (res) => {
      if (res.status === 200) {
        setSearchLi([
          ...res.result.map((j) => {
            return {
              ...j,
              id: j.id,
              label: `${j.name} (${j.barcode})`,
            };
          }),
        ]);
        setFindErr("");
      }
    };
    e && GetDataFromServer(e.target.value, onComplete);
  };

  const handleScan = (e) => {
    const onComplete = (res) => {
      const product = res.result[0];
      if (res.status === 200 && product) handleSelect(null, product);
      else setFindErr(t("productNotFound"));
    };
    GetDataFromServer(e.target.value, onComplete);
    setSearchVal("");
  };

  const handleSelect = (e, product) => {
    if (product) {
      if (!values.purchItems.some((j) => j.productId === product.id)) {
        setValues((x) => ({
          ...x,
          purchItems: [
            {
              productId: product.id,
              qty: 1,
              purchPrice: product.purchPrice.toFixed(settings.decimal),
              product: product,
            },
            ...x.purchItems,
          ],
        }));
      } else if (values.purchItems.some((j) => j.productId === product.id)) {
        setValues((x) => ({
          ...x,
          purchItems: x.purchItems.map((j) =>
            j.productId === product.id ? { ...j, qty: parseInt(j.qty) + 1 } : j
          ),
        }));
      }
      setFindErr("");
    }
    setSearchVal("");
  };

  const handleQtyChg = (e, productId) => {
    setValues((x) => ({
      ...x,
      purchItems: x.purchItems.map((j) =>
        j.productId === productId ? { ...j, qty: Math.abs(e.target.value) } : j
      ),
    }));
  };

  const handleInc = (productId) => {
    setValues((x) => ({
      ...x,
      purchItems: x.purchItems.map((j) =>
        j.productId === productId ? { ...j, qty: parseInt(j.qty) + 1 } : j
      ),
    }));
  };
  const handleDec = (productId) => {
    setValues((x) => ({
      ...x,
      purchItems: x.purchItems.map((j) =>
        j.productId === productId
          ? { ...j, qty: j.qty > 1 ? parseInt(j.qty) - 1 : j.qty }
          : j
      ),
    }));
  };

  const handleRmvItem = (prodId) => {
    setValues((x) => ({
      ...x,
      purchItems: x.purchItems.filter((j) => j.productId !== prodId),
    }));
  };

  const handlePurchPrice = (e, productId) => {
    setValues((x) => ({
      ...x,
      purchItems: x.purchItems.map((j) =>
        j.productId === productId ? { ...j, purchPrice: e.target.value } : j
      ),
    }));
  };

  const handleSplrChg = (e) => {
    handleInpChg(e);
    setValues((j) => ({ ...j, paymentType: "", paymentDue: "" }));
  };

  const handlePayType = (e) => {
    handleInpChg(e);
    e.target.value === 1 && setValues((j) => ({ ...j, paymentDue: "" }));
  };

  const handleDis = (e) => {
    handleInpChg(e);
    values.paymentType === 2 && setValues((j) => ({ ...j, paymentDue: "" }));
  };

  return (
    <>
      <Form noValidate>
        {/* Items */}
        <div className="pt-20" />
        <Paper className="br-8 shadow-xs">
          {/* Title Bar */}
          <div>
            <div className="d-flex j-between a-center p-10">
              <div>
                <span className="border-left fs-20 tx-upp fw-5 gray-color pl-10 mb-20">
                  {t("purchaseProducts")}
                </span>
              </div>

              <Button
                loading={loading}
                onClick={handleSubmit}
                sx={{ minWidth: "150px" }}
              >
                {id ? t("saveChanges") : t("submit")}
              </Button>
            </div>
            <Divider />
          </div>

          <div className="p-15 mt-20 d-flex g-15 ">
            {/* Search Box */}
            <div className="w-100pr">
              <Autocomplete
                id="searchBox"
                options={searchLi}
                onChange={handleSelect}
                inputValue={searchVal}
                onInputChange={handleSearch}
                isOptionEqualToValue={(opt, val) => opt.id === val.id}
                onKeyDown={(e) => e.which === 13 && handleScan(e, searchVal)}
                componentsProps={{
                  paper: {
                    sx: {
                      borderRadius: "0px 0px 10px 10px",
                      backgroundColor: "var(--white-color)",
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    label={t("searchByNameOrBarcode")}
                    {...(findErr && { error: true, helperText: findErr })}
                    {...params}
                  />
                )}
              />
            </div>
            <Button
              sx={{ width: "200px" }}
              variant="text"
              onClick={handlePurchForm}
            >
              {t("addNewProduct")}
            </Button>
          </div>

          {/* Product Details */}
          <TableContainer className="mt-20">
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("actions")}</TableCell>
                  <TableCell>{t("productName")}</TableCell>
                  <TableCell>{t("quantity")}</TableCell>
                  <TableCell>{t("purchasePrice")}</TableCell>
                  <TableCell>{t("subtotal")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.purchItems.map((prod, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Tooltip arrow title={t("delete")}>
                        <IconButton
                          onClick={() => handleRmvItem(prod.productId)}
                          size="small"
                          color="error"
                        >
                          <ClearRounded className="fs-14" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{prod.product && prod.product.name}</TableCell>
                    <TableCell>
                      <InputQty
                        value={prod.qty}
                        onIncrease={() => handleInc(prod.productId)}
                        onDecrease={() => handleDec(prod.productId)}
                        onChange={(e) => handleQtyChg(e, prod.productId)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        type="number"
                        className="rmv-arrow"
                        value={prod.purchPrice}
                        sx={{
                          "& .MuiInputBase-root": {
                            maxWidth: "120px",
                            padding: "0px 0px",
                          },
                          "& .MuiInputBase-input": {
                            padding: "5.5px 0px",
                            textAlign: "center",
                          },
                        }}
                        onChange={(e) => handlePurchPrice(e, prod.productId)}
                      />{" "}
                    </TableCell>
                    <TableCell className="fs-16">
                      $&nbsp;
                      {(prod.qty * prod.purchPrice).toFixed(settings.decimal)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="tx-right p-20 d-grid g-15">
              <div className="d-flex a-center j-end">
                <div className="d-grid g-15 fw-5 gray-color">
                  <span>{t("totalItem")} :</span>
                  <span>{t("subtotal")} :</span>
                  <span>{t("discount")} :</span>
                  <span className="fs-20">{t("netAmount")} :</span>
                </div>
                <div className="d-grid g-15 fw-5 gray-color j-start">
                  <span>
                    {values.purchItems.length > 0
                      ? values.purchItems
                          .map((j) => j.qty)
                          .reduce((x, y) => x + y, 0)
                          .toLocaleString("en-US", {
                            maximumFractionDigits: settings.decimal,
                          })
                      : 0}
                  </span>
                  <span>
                    {settings && settings.curSymbol}&nbsp;
                    {values.purchItems.length > 0
                      ? values.purchItems
                          .map((j) => j.qty * parseFloat(j.purchPrice))
                          .reduce((x, y) => x + y, 0)
                          .toFixed(settings.decimal)
                      : 0}
                  </span>
                  <span>
                    (-) {settings && settings.curSymbol}&nbsp;
                    {parseFloat(disAmt).toFixed(settings.decimal)}
                  </span>
                  <span className="fs-20">
                    &nbsp;&nbsp; {settings && settings.curSymbol}{" "}
                    {parseFloat(netAmount - disAmt).toFixed(settings.decimal)}
                  </span>
                </div>
              </div>
            </div>
          </TableContainer>
        </Paper>

        {/* Purchase Details */}
        <div className="pt-20" />
        <Paper className="br-8 shadow-xs p-20">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputAuto
                label={t("supplier")}
                name="supplierId"
                options={suppliers}
                value={values.supplierId}
                error={errors.supplierId}
                onChange={(e) => handleSplrChg(e)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Select
                label={t("paymentType")}
                name="paymentType"
                fullWidth
                value={values.paymentType}
                error={errors.paymentType}
                onChange={handlePayType}
                options={PaymentType}
                disabled={!values.supplierId}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputDate
                label={t("purchaseDate")}
                name="purchDate"
                value={values.purchDate}
                error={errors.purchDate}
                onChange={(date) =>
                  handleInpDate(
                    "purchDate",
                    new Date(date).toLocaleDateString()
                  )
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputDate
                label={t("payDate")}
                name="purchpayDateDate"
                value={values.payDate}
                error={errors.payDate}
                minDate={new Date()}
                onChange={(date) =>
                  handleInpDate("payDate", new Date(date).toLocaleDateString())
                }
                fullWidth
                disabled={
                  values.paymentType && values.paymentType === 2 ? false : true
                }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Input
                label={t("discount")}
                onInput={(e) => (e.target.value = MaxLength(e.target.value, 5))}
                name="discount"
                className="rmv-arrow"
                value={values.discount}
                error={errors.discount}
                onChange={handleDis}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PercentRounded />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label={t("paidAmt")}
                className="rmv-arrow"
                name="paidAmt"
                value={values.paidAmt}
                error={errors.paidAmt}
                disabled={disableAmt}
                type="number"
                onChange={(e) => {
                  (parseFloat(e.target.value) <=
                    parseFloat(netAmount - disAmt).toFixed(settings.decimal) ||
                    values.paidAmt.length > e.target.value.length) &&
                    handleInpChg(e);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label={t("paymentDueReadonly")}
                className="rmv-arrow"
                name="paymentDue"
                value={values.paymentDue}
                error={errors.paymentDue}
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <div className="mt-10" />
              <InputFile
                label={t("uploadReceipt")}
                name="receptFile"
                src={values.recept}
                values={values.recept}
                onChange={handleFile}
                rmv={() => {
                  setValues({ ...values, receptFile: "", recept: "" });
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Form>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
      {/* Edit Dialog */}
      {showPurchForm && (
        <Dialog show={showPurchForm} maxWidth="md">
          <div className="bg-grayXS">
            <AddEditProd {...{ setShowPurchForm }} />
          </div>
        </Dialog>
      )}
    </>
  );
};

export default AddMultPurch;
