import React from "react";
import { Alert, Snackbar as MuiSnackbar } from "@mui/material";

const Snackbar = (props) => {
  const {
    snackbar,
    setSnackbar,
    vertical = "top",
    horizontal = "right",
    autoHideDuration = 2000,
  } = props;
  const handleClose = () => {
    setSnackbar((x) => ({ ...x, show: false }));
  };
  return (
    <MuiSnackbar
      anchorOrigin={{ vertical, horizontal }}
      open={snackbar.show}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
      sx={{ width: "100%" }}
    >
      <Alert
        severity={snackbar.type}
        onClose={handleClose}
        className="shadow-md fw-6"
      >
        {snackbar.msg}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
