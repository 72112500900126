import { Grid } from "@mui/material";
import React from "react";
import { Select } from "../../../controls";
import InputSwitch from "../../../controls/InputSwitch";
import { DropLi } from "../../../hooks";
import { useTranslation } from "react-i18next";

const ReceiptSettings = ({ values, errors, handleInpChg, handleInpCheck }) => {
  const { t } = useTranslation();
  const { ReceiptType, StoreNameForReceipit } = DropLi();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} className="mb-10 mt-20">
        <span className="border-left tx-upp fw-5 gray-color pl-10 ml-10">
          {t("receiptSettings")}
        </span>
      </Grid>
      <Grid item xs={12} md={4}>
        <Select
          label={t("receiptType")}
          name="receiptType"
          required={true}
          value={values.receiptType}
          error={errors.receiptType}
          onChange={handleInpChg}
          options={ReceiptType}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Select
          label={t("storeNameForReceipit")}
          name="storeNameForReceipit"
          required={true}
          value={values.storeNameForReceipit}
          error={errors.storeNameForReceipit}
          onChange={handleInpChg}
          options={StoreNameForReceipit}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputSwitch
          label={t("showLogoOnReceipt")}
          name="showLogoOnReceipt"
          checked={values.showLogoOnReceipt}
          onChange={handleInpCheck}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputSwitch
          label={t("showStoreNameOnReceipt")}
          name="showStoreNameOnReceipt"
          checked={values.showStoreNameOnReceipt}
          onChange={handleInpCheck}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputSwitch
          label={t("showAddressOnReceipt")}
          name="showAddressOnReceipt"
          checked={values.showAddressOnReceipt}
          onChange={handleInpCheck}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputSwitch
          label={t("showPhoneOnReceipt")}
          name="showPhoneOnReceipt"
          checked={values.showPhoneOnReceipt}
          onChange={handleInpCheck}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputSwitch
          label={t("showTaxOnReceipt")}
          name="showTaxOnReceipt"
          checked={values.showTaxOnReceipt}
          onChange={handleInpCheck}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputSwitch
          label={t("showTaglineOnReceipt")}
          name="showTaglineOnReceipt"
          checked={values.showTaglineOnReceipt}
          onChange={handleInpCheck}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputSwitch
          label={t("showEmailOnReceipt")}
          name="showEmailOnReceipt"
          checked={values.showEmailOnReceipt}
          onChange={handleInpCheck}
        />
      </Grid>
    </Grid>
  );
};

export default ReceiptSettings;
