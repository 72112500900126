import React, { useContext, useState } from "react";
import { Dashboard } from "../../layout";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  Home,
  Header,
  NotFound,
  NavMenu,
  AddEditProd,
  Products,
  Categories,
  AddEditCat,
  Pos,
  Purchases,
  PurchReturn,
  AddEditPurchReturn,
  ExpsCat,
  Suppliers,
  Users,
  AddEditUser,
  Sells,
  StockTransfers,
  AddEditTransfer,
  Inventory,
  AddEditInventory,
  SalaryPayment,
  SalaryHistory,
  AddEditSplr,
  EmployeeHistory,
  PayBills,
  StoreReports,
  ProductReport,
  PurchaseReport,
  RegReport,
  ExpenseReport,
  ProdSellVol,
  EmployeeReport,
  TopSelling,
  ReturnProd,
  Stores,
  AddEditStore,
  PosSettings,
  DefaultStore,
  AddMultPurch,
  Customers,
  AddEditCstmr,
  ViewCstmr,
} from "../";
import OrgForm from "../Org/OrgForm";
import { AppContext } from "../../App";
import { useEffect } from "react";
import { no_img } from "../../assets";
import Currencies from "../currencies/Currencies";
import ViewSplr from "../suppliers/ViewSupplier/ViewSplr";
import PayMethods from "../payMethod/PayMethods";
import ReturnForm from "../sellReturn/ReturnForm";
import SellReturns from "../sellReturn/SellReturns";
import ActivityLogs from "../reports/Log/ActivityLogs";
import InventoryStatus from "../reports/InventoryStatus";

const DashRoute = () => {
  const { selStore, stores, authUser } = useContext(AppContext);
  const [storeDtl, setStoreDtl] = useState();
  const [reportAccess, setReportAccess] = useState({});
  const [access, setAccess] = useState({});

  useEffect(() => {
    if (authUser && authUser.featuresAccess) {
      setAccess({ ...authUser.featuresAccess });
      authUser.featuresAccess.report &&
        setReportAccess({ ...authUser.featuresAccess.report });
    }
    return () => {};
  }, [authUser]);

  useEffect(() => {
    setStoreDtl({ ...stores.find((j) => j.id === selStore) });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  return (
    <Dashboard
      logo={(storeDtl && storeDtl.logo) || no_img}
      menu={<NavMenu />}
      header={<Header />}
    >
      <Routes>
        {authUser.id && (
          <Route
            path="/"
            element={
              <Navigate
                to={
                  !authUser.featuresAccess ||
                  authUser.featuresAccess.isHaveDashboardAccess
                    ? "/dashboard"
                    : "/pos"
                }
              />
            }
          />
        )}

        {access.isHavePosAccess && <Route path="pos/*" element={<Pos />} />}

        <Route path="sell_return/:id" element={<ReturnForm />} />

        {access.isHaveCurrencyAccess && (
          <Route path="currencies" element={<Currencies />} />
        )}

        {access.isHaveProductAccess && (
          <>
            <Route path="products" element={<Products />} />
            <Route path="products/product-form/">
              <Route path=":id" element={<AddEditProd />} />
              <Route path="" element={<AddEditProd />} />
            </Route>
          </>
        )}

        {access.isHaveCategoryAccess && (
          <Route path="categories" element={<Categories />} />
        )}
        {access.isHaveExpenseCategoryAccess && (
          <Route path="expenses" element={<ExpsCat />} />
        )}
        <Route path="categories/cat-form/:id" element={<AddEditCat />} />
        {access.isHavePurchaseAccess && (
          <>
            <Route path="purchases" element={<Purchases />} />
            <Route path="purchases/multi-purchase-form">
              <Route path=":id" element={<AddMultPurch />} />
              <Route path="" element={<AddMultPurch />} />
            </Route>
          </>
        )}
        {access.isHavePurchaseAccess && (
          <>
            <Route path="purchases-return" element={<PurchReturn />} />
            <Route path="purchases-return/purchase-return-form">
              <Route path=":id" element={<AddEditPurchReturn />} />
              <Route path="" element={<AddEditPurchReturn />} />
            </Route>
          </>
        )}

        {access.isHaveSupplierAccess && (
          <>
            <Route path="suppliers" element={<Suppliers />} />
            <Route path="/view-supplier/:id" element={<ViewSplr />} />
            <Route path="suppliers/supplier-form">
              <Route path=":id" element={<AddEditSplr />} />
              <Route path="" element={<AddEditSplr />} />
            </Route>
          </>
        )}

        {access.isHaveCustomerAccess && (
          <>
            <Route path="customers" element={<Customers />} />
            <Route path="/view-customer/:id" element={<ViewCstmr />} />
            <Route path="customers/customer-form">
              <Route path=":id" element={<AddEditCstmr />} />
              <Route path="" element={<AddEditCstmr />} />
            </Route>
          </>
        )}

        {access.isHaveSellsAccess && <Route path="sells" element={<Sells />} />}

        {access.isHaveStockTransferAccess && (
          <>
            <Route path="stock-transfer" element={<StockTransfers />} />
            <Route path="stock-transfer/stock-transfer-form/">
              <Route path=":id" element={<AddEditTransfer />} />
              <Route path="" element={<AddEditTransfer />} />
            </Route>
          </>
        )}

        {access.isHaveInventoryAccess && (
          <>
            <Route path="inventory" element={<Inventory />} />
            <Route
              path="inventory/inventory-form/:id"
              element={<AddEditInventory />}
            />
          </>
        )}

        {access.isHavePaymentMethodAccess && (
          <Route path="payment_methods" element={<PayMethods />} />
        )}

        {access.isHaveDashboardAccess && (
          <Route path="/dashboard" element={<Home />} />
        )}
        {access.isHaveUserAccess && (
          <>
            <Route path="users" element={<Users />} />
            <Route path="users/user-form/">
              <Route path=":id" element={<AddEditUser />} />
              <Route path="" element={<AddEditUser />} />
            </Route>
          </>
        )}

        {access.isHaveSalaryPaymentAccess && (
          <>
            <Route path="pay_salary" element={<SalaryPayment />} />
            <Route path="salary-payments" element={<SalaryHistory />} />
          </>
        )}

        <Route path="employee-history" element={<EmployeeHistory />} />
        {access.isHavePayBillsAccess && (
          <Route path="paybills" element={<PayBills />} />
        )}
        {/* ====== Report Access ====== */}
        {reportAccess.canSeeInventoryStatusReport && (
          <Route path="inventory-status" element={<InventoryStatus />} />
        )}
        {reportAccess.canSeeStoreReport && (
          <Route path="store-report" element={<StoreReports />} />
        )}
        {reportAccess.canSeeStoreReport && (
          <Route path="store-report" element={<StoreReports />} />
        )}
        {reportAccess.canSeeProductReport && (
          <Route path="product-report" element={<ProductReport />} />
        )}
        {reportAccess.canSeePurchaseReport && (
          <Route path="purchase-report" element={<PurchaseReport />} />
        )}
        {reportAccess.canSeeRegisterReport && (
          <Route path="register-report" element={<RegReport />} />
        )}
        {reportAccess.canSeeExpenseReport && (
          <Route path="expense-report" element={<ExpenseReport />} />
        )}
        {reportAccess.canSeeSellVolumeReport && (
          <Route path="products-sell-volume" element={<ProdSellVol />} />
        )}
        {reportAccess.canSeeEmployeeReport && (
          <Route path="employee-report" element={<EmployeeReport />} />
        )}
        {reportAccess.canSeeTopSellingReport && (
          <Route path="top-selling" element={<TopSelling />} />
        )}
        {reportAccess.canSeePurchaseReturnReport && (
          <Route path="purchase-return-report" element={<ReturnProd />} />
        )}
        {reportAccess.canSeeSellReturnReport && (
          <Route path="sell-returns" element={<SellReturns />} />
        )}

        <Route path="activity-log" element={<ActivityLogs />} />

        {access.isHaveStoreAccess && (
          <>
            <Route path="stores" element={<Stores />} />
            <Route path="stores/store-form">
              <Route path=":id" element={<AddEditStore />} />
              <Route path="" element={<AddEditStore />} />
            </Route>
          </>
        )}

        {access.canCustomizeDefaultStore && (
          <Route path="pos_items" element={<PosSettings />} />
        )}
        {access.canCustomizeDefaultStore && (
          <Route path="default_store" element={<DefaultStore />} />
        )}
        {access.canEditOrganizationDetails && (
          <Route path="setting_form" element={<OrgForm />} />
        )}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Dashboard>
  );
};

export default DashRoute;
