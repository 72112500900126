import { Grid } from "@mui/material";
import React from "react";
import * as actions from "../../api/actions/pos";
import { connect } from "react-redux";
import {
  SalesAccWidget,
  SalesChart,
  PeriodicalSalesReports,
  PurchaseAndSellChart,
  StoreValueReport,
  TopTenItems,
  SalesByEmployee,
  ProfitBreakdown,
  TopSellingProdTable,
  StoreExpenses,
} from "../index";
import { useContext } from "react";
import { AppContext } from "../../App";
import { useState } from "react";
import { useEffect } from "react";
import { controller, endpoints } from "../../api/actions/api";
import Currencies from "../currencies/Currencies";

const Home = (props) => {
  const { _getById } = props;
  const { selStore, setPageTitle, authUser, org } = useContext(AppContext);
  const [sellsPerMonth, setSellsPerMonth] = useState([]);
  const [purchPerMonth, setPurchPerMonth] = useState([]);
  const [access, setAccess] = useState({});

  useEffect(() => {
    if (
      authUser &&
      authUser.featuresAccess &&
      authUser.featuresAccess.dashboard
    ) {
      setAccess({
        ...authUser.featuresAccess.dashboard,
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  useEffect(() => {
    setPageTitle("Home");
    if (selStore) {
      // Get sell per month
      _getById(
        endpoints.HomeReport + controller.SellsPerMonth,
        selStore,
        null,
        (res) => res.status === 200 && setSellsPerMonth(res.result)
      );

      // Get purchase per month
      _getById(
        endpoints.HomeReport + controller.PurchasePerMonth,
        selStore,
        null,
        (res) => res.status === 200 && setPurchPerMonth(res.result)
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  return (
    <>
      <Grid container spacing={3} className="tx-upp">
        {access.canSeePeriodicalWidget && (
          <Grid item xs={12}>
            <PeriodicalSalesReports />
          </Grid>
        )}
        {access.canSeeMonthlyAccountWidget && (
          <Grid item xs={12} md={5}>
            <SalesAccWidget />
          </Grid>
        )}
        {access.canSeeMonthlySalesReportChart && (
          <Grid item xs={12} md={7}>
            <SalesChart {...{ sellsPerMonth }} />
          </Grid>
        )}
        {access.canSeeMulticurrencyTable && org && org.enableMultCurr && (
          <Grid item xs={12} md={9}>
            <Currencies fromHome={true} />
          </Grid>
        )}
        {access.canSeeInventoryValueWidget && (
          <Grid item xs={12} md={3}>
            <StoreValueReport />
          </Grid>
        )}
        {access.canSeePurchaseAndSellsChart && (
          <Grid item xs={12} md={org && org.enableMultCurr ? 12 : 9}>
            <PurchaseAndSellChart {...{ sellsPerMonth, purchPerMonth }} />
          </Grid>
        )}
        {access.canSeeTopProfitMakingItemChart && (
          <Grid item xs={12}>
            <TopTenItems />
          </Grid>
        )}
        {access.canSeeSalesByEmployeeChart && (
          <Grid item xs={12} md={4}>
            <SalesByEmployee />
          </Grid>
        )}
        {access.canSeeProfitBreakdownChart && (
          <Grid item xs={12} md={4}>
            <ProfitBreakdown />
          </Grid>
        )}
        {access.canSeeStoreExpenseChart && (
          <Grid item xs={12} md={4}>
            <StoreExpenses />
          </Grid>
        )}
        {access.canSeeTopSellingProductsTable && (
          <Grid item xs={12}>
            <TopSellingProdTable />
          </Grid>
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(Home);
