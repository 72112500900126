import React from "react";
import InputSwitch from "../../../controls/InputSwitch";
import { useTranslation } from "react-i18next";

const SendReceiptToEmail = ({ setEmailInv, emailInv }) => {
  const { t } = useTranslation();
  const handleInpChange = (e) => {
    setEmailInv(e.target.checked);
  };
  return (
    <InputSwitch
      label={<span className="tx-upp">{t("emailReceipt")}</span>}
      checked={emailInv}
      onChange={handleInpChange}
    />
  );
};

export default SendReceiptToEmail;
