import { Grid, IconButton, InputAdornment, Tooltip } from "@mui/material";
import React from "react";
import { Input } from "../../../controls";
import { ViewWeekRounded } from "@mui/icons-material";
import { MaxLength } from "../../../hooks/Method";
import InputSellPrice from "../SellPrice/InputSellPrice";
import { useTranslation } from "react-i18next";

const GeneralFields = ({
  values,
  setValues,
  errors,
  setErrors,
  handleInpChg,
}) => {
  const { t } = useTranslation();

  const handleGenerateCode = (name) => {
    setValues({
      ...values,
      [name]: Math.floor(1000000 + Math.random() * 10000000),
    });
    setErrors((j) => ({ ...j, [name]: "" }));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Input
          label={t("productName")}
          name="name"
          value={values.name}
          error={errors.name}
          onChange={handleInpChg}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label={t("productLocalName")}
          name="localName"
          value={values.localName}
          onChange={handleInpChg}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label={t("barcode")}
          name="barcode"
          value={values.barcode}
          error={errors.barcode}
          onChange={handleInpChg}
          fullWidth
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip arrow title={t("generateBarcode")}>
                  <IconButton onClick={() => handleGenerateCode("barcode")}>
                    <ViewWeekRounded />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Input
          label={t("purchasePrice")}
          onInput={(e) => (e.target.value = MaxLength(e.target.value, 12))}
          name="purchPrice"
          value={values.purchPrice}
          error={errors.purchPrice}
          onChange={handleInpChg}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputSellPrice
          {...{ setValues, values, error: errors.sellPrices, setErrors }}
        />
      </Grid>
    </Grid>
  );
};

export default GeneralFields;
