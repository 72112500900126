import { Container, Grid, Grow, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Input, InputPass } from "../../controls";
import { Model, UseForm } from "../../hooks";
import { Form } from "../../layout";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { ToFormData } from "../../hooks/Method";
import { useState } from "react";
import { Link } from "react-router-dom";
import { PersonRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";

const Login = (props) => {
  const { _getByObj } = props;
  const { t } = useTranslation();
  const { LoginMdl } = Model();
  const { setAuthUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { values, errors, setErrors, handleInpChg } = UseForm(LoginMdl);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    document.title = "Login";
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateForm = () => {
    let temp = {};
    temp.userName = values.userName !== "" ? "" : t("userNameIsRequired");
    temp.password = values.password !== "" ? "" : t("passwordIsRequired");
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        if (res !== undefined && res.status === 403) {
          setErrors({
            ...errors,
            [res.error.key]: res.error.msg,
          });
        } else if (res.status === 401) {
          setErrMsg(res.msg);
        } else if (res && res.status === 200) setAuthUser({ ...res.result });
        setLoading(false);
      };
      _getByObj(
        endpoints.Auth + controller.Login,
        ToFormData(values),
        actions.ActionType.Auth,
        onComplete
      );
    }
  };

  return (
    <Grow in timeout={1000}>
      <Container maxWidth="xl">
        <div className="d-flex j-center a-center h-100vh">
          <div className="d-grid g-20" style={{ maxWidth: "400px" }}>
            <Paper className="p-15 bs-primaryMD">
              <Form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div className="tx-center mb-10 mt-20 d-flex j-center">
                      <div
                        className="w-fit p-5"
                        style={{
                          backgroundColor: "var(--primaryXS-color)",
                          borderRadius: "50%",
                        }}
                      >
                        <PersonRounded
                          className="primary-color"
                          sx={{ fontSize: "80px" }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} className="tx-center">
                    <Typography color="error">{errMsg}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      label={t("userName")}
                      name="userName"
                      value={values.userName}
                      onChange={handleInpChg}
                      error={errors.userName}
                      className="input-primary w-100pr"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputPass
                      label={t("password")}
                      name="password"
                      value={values.password}
                      onChange={handleInpChg}
                      error={errors.password}
                      className="input-primary w-100pr"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className="tx-right"
                    sx={{ pt: "15px !important", pb: "15px", fontSize: "15px" }}
                  >
                    <Link
                      to="forgot"
                      className="gray-color tx-und-none tx-upp hove-primary"
                    >
                      {t("forgotPassword")}?
                    </Link>
                  </Grid>
                  <Grid item xs={12} className="text-center">
                    <Button
                      loading={loading}
                      type="submit"
                      fullWidth
                      size="large"
                    >
                      {t("login")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Paper>
          </div>
        </div>
      </Container>
    </Grow>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getByObj: actions.getByObj,
};

export default connect(mapStateToProps, mapActionToProps)(Login);
