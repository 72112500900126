import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { useTranslation } from "react-i18next";

const GeneralPrinterReceipt = ({ data }) => {
  const { settings, selStore, stores } = useContext(AppContext);
  const [store, setStore] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setStore({ ...stores.find((j) => j.id === selStore) });
    return () => {};
  }, [selStore, stores]);

  return data.id && store.id ? (
    <div
      style={{
        maxWidth: "210mm", // A4 width
        width: "100%",
        fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
        fontSize: "14px",
        lineHeight: 1.6,
        color: "#333",
        backgroundColor: "#fff",
        padding: "20px",
        margin: "0 auto", // Center the content
        boxSizing: "border-box",
      }}
    >
      {/* Store Header */}
      <div
        style={{
          textAlign: "center",
          marginBottom: "20px",
          paddingBottom: "15px",
          borderBottom: "2px solid #ddd",
        }}
      >
        {store.logo && (
          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={store.logo}
              alt="store_logo"
              style={{
                maxHeight: "80px",
                maxWidth: "160px",
                objectFit: "contain",
              }}
            />
          </div>
        )}
        <div
          style={{
            fontSize: "24px",
            fontWeight: "700",
            letterSpacing: "0.5px",
            color: "#222",
            marginBottom: "8px",
          }}
        >
          {store.storeLocalName || store.storeName}
        </div>
        {store.tagline && (
          <div
            style={{
              fontSize: "16px",
              fontWeight: "600",
              letterSpacing: "0.5px",
              color: "#444",
              marginBottom: "6px",
            }}
          >
            {store.tagline}
          </div>
        )}
        <div
          style={{
            fontSize: "14px",
            color: "#666",
            lineHeight: 1.4,
            marginTop: "8px",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          {store.address && (
            <span
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {store.address}
            </span>
          )}
          <div>
            {store.city ? `, ${store.city}` : ""}
            {store.state ? `, ${store.state}` : ""}
            {store.zip ? `, ${store.zip}` : ""}
          </div>
          <div
            style={{ display: "flex", gap: "8px", justifyContent: "center" }}
          >
            {store.phone && <span>📞 {store.phone}</span>}
            {store.email && <span>✉️ {store.email}</span>}
          </div>
        </div>
      </div>

      {/* Transaction Details */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "16px",
          marginBottom: "20px",
        }}
      >
        <div>
          <div style={{ fontWeight: "600", color: "#555" }}>
            {t("invoiceNo")}
          </div>
          <div style={{ color: "#333" }}>#{data.sellId}</div>
        </div>
        <div>
          <div style={{ fontWeight: "600", color: "#555" }}>
            {t("sellReturnId")}
          </div>
          <div style={{ color: "#333" }}>#{data.id}</div>
        </div>
        <div>
          <div style={{ fontWeight: "600", color: "#555" }}>
            {t("sellDate")}
          </div>
          <div style={{ color: "#333" }}>
            {data.sell && new Date(data.sell.sellDate).toLocaleString()}
          </div>
        </div>
        <div>
          <div style={{ fontWeight: "600", color: "#555" }}>
            {t("refundDate")}
          </div>
          <div style={{ color: "#333" }}>
            {new Date(data.refundDate).toLocaleString()}
          </div>
        </div>
      </div>

      {/* Customer Details */}
      {data.sell.cstmr && (
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          <div style={{ fontWeight: "600", color: "#555" }}>
            {t("customer")}
          </div>
          <div style={{ color: "#333" }}>{data.sell.cstmr.fullName}</div>
          <div style={{ color: "#333" }}>{data.sell.cstmr.phone}</div>
        </div>
      )}

      {/* Items Table */}
      <div
        style={{
          marginBottom: "20px",
          borderBottom: "2px solid #ddd",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2.5fr 0.50fr 1fr 1fr",
            gap: "12px",
            fontWeight: "600",
            marginBottom: "12px",
            color: "#555",
            paddingBottom: "8px",
            borderBottom: "1px solid #eee",
          }}
        >
          <div>{t("itemName")}</div>
          <div style={{ textAlign: "center" }}>{t("qty")}</div>
          <div style={{ textAlign: "right" }}>{t("unitPrice")}</div>
          <div style={{ textAlign: "right" }}>{t("totalPrice")}</div>
        </div>

        {data.items?.map((item, i) => (
          <div
            key={i}
            style={{
              display: "grid",
              gridTemplateColumns: "2.5fr 0.50fr 1fr 1fr",
              gap: "12px",
              marginBottom: "8px",
              color: "#333",
              paddingBottom: "8px",
              borderBottom: `${
                data.items.length > i + 1 ? "1" : "0"
              }px solid #eee`,
            }}
          >
            <div>{item.product.label}</div>
            <div style={{ textAlign: "center" }}>{item.returnQty}</div>
            <div style={{ textAlign: "right" }}>
              {settings.curSymbol} {item.price.toFixed(settings.decimal)}
            </div>
            <div style={{ textAlign: "right" }}>
              {settings.curSymbol}{" "}
              {(item.returnQty * item.price).toFixed(settings.decimal)}
            </div>
          </div>
        ))}
      </div>

      {/* Totals Section */}
      <div
        style={{
          display: "grid",
          gap: "12px",
          marginBottom: "25px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ color: "#555" }}>{t("refund")}:</span>
          <span style={{ color: "#333" }}>
            {settings.curSymbol} {data.refundAmt.toFixed(settings.decimal)}
          </span>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ color: "#555" }}>{t("discount")}:</span>
          <span style={{ color: "#333" }}>
            -{settings.curSymbol} {data.cutDiscount.toFixed(settings.decimal)}
          </span>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ color: "#555" }}>{t("tax")}:</span>
          <span style={{ color: "#333" }}>
            +{settings.curSymbol} {data.refundTaxAmt.toFixed(settings.decimal)}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "600",
            paddingTop: "12px",
            borderTop: "1px solid #ddd",
            color: "#222",
          }}
        >
          <span>{t("payableRefund")}:</span>
          <span>
            {settings.curSymbol} {data.payableRefund.toFixed(settings.decimal)}
          </span>
        </div>
      </div>

      {/* Footer */}
      <div
        style={{
          fontSize: "12px",
          color: "#666",
          lineHeight: 1.4,
          borderTop: "2px solid #ddd",
          paddingTop: "15px",
          whiteSpace: "pre-wrap",
          overflowWrap: "break-word",
          maxWidth: "100%",
        }}
      >
        {settings.salesTermForReceipt}
      </div>
    </div>
  ) : null;
};

export default GeneralPrinterReceipt;
