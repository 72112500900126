import React, { useEffect } from "react";
import { ClearRounded, EditRounded } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { connect } from "react-redux";
import { Alert, Dialog, Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { useContext } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import AddEditCurr from "./AddEditCurr";

const Currencies = (props) => {
  const { _getById, _delete, fromHome = false } = props;
  const { t } = useTranslation();
  const [currs, setCurrs] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [updData, setUpdData] = useState();
  const { org, setLoader, setPageTitle, authUser } = useContext(AppContext);
  const [access, setAccess] = useState({});
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  console.log("tblData: ", currs);

  useEffect(() => {
    if (authUser && authUser.featuresAccess) {
      authUser.featuresAccess.currency &&
        setAccess({
          ...authUser.featuresAccess.currency,
          isHaveCurrencyRateAccess:
            authUser.featuresAccess.isHaveCurrencyRateAccess,
        });
    }
    return () => {};
  }, [authUser]);

  const getCurrencies = () => {
    if (org && org.id) {
      setLoader(true);
      _getById(
        endpoints.Currency + controller.GetAllByOrg,
        org.id,
        null,
        (res) => {
          setLoader(false);
          setCurrs([...res.result]);
        }
      );
    }
  };

  useEffect(() => {
    !fromHome && setPageTitle(t("currencies"));
    getCurrencies();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org]);

  const handleAddEdit = (val) => {
    setUpdData(val);
    setShowForm(true);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("catDltTitle"),
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setCurrs((x) => [...x.filter((j) => j.id !== res.result.id)]);
            setAlert({
              isOpen: true,
              type: "success",
              title: t("dltSuccessTitle"),
              subTitle: res.msg,
            });
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: t("warning"),
              subTitle: res.msg,
            });
          }
        };
        _delete(endpoints.Currency, id, null, onComplete);
      },
    });
  };

  const ActionComp = (props) => (
    <Box className="d-flex a-center g-10">
      {org.currId === props.id ? (
        <div>
          <span className="primary-color bg-primarySM tx-upp fs-12 fw-5 pl-10 pr-10 pt-5 pb-5 br-15">
            {t("defaultCurrency")}
          </span>
        </div>
      ) : (
        <>
          {!fromHome && (
            <>
              {access.isHaveEditAccess && (
                <Tooltip arrow title={t("edit")}>
                  <IconButton
                    onClick={() => handleAddEdit(props)}
                    size="small"
                    className="primary-color"
                  >
                    <EditRounded className="fs-18 m-2" />
                  </IconButton>
                </Tooltip>
              )}
              {access.isHaveDeleteAccess && (
                <Tooltip arrow title={t("delete")}>
                  <IconButton
                    onClick={() => handleDlt(props.id)}
                    size="small"
                    color="error"
                  >
                    <ClearRounded className="fs-18 m-2" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );

  const HeaderComp = () =>
    access.isHaveAddAccess ? (
      <Button
        onClick={() => handleAddEdit()}
        className="btn-secondary"
        sx={{
          width: { md: "180px", xs: "100%" },
          mb: { xs: "10px", md: "0px" },
          fontSize: "17px",
          padding: "8px",
        }}
      >
        {t("addCurrency")}
      </Button>
    ) : (
      <></>
    );

  return (
    <>
      <Table
        showHeader={!fromHome}
        showSearch={!fromHome}
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          { id: "currency", label: t("currency") },
          { id: "name", label: t("name") },
          { id: "lastUpdate", label: t("lastUpdate") },
        ]}
        tblData={currs.map((j) => ({
          ...j,
          lastUpdate: j.lastUpdate && new Date(j.lastUpdate).toLocaleString(),
        }))}
      />
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {/* Edit Dialog */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="md">
          <AddEditCurr {...{ updData, setShowForm, setCurrs, setAlert }} />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(Currencies);
