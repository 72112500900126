import { Grid } from "@mui/material";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Input } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { PrintDiv, ToFormData } from "../../../hooks/Method";
import { AppContext } from "../../../App";
import { useTranslation } from "react-i18next";
import CustomerSect from "./CustomerSect";
import AdditionalDiscount from "./AdditionalDiscount";
import AmountToPay from "./PaymentReceived/AmountToPay";
import PaymentReceived from "./PaymentReceived/PaymentReceived";
import SendReceiptToEmail from "./SendReceiptToEmail";
import { action, controller, Server } from "../../../api/Server";

const Checkout = (props) => {
  const {
    subTotal,
    cartItems,
    totalPay,
    setTotalPay,
    tax,
    setReceptData,
    handleClearCart,
    setAlert,
    setTax,
    setTaxPct,
  } = props;
  const { selStore, stores, org, settings, setPageTitle, authUser } =
    useContext(AppContext);
  const { SellMdl } = Model();
  const { t } = useTranslation();
  const {
    values,
    errors,
    setErrors,
    handleInpChg,
    handleInpCheck,
    handleInpDate,
    setValues,
  } = UseForm(SellMdl);
  const [needToPayAmt, setNeedToPayAmt] = useState(totalPay || 0);
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState({});
  const [payMethods, setPayMethods] = useState([]);
  const [access, setAccess] = useState({});
  const [emailInv, setEmailInv] = useState(false);

  useEffect(() => {
    if (authUser && authUser.featuresAccess && authUser.featuresAccess.pos)
      setAccess({ ...authUser.featuresAccess.pos });
    return () => {};
  }, [authUser]);

  useEffect(() => {
    if (org.id) {
      Server(controller.PayMethod + action.GetAllByStore).getById(
        selStore,
        (res) => {
          res &&
            res.status &&
            setPayMethods([
              ...res.result.map((j) => ({ ...j, label: j.title })),
            ]);
        }
      );
    }

    setPageTitle(t("checkout"));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org]);

  // Get Selected or Default store details
  useEffect(() => {
    setStore(stores.find((j) => j.id === selStore));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  // Define default data.
  useEffect(() => {
    setValues((j) => ({
      ...j,
      storeId: selStore,
      sellItems: cartItems,
      taxPct: store ? store.tax : 0,
      tax: tax,
      totalPay: totalPay,
      received: "",
      discount: "",
      subtotal: subTotal.toFixed(settings.decimal),

      userId: authUser.id,
      paymentType: 1,
      payMethodId: payMethods[0] ? payMethods[0].id : "",
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPay, store]);

  // =======** Calculate Total Amount to Pay **=======
  useEffect(() => {
    var _disAmt = parseFloat(values.disAmt || 0);
    var _dueAmt = parseFloat(values.dueAmt || 0);
    var _creditAmt = parseFloat(values.creditAmt || 0);
    var _received = parseFloat(values.received || 0);

    var _leftAmt = (
      totalPay -
      (_disAmt + _dueAmt + _creditAmt + _received)
    ).toFixed(settings.decimal);
    setNeedToPayAmt(parseFloat(_leftAmt));

    setValues((j) => ({
      ...j,
      totalPay: (totalPay - (_disAmt + _dueAmt)).toFixed(settings.decimal),
      change: Math.abs(_leftAmt < 0 ? _leftAmt : 0),
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    totalPay,
    values.disAmt,
    values.dueAmt,
    values.creditAmt,
    values.received,
  ]);

  useEffect(() => {
    var _totalReceived = values.payments.reduce(
      (a, b) => a + parseFloat(b.amount),
      0
    );
    setValues((j) => ({
      ...j,
      received: parseFloat(_totalReceived.toFixed(settings.decimal)),
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.payments, values.cstmrId]);

  // Calculate Discount Amount
  useEffect(() => {
    if (values.discountType === 1) {
      setValues((j) => ({
        ...j,
        disAmt: (
          (parseFloat(totalPay) * parseFloat(values.discount || 0)) /
          100
        ).toFixed(settings.decimal),
      }));
    } else if (values.discountType === 2)
      setValues((j) => ({ ...j, disAmt: j.discount }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.discountType, values.discount]);

  const validateForm = () => {
    let temp = {};
    temp.dueDate =
      values.sellType === 2 && !values.dueDate ? t("dueDateIsRequired") : "";
    temp.dueAmt =
      values.sellType === 2 && !parseFloat(values.dueAmt || 0)
        ? t("dueAmtIsReq")
        : "";
    temp.received = needToPayAmt > 0 && 1 < needToPayAmt ? t("payMoreAmt") : "";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleEmailReceipt = (invoiceId) => {
    Server(controller.Sell + action.EmailInvoice).getById(invoiceId, (res) => {
      console.log("status: ", res.msg);
    });
  };

  const handleSubmit = () => {
    // **** Submit Action ****
    if (values.sellItems.length > 0 && validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(true);
        if (res.status === 200) {
          setReceptData(res.result);
          setTimeout(() => {
            PrintDiv("printSellRecept");
            // Email Sale Invoice
            if ((settings.emailInvoice === 2 || emailInv) && values.cstmrId) {
              handleEmailReceipt(res.result.id);
            }
            handleClearCart();
          }, 1500);
        } else {
          setLoading(false);
          setAlert({
            isOpen: true,
            type: "warning",
            title: t("warning"),
            subTitle: res.msg,
          });
        }
      };

      // Differential Amount
      if (needToPayAmt < 1 && needToPayAmt > 0) values.diffAmt = needToPayAmt;

      // Submit data to the server.
      Server(controller.Sell).post(
        ToFormData({
          ...values,
          sellItems: values.sellItems.map((j) =>
            j.itemType === 2 ? { ...j, productId: "" } : j
          ),
          sellDate: new Date().toLocaleString(),
        }),
        onComplete
      );
    } else if (values.sellItems.length <= 0) {
      setAlert({
        isOpen: true,
        type: "warning",
        title: t("warning"),
        subTitle: t("noItemInCart"),
      });
    } else if (needToPayAmt > 0) {
      setAlert({
        isOpen: true,
        type: "warning",
        title: t("receivedAmount"),
        subTitle: t("youDidntReceivedEnoughAmount"),
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {/* Customer Loyalty Section */}
        <Grid item xs={12}>
          <CustomerSect
            {...{
              values,
              setValues,
              errors,
              handleInpChg,
              handleInpDate,
              handleSubmit,
              loading,
              setTax,
              store,
              totalPay,
              needToPayAmt,
              setTotalPay,
              setTaxPct,
              access,
            }}
          />
        </Grid>

        {/* Amount to Pay */}
        <Grid item xs={12}>
          <AmountToPay {...{ needToPayAmt, values, handleInpCheck, org }} />
        </Grid>

        {/* Additional Discount */}
        {access.isHaveGivingDiscountAccess && (
          <AdditionalDiscount
            {...{ values, setValues, handleInpChg, totalPay }}
          />
        )}

        {/* Payment Received  */}
        <Grid item xs={12}>
          <PaymentReceived
            {...{ needToPayAmt, values, setValues, handleInpCheck }}
          />
        </Grid>

        {/* Note */}
        <Grid item xs={12} className="mt-10">
          <Input
            fullWidth
            placeholder={t("keepNoteIfYouWant")}
            name="note"
            value={values.note}
            onChange={handleInpChg}
            className="bg-white rmv-arrow br-8"
            variant="outlined"
            multiline
            rows={3}
          />
        </Grid>
        {/* Email Invoice Button */}
        {settings.emailInvoice === 1 && values.cstmrId && (
          <Grid item xs={12}>
            <SendReceiptToEmail {...{ setEmailInv, emailInv }} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Checkout;
