import { Collapse, Grid } from "@mui/material";
import React from "react";
import { Input } from "../../../controls";
import { useTranslation } from "react-i18next";
import InputSwitch from "../../../controls/InputSwitch";
import SMTPConfig from "./SMTPConfig";

const GeneralFields = ({
  values,
  setValues,
  errors,
  handleInpChg,
  handleInpCheck,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={6}>
          <Input
            label={t("storeName")}
            name="storeName"
            required={true}
            value={values.storeName}
            error={errors.storeName}
            onChange={handleInpChg}
            fullWidth
            inputProps={{ maxLength: 200 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label={t("storeLocalName")}
            name="storeLocalName"
            value={values.storeLocalName}
            onChange={handleInpChg}
            fullWidth
            inputProps={{ maxLength: 200 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label={t("phone")}
            name="phone"
            required={true}
            value={values.phone}
            error={errors.phone}
            onChange={handleInpChg}
            fullWidth
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label={t("tagline")}
            name="tagline"
            value={values.tagline}
            onChange={handleInpChg}
            fullWidth
            inputProps={{ maxLength: 150 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label={t("email")}
            name="email"
            value={values.email}
            onChange={handleInpChg}
            fullWidth
            inputProps={{ maxLength: 50 }}
          />
        </Grid>

        {/* ====== SMTP Configuration ====== */}
        <Grid item xs={12}>
          <div className="d-grid g-2">
            <InputSwitch
              label={<span className="tx-upp">{t("useOwnEmail")}</span>}
              name="useOwnEmail"
              checked={values.useOwnEmail}
              onChange={handleInpCheck}
            />
            <span className="gray-color pl-10">
              {t("useOwnEmailConfigTerm")}
            </span>
            <span className="error-color pl-10 mt-15 fs-18">
              {errors.smtpConfig}
            </span>
          </div>
        </Grid>
      </Grid>
      <Collapse in={values.useOwnEmail}>
        <SMTPConfig {...{ values, setValues, errors }} />
      </Collapse>
    </>
  );
};

export default GeneralFields;
