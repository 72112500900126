import React, { useContext, useEffect, useRef, useState } from "react";
import * as actions from "../../../api/actions/pos";
import { connect } from "react-redux";
import { Model, UseForm } from "../../../hooks";
import { AppContext } from "../../../App";
import { useTranslation } from "react-i18next";
import { controller, endpoints } from "../../../api/actions/api";
import { ToFormData } from "../../../hooks/Method";
import { Alert, Form } from "../../../layout";
import { Grid, Typography } from "@mui/material";
import { Button, Input, InputDate } from "../../../controls";

const MakePayment = ({ paymentData, setShowForm, _post, setSplr }) => {
  const { SupplierPaymentMdl } = Model();
  const amountRef = useRef();
  const { settings, selStore } = useContext(AppContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [totalAmt, setTotalAmt] = useState();
  const [leftDue, setLeftDue] = useState();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const { values, errors, setErrors, handleInpChg, setValues, handleInpDate } =
    UseForm(SupplierPaymentMdl);

  useEffect(() => {
    if (amountRef.current && values.amount !== undefined) {
      amountRef.current.focus();
    }
  }, [values.amount]);

  useEffect(() => {
    setValues((j) => ({
      ...j,
      purchaseId: paymentData.id,
      storeId: selStore,
      amount: paymentData.paymentDue,
    }));
    if (paymentData) {
      setTotalAmt(
        parseFloat(paymentData.paidAmt) + parseFloat(paymentData.paymentDue)
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentData]);

  useEffect(() => {
    if (paymentData) {
      var leftDue =
        parseFloat(paymentData.paymentDue) -
        parseFloat(values.amount || 0).toFixed(settings.decimal);
      setLeftDue(leftDue);
      setValues((j) => ({ ...j, nextDueDate: "" }));
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.amount, paymentData]);

  const validateForm = () => {
    let temp = {};
    temp.amount = values.amount !== "" ? "" : t("nextDueDateIsRequired");
    if (parseFloat(values.amount) > 0 && leftDue > 0)
      temp.nextDueDate =
        values.nextDueDate !== "" ? "" : t("nextDueDateIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        if (res.status === 200) {
          setAlert({
            isOpen: true,
            type: "success",
            title: t("success"),
            subTitle: res.msg,
          });
        }
        setSplr &&
          setSplr((j) => ({
            ...j,
            purchases: j.purchases.map((x) =>
              x.id === res.result.id ? res.result : x
            ),
          }));
        setLoading(false);
        setShowForm(false);
      };

      _post(
        endpoints.Supplier + controller.MakePayment,
        ToFormData(values),
        null,
        onComplete
      );
    }
  };

  return (
    <>
      {settings && values.purchaseId ? (
        <Form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3} className="p-20 pt-40 bg-grayXS">
            <Grid item xs={12} className="tx-center">
              <Typography variant="h5" className="fs-22 gray-color fw-5 tx-upp">
                {t("makePayment")}
              </Typography>
              <div className="bs-bottom mx-w-80 m-auto mt-10 mb-30" />
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="d-grid g-5 a-center gray-color">
                <span className="fs-12 tx-upp">{t("referenceNo")}</span>
                <span className="fs-20 fw-5">{values.purchaseId}</span>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="d-grid g-5 a-center gray-color">
                <span className="fs-12 tx-upp">{t("totalBill")}</span>
                <span className="fs-20 fw-5">
                  {settings.curSymbol + " " + totalAmt.toLocaleString()}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div
                className={`d-grid g-5 a-center ${
                  leftDue > 0 ? "error-color" : "success-color"
                }`}
              >
                <span className="fs-12 tx-upp">{t("due")}</span>
                <span className="fs-20 fw-5">
                  {settings.curSymbol + " " + leftDue.toLocaleString()}
                </span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Input
                inputRef={amountRef}
                label={t("payAmount")}
                type="number"
                name="amount"
                value={values.amount}
                error={errors.amount}
                onChange={(e) => {
                  if (
                    parseFloat(e.target.value) <=
                      parseFloat(paymentData.paymentDue).toFixed(
                        settings.decimal
                      ) ||
                    values.amount.length > e.target.value.length
                  )
                    handleInpChg(e);
                }}
                fullWidth
              />
            </Grid>
            {parseFloat(values.amount) > 0 && leftDue > 0 && (
              <Grid item xs={12}>
                <InputDate
                  label="Next Due Date"
                  name="nextDueDate"
                  value={values.nextDueDate}
                  error={errors.nextDueDate}
                  minDate={new Date()}
                  onChange={(date) =>
                    handleInpDate(
                      "nextDueDate",
                      new Date(date).toLocaleDateString()
                    )
                  }
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              className="tx-right d-flex mt-10"
              sx={{ flexWrap: { xs: "wrap", md: "initial" } }}
            >
              <Button
                loading={loading}
                type="submit"
                className="w-100pr primary-color bg-primarySM"
                size="large"
              >
                {t("payment")}
              </Button>
              <Button
                onClick={() => setShowForm(false)}
                className="error-color bg-errorSM w-100pr"
                size="large"
                sx={{ ml: { md: "10px" }, mt: { xs: "15px", md: "0px" } }}
              >
                {t("close")}
              </Button>
            </Grid>
          </Grid>
        </Form>
      ) : (
        "Loading..."
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(MakePayment);
