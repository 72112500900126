import React, { useContext, useEffect, useState } from "react";
import NavItem from "../../layout/NavItem";
// Icon
import {
  InsightsTwoTone,
  PeopleRounded,
  LocalMallRounded,
  MoveDownRounded,
  ShoppingCartCheckoutRounded,
  ContactMailRounded,
  TroubleshootRounded,
  AdminPanelSettingsRounded,
  SettingsRounded,
  RequestQuoteRounded,
  PriceCheckRounded,
  InventoryRounded,
  LogoutRounded,
  GroupRounded,
} from "@mui/icons-material";
import { connect } from "react-redux";
import { AppContext } from "../../App";
import * as actions from "../../api/actions/pos";
import { useNavigate } from "react-router-dom";
import { controller, endpoints } from "../../api/actions/api";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

const NevMenu = (props) => {
  const { _get } = props;
  const { t } = useTranslation();
  const { setCLoader, authUser } = useContext(AppContext);
  const navigate = useNavigate();
  const [reportAccess, setReportAccess] = useState({});
  const [access, setAccess] = useState({});

  useEffect(() => {
    if (authUser && authUser.featuresAccess) {
      setAccess({ ...authUser.featuresAccess });
      authUser.featuresAccess.report &&
        setReportAccess({ ...authUser.featuresAccess.report });
    }

    return () => {};
  }, [authUser]);

  const handleLogout = () => {
    setCLoader(true);
    const onComplete = (res) => {
      setCLoader(false);
      navigate("/");
    };
    _get(
      endpoints.Auth + controller.Logout,
      actions.ActionType.Auth,
      onComplete
    );
  };

  return (
    <>
      {access.isHaveDashboardAccess && (
        <NavItem
          title={t("dashboard")}
          path="/dashboard"
          icon={<InsightsTwoTone />}
        />
      )}

      {(access.isHaveProductAccess || access.isHaveCategoryAccess) && (
        <NavItem
          title={t("products")}
          icon={<LocalMallRounded />}
          child={[
            ...(access.isHaveProductAccess
              ? [{ title: t("products"), path: "products" }]
              : []),
            ...(access.isHaveCategoryAccess
              ? [{ title: t("categories"), path: "categories" }]
              : []),
          ]}
        />
      )}

      {access.isHaveInventoryAccess && (
        <NavItem
          title={t("inventory")}
          path="/inventory"
          icon={<InventoryRounded />}
        />
      )}

      {(access.isHavePurchaseAccess || access.isHavePurchaseReturnAccess) && (
        <NavItem
          title={t("purchases")}
          icon={<ShoppingCartCheckoutRounded />}
          child={[
            ...(access.isHavePurchaseAccess
              ? [{ title: t("purchases"), path: "purchases" }]
              : []),
            ...(access.isHavePurchaseReturnAccess
              ? [{ title: t("purchasesReturn"), path: "purchases-return" }]
              : []),
          ]}
        />
      )}
      {access.isHaveSupplierAccess && (
        <NavItem
          title={t("suppliers")}
          path="suppliers"
          icon={<ContactMailRounded />}
        />
      )}
      {access.isHaveCustomerAccess && (
        <NavItem
          title={t("customers")}
          path="customers"
          icon={<GroupRounded />}
        />
      )}
      {access.isHaveExpenseCategoryAccess && (
        <NavItem
          title={t("expenses")}
          path="expenses"
          icon={<RequestQuoteRounded />}
        />
      )}
      {access.isHaveSellsAccess && (
        <NavItem title={t("sells")} path="sells" icon={<PriceCheckRounded />} />
      )}
      {access.isHaveStockTransferAccess && (
        <NavItem
          title={t("stockTransfer")}
          path="stock-transfer"
          icon={<MoveDownRounded />}
        />
      )}

      {access.isHaveReportAccess && (
        <NavItem
          title={t("reports")}
          icon={<TroubleshootRounded />}
          child={[
            ...(reportAccess.canSeeStoreReport
              ? [{ title: t("storeReport"), path: "store-report" }]
              : []),
            ...(reportAccess.canSeeInventoryStatusReport
              ? [{ title: t("inventoryStatus"), path: "inventory-status" }]
              : []),
            ...(reportAccess.canSeeProductReport
              ? [{ title: t("productReport"), path: "product-report" }]
              : []),
            ...(reportAccess.canSeePurchaseReport
              ? [{ title: t("purchaseReport"), path: "purchase-report" }]
              : []),
            ...(reportAccess.canSeeRegisterReport
              ? [{ title: t("registerReport"), path: "register-report" }]
              : []),
            ...(reportAccess.canSeeExpenseReport
              ? [{ title: t("expenseReport"), path: "expense-report" }]
              : []),
            ...(reportAccess.canSeeSellVolumeReport
              ? [
                  {
                    title: t("productsSellVolume"),
                    path: "products-sell-volume",
                  },
                ]
              : []),
            ...(reportAccess.canSeeEmployeeReport
              ? [{ title: t("employeeReport"), path: "employee-report" }]
              : []),
            ...(reportAccess.canSeeTopSellingReport
              ? [{ title: t("topSelling"), path: "top-selling" }]
              : []),
            ...(reportAccess.canSeePurchaseReturnReport
              ? [
                  {
                    title: t("purchaseReturn"),
                    path: "purchase-return-report",
                  },
                ]
              : []),
            ...(reportAccess.canSeeSellReturnReport
              ? [{ title: t("sellReturn"), path: "sell-returns" }]
              : []),
            { title: t("activityLog"), path: "activity-log" },
          ]}
        />
      )}
      {access.isHaveUserAccess && (
        <NavItem title={t("user")} path="/users" icon={<PeopleRounded />} />
      )}
      {(access.isHavePayBillsAccess || access.isHaveSalaryPaymentAccess) && (
        <NavItem
          title={t("HR")}
          icon={<AdminPanelSettingsRounded />}
          child={[
            ...(access.isHavePayBillsAccess
              ? [{ title: t("payBills"), path: "paybills" }]
              : []),
            ...(access.isHaveSalaryPaymentAccess
              ? [{ title: t("salaryPayments"), path: "salary-payments" }]
              : []),
          ]}
        />
      )}
      {(access.canCustomizeDefaultPosItem ||
        access.isHaveStoreAccess ||
        access.canCustomizeDefaultStore ||
        access.isHaveCurrencyAccess ||
        access.isHavePaymentMethodAccess ||
        access.canEditOrganizationDetails) && (
        <NavItem
          title={t("master")}
          icon={<SettingsRounded />}
          child={[
            ...(access.canCustomizeDefaultPosItem
              ? [{ title: t("POSItem"), path: "pos_items" }]
              : []),
            ...(access.isHaveStoreAccess
              ? [{ title: t("store"), path: "stores" }]
              : []),
            ...(access.canCustomizeDefaultStore
              ? [{ title: t("defaultStore"), path: "default_store" }]
              : []),
            ...(access.isHaveCurrencyAccess
              ? [{ title: t("currencies"), path: "currencies" }]
              : []),
            ...(access.isHavePaymentMethodAccess
              ? [{ title: t("paymentMethod"), path: "payment_methods" }]
              : []),
            ...(access.canEditOrganizationDetails
              ? [{ title: t("organization"), path: "setting_form" }]
              : []),
          ]}
        />
      )}

      {/* ==== Logout ==== */}
      <ListItemButton onClick={() => handleLogout()}>
        <ListItemIcon>
          <LogoutRounded />
        </ListItemIcon>
        <ListItemText primary={t("logout")} />
      </ListItemButton>
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
});

const mapActiontoProps = {
  _get: actions.get,
};

export default connect(mapStateToProps, mapActiontoProps)(NevMenu);
