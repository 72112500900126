import { DeleteOutlineRounded } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React from "react";

const Payments = ({ values, setValues, settings }) => {
  const handleDelete = (key) => {
    setValues((val) => ({
      ...val,
      payments: [...val.payments.filter((x) => x.key !== key)],
    }));
  };

  return (
    <>
      <Divider />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableBody>
            {values.payments &&
              values.payments.map((payment, i) => (
                <TableRow key={i}>
                  <TableCell className="gray-color">
                    <div className="d-grid a-center ">
                      <span className="fs-16 fw-5">{payment.methodTitle}</span>
                      <span className="fs-12 ">{payment.note}</span>
                    </div>
                  </TableCell>

                  <TableCell align="right" className="fw-5">
                    <div className="d-flex a-center g-15 j-end">
                      <span className="fs-18 gray-color">
                        {settings.curSymbol} {payment.amount}
                      </span>
                      <IconButton
                        onClick={() => handleDelete(payment.key)}
                        size="small"
                      >
                        <DeleteOutlineRounded className="fs-20" color="error" />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Payments;
