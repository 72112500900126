import React, { useContext, useEffect, useState } from "react";
import { Divider, Grid } from "@mui/material";
import { Input, InputAuto, Button } from "../../controls";
import { Form } from "../../layout";
import { Model, UseForm } from "../../hooks";
import { ToFormData } from "../../hooks/Method";
import { useTranslation } from "react-i18next";
import { controller, Server } from "../../api/Server";
import { AppContext } from "../../App";

const AddEditCat = ({ updData, setShowForm, setCats }) => {
  const { t } = useTranslation();
  const { selStore } = useContext(AppContext);
  const { CatMdl } = Model();
  const [loading, setLoading] = useState(false);
  const [parentCats, setParentCats] = useState([]);
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    resetFormControls,
  } = UseForm(CatMdl);

  const validateForm = () => {
    let temp = {};
    temp.name = values.name !== "" ? "" : t("categoryNameIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    Server(controller.Category).get((res) => {
      setParentCats([
        ...res.result
          .filter((j) => !j.parentCatId)
          .map((j) => ({ ...j, label: j.name })),
      ]);
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm() && selStore) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        setShowForm(false);

        setCats((j) => [res.result, ...j]);
      };
      if (updData && updData.id !== 0) {
        Server(controller.Category).put(ToFormData(values), onComplete);
      } else {
        Server(controller.Category).post(
          ToFormData({
            ...values,
            storeId: selStore,
            updateDate: new Date().toLocaleString(),
          }),
          onComplete
        );
      }
    }
  };

  useEffect(() => {
    updData && setValues((j) => ({ ...j, ...updData }));
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData]);

  return (
    <div className="bg-grayXS">
      <Form noValidate onSubmit={handleSubmit}>
        <div className="d-flex j-between a-center p-10">
          <div>
            <span className="border-left fs-20 tx-upp fw-5 gray-color">
              {t("category")}
            </span>
          </div>
          <div className="d-flex g-10">
            <Button
              onClick={() => setShowForm(false)}
              className="error-color bg-errorSM"
              size="small"
            >
              {t("close")}
            </Button>
            <Button type="submit" size="small" loading={loading}>
              {!updData ? t("submit") : t("saveChanges")}
            </Button>
          </div>
        </div>
        <Divider />
        <Grid container spacing={2} className="p-15">
          <Grid item xs={12}>
            <Input
              label={t("categoryName")}
              name="name"
              value={values.name}
              error={errors.name}
              onChange={handleInpChg}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label={t("productDescription")}
              multiline
              rows={4}
              name="descr"
              value={values.descr}
              error={errors.descr}
              onChange={handleInpChg}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <InputAuto
              label={t("parentCategory")}
              name="parentCatId"
              fullWidth
              value={values.parentCatId}
              error={errors.parentCatId}
              onChange={handleInpChg}
              options={parentCats}
            />
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

export default AddEditCat;
