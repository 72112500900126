import React, { useEffect, useState } from "react";
import { Table } from "../../layout";
import { useContext } from "react";
import { AppContext } from "../../App";
import { action, controller, Server } from "../../api/Server";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { CSVLink } from "react-csv";

const InventoryStatus = () => {
  const { setLoader, selStore, settings, setPageTitle } =
    useContext(AppContext);
  const { t } = useTranslation();
  const [csvHeader, setCsvHeader] = useState([]);
  const [invs, setInvs] = useState([]);

  useEffect(() => {
    if (selStore) {
      setLoader(true);
      Server(controller.Report + action.InventoryStatus).getById(
        selStore,
        (res) => {
          setLoader(false);
          res &&
            res.status === 200 &&
            setInvs([
              ...res.result.map((j) => ({
                ...j,
                searchTerm: `${
                  j.status === 1 ? "red" : j.status === 2 ? "yellow" : "green"
                }`,
              })),
            ]);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    setPageTitle(t("inventoryStatus"));
    setCsvHeader([
      { key: "productId", label: t("productId") },
      { key: "barcode", label: t("barcode") },
      { key: "name", label: t("product") },
      { key: "inInventory", label: t("inventory") },
      { key: "purchPrice", label: t("purchasePrice") },
      { key: "sellPrice", label: t("sellPrice") },
      { key: "alertQty", label: t("alertQty") },
      { key: "reOrderPoint", label: t("reOrderPoint") },
      { key: "inventoryValue_Purchase", label: t("inventoryPurchaseValue") },
      { key: "inventoryValue_Sale", label: t("inventorySaleValue") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={invs}
          headers={csvHeader}
          filename={`${t("productReport")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  const Status = ({ type }) => {
    switch (type) {
      case 3:
        return (
          <span className="bg-successXS success-color fw-6 pr-10 pl-10 pt-5 pb-5 br-25 fs-12 tx-upp">
            {t("normal")}
          </span>
        );
      case 2:
        return (
          <span className="bg-warning white-color fw-5 pr-10 pl-10 pt-5 pb-5 br-25 fs-12 tx-upp">
            {t("reacedReOrderPoint")}
          </span>
        );
      case 1:
        return (
          <span className="bg-errorSM error-color pr-10 pl-10 pt-5 pb-5 br-25 fs-12 tx-upp fw-6">
            {t("reachedAlertyQty")}
          </span>
        );

      default:
        break;
    }
  };

  return (
    <>
      <Table
        ActionComp={() => <div></div>}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "status", label: t("status") },
          { id: "product", label: t("product") },
          { id: "priceAndStock", label: t("priceAndStock") },
          { id: "orderPoint", label: t("orderPoint") },
          { id: "value", label: t("value") },
        ]}
        tblData={invs.map((inv) => {
          return {
            ...inv,
            status: <Status type={inv.status} />,
            product: (
              <div className="d-flex g-10">
                <div className="d-grid g-2">
                  <span>{inv.name}</span>
                  <span className="fs-12 fw-5">{inv.barcode}</span>
                </div>
              </div>
            ),
            priceAndStock: (
              <div className="d-grid g-2 fs-14">
                <div className="d-flex g-5">
                  <span>{t("inStock")}: </span>
                  <span className="fw-5">{inv.inInventory}</span>
                </div>
                <div className="d-flex g-5">
                  <span>{t("sellPrice")}: </span>
                  <span className="fw-5">
                    {settings.curSymbol}{" "}
                    {inv.sellPrice.toFixed(settings.decimal)}
                  </span>
                </div>
              </div>
            ),
            orderPoint: (
              <div className="d-grid g-2 fs-14">
                <div
                  className={`d-flex g-5 ${
                    inv.status === 1 ? "error-color fw-5" : ""
                  }`}
                >
                  <span>{t("alertQty")}: </span>
                  <span className="fw-5">{inv.alertQty}</span>
                </div>
                <div
                  className={`d-flex g-5 ${
                    inv.status !== 3 ? "warning-color fw-5" : ""
                  }`}
                >
                  <span>{t("reOrderPoint")}: </span>
                  <span className="fw-5">{inv.reOrderPoint}</span>
                </div>
              </div>
            ),
            value: (
              <div className="d-grid g-2 fs-14">
                <div className="d-flex g-5">
                  <span>{t("inventorySaleValue")}: </span>
                  <span className="fw-5">
                    {" "}
                    {settings.curSymbol}{" "}
                    {inv.inventoryValue_Sale.toFixed(settings.decimal)}
                  </span>
                </div>
                <div className="d-flex g-5">
                  <span>{t("inventoryPurchaseValue")}: </span>
                  <span className="fw-5">
                    {settings.curSymbol}{" "}
                    {inv.inventoryValue_Purchase.toFixed(settings.decimal)}
                  </span>
                </div>
              </div>
            ),
            // profit:
            //   settings.curSymbol + " " + prod.profit.toFixed(settings.decimal),
          };
        })}
      />
    </>
  );
};

export default InventoryStatus;
