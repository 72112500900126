import { ClearRounded, EditRounded } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Table } from "../../layout";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { DropLi } from "../../hooks";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import InputSwitch from "../../controls/InputSwitch";
import { action, controller, Server } from "../../api/Server";
import { ToFormData } from "../../hooks/Method";

const Users = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoader, selStore, setPageTitle, authUser } =
    useContext(AppContext);
  const { Gender, Role } = DropLi();
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [access, setAccess] = useState({});
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (
      authUser &&
      authUser.featuresAccess &&
      authUser.featuresAccess.userAccess
    )
      setAccess({ ...authUser.featuresAccess.userAccess });
    return () => {};
  }, [authUser]);

  useEffect(() => {
    setPageTitle && setPageTitle(t("users"));
    setCsvHeader([
      { key: "fullName", label: t("fullName") },
      { key: "userName", label: t("userName") },
      { key: "dob", label: t("dateOfBirth") },
      { key: "gender", label: t("gender") },
      { key: "phone", label: t("phone") },
      { key: "role", label: t("userRole") },
      { key: "address", label: t("address") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selStore) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 &&
          setTblData(
            res.result.map((user) => {
              var gender = Gender.find((j) => j.id === user.gender);
              var role = Role.find((j) => j.id === user.role);
              return {
                ...user,
                gender: gender ? gender.label : "",
                role: role ? role.label : "",
              };
            })
          );
      };
      Server(controller.User + action.GetAllByStore).getById(
        selStore,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const handleAddEdit = (val) => {
    navigate(`user-form/${val.id || ""}`);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("userDltUser"),
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: t("dltSuccessTitle"),
              subTitle: res.msg,
            });
            setTblData((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else
            setAlert({
              isOpen: true,
              type: "error",
              title: t("error"),
              subTitle: res.msg,
            });
        };
        Server(controller.User).Delete(id, onComplete);
      },
    });
  };

  const handleActive = (id) => {
    Server(controller.User + action.ChangeActiveStatus).post(
      ToFormData({
        userId: id,
        dateTime: new Date().toLocaleString(),
      }),
      (res) => {
        res && res.status === 200
          ? setTblData([
              ...tblData.map((j) =>
                j.id === id ? { ...j, isActive: res.result.isActive } : j
              ),
            ])
          : setAlert({
              isOpen: true,
              type: "error",
              title: t("error"),
              subTitle: res.msg,
            });
      }
    );
  };

  const ActionComp = (props) => (
    <div className="bs-secondary br-8 w-fit">
      {access.isHaveEditAccess && (
        <Tooltip arrow title={t("edit")}>
          <IconButton
            onClick={() => handleAddEdit(props)}
            size="small"
            color="primary"
            className="mr-5 p-5"
          >
            <EditRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      )}

      {access.isHaveDeleteAccess && (
        <Tooltip arrow title={t("delete")}>
          <IconButton
            onClick={() => handleDlt(props.id)}
            size="small"
            color="error"
            className="mr-5 p-5"
          >
            <ClearRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      {access.isHaveAddAccess && (
        <Button
          onClick={handleAddEdit}
          className="btn-secondary"
          sx={{
            width: { md: "180px", xs: "100%" },
            mb: { xs: "10px", md: "0px" },
            fontSize: "17px",
            padding: "8px",
          }}
        >
          {t("addUser")}
        </Button>
      )}
      {access.isHaveExportAccess && (
        <Box
          className="d-flex"
          sx={{
            width: { xs: "100%", md: "auto" },
          }}
        >
          <CSVLink
            data={tblData}
            headers={csvHeader}
            filename={`${t("user")} (${new Date().toDateString()}).csv`}
            target="_blank"
            className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
          >
            {t("exportCSV")}
          </CSVLink>
        </Box>
      )}
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          { id: "active", label: t("active") },
          { id: "user", label: t("user") },
          { id: "contact", label: t("contact") },
          { id: "canAccess", label: t("canAccess") },
          { id: "address", label: t("address") },
        ]}
        tblData={tblData.map((user) => {
          return {
            ...user,
            active: access.isHaveActivatiInactiveAccess ? (
              <InputSwitch
                checked={user.isActive}
                onChange={() => handleActive(user.id)}
              />
            ) : user.isActive ? (
              "Active"
            ) : (
              "Inactive"
            ),
            idSearch: `id ${user.id}`,
            user: (
              <div className="d-grid">
                <span className="fw-5">{user.fullName}</span>
                <span className="fs-12">
                  <span className="fw-5">
                    {t("id")}: {user.id}
                  </span>
                  &nbsp; | &nbsp;
                  <span className="fw-5">{user.userName}</span>
                  &nbsp; | &nbsp;
                  <span className="fw-6 primary-color">{user.role}</span>
                </span>
              </div>
            ),
            contact: (
              <div className="d-grid ">
                {user.email && (
                  <a
                    className="fs-14 primary-color c-pointer mb-3"
                    href={`mailto:${user.email}`}
                  >
                    ✉️ {user.email}
                  </a>
                )}
                {user.phone && (
                  <a
                    className="fs-14 primary-color c-pointer"
                    href={`tel:${user.phone}`}
                  >
                    📞 {user.phone}
                  </a>
                )}
              </div>
            ),
            canAccess: (
              <div className="d-flex g-5">
                {user.stores &&
                  user.stores.map((store, ind) => (
                    <Fragment key={ind}>
                      <span className="fw-5">
                        {store.store && store.store.storeName}
                      </span>
                      {user.stores.length > ind + 1 && " | "}
                    </Fragment>
                  ))}
              </div>
            ),
            address: (
              <div className="d-grid fs-14">
                <span>
                  {user.city} {user.city && ", "} {user.state} {user.zip}
                </span>
                <span>{user.address}</span>
              </div>
            ),
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Users;
