import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { controller, endpoints } from "../../api/actions/api";
import { AppContext } from "../../App";
import { Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { CSVLink } from "react-csv";
import { ChangeArrayKey } from "../../hooks/Method";

const ExpenseReport = (props) => {
  const { _getById } = props;
  const { setLoader, selStore, settings, setPageTitle } =
    useContext(AppContext);
  const { t } = useTranslation();
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);

  const onComplete = (res) => {
    var currSymbol = settings && settings.curSymbol;
    setLoader(false);
    res.status === 200 &&
      setTblData([
        ...res.result.map((obj) => ({
          ...obj,
          addDate: new Date(obj.addDate).toLocaleString(),
          expenseType: obj.expenseCat.name,
          amount: currSymbol + " " + obj.amount.toFixed(settings.decimal),
          paidBy: obj.user.fullName,
        })),
      ]);
  };

  useEffect(() => {
    if (selStore && settings) {
      setLoader(true);
      _getById(
        endpoints.Expense + controller.GetByStoreId,
        selStore,
        null,
        onComplete
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore, settings]);

  useEffect(() => {
    setPageTitle(t("expenseReport"));
    setCsvHeader([
      { key: "addDate", label: t("payDate") },
      { key: "expenseType", label: t("expenseType") },
      { key: "amount", label: t("amount") },
      { key: "paidBy", label: t("paidBy") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={tblData}
          headers={csvHeader}
          filename={`${t("expenseReport")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={() => <></>}
        HeaderComp={HeaderComp}
        tblHead={[...ChangeArrayKey(csvHeader, "key", "id")]}
        tblData={tblData}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(ExpenseReport);
