import React, { useEffect } from "react";
import { InputCheck } from "../../../controls";
import { useTranslation } from "react-i18next";
import { Collapse } from "@mui/material";

const ReportAccess = ({ setFeaturesAccess, featuresAccess }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!featuresAccess.id) {
      featuresAccess.isHaveReportAccess
        ? setFeaturesAccess((j) => ({
            ...j,
            report: {
              canSeeStoreReport: true,
              canSeeProductReport: true,
              canSeePurchaseReport: true,
              canSeeRegisterReport: true,
              canSeeExpenseReport: true,
              canSeeSellVolumeReport: true,
              canSeeEmployeeReport: true,
              canSeeTopSellingReport: true,
              canSeePurchaseReturnReport: true,
              canSeeSellReturnReport: true,
              canSeeInventoryStatusReport: true,
            },
          }))
        : setFeaturesAccess((j) => ({
            ...j,
            report: {
              canSeeStoreReport: false,
              canSeeProductReport: false,
              canSeePurchaseReport: false,
              canSeeRegisterReport: false,
              canSeeExpenseReport: false,
              canSeeSellVolumeReport: false,
              canSeeEmployeeReport: false,
              canSeeTopSellingReport: false,
              canSeePurchaseReturnReport: false,
              canSeeSellReturnReport: false,
              canSeeInventoryStatusReport: false,
            },
          }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresAccess.isHaveReportAccess]);

  const handleInpChg = (e) => {
    const { name, checked } = e.target;
    setFeaturesAccess((j) => ({
      ...j,
      report: { ...j.report, [name]: checked },
    }));
  };

  return (
    <Collapse in={featuresAccess.isHaveReportAccess || false}>
      <div className="ml-25 d-grid g-5 mt-5">
        <InputCheck
          label={t("canSeeStoreReport")}
          checked={
            (featuresAccess.report &&
              featuresAccess.report.canSeeStoreReport) ||
            false
          }
          name={"canSeeStoreReport"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeeInventoryStatusReport")}
          checked={
            (featuresAccess.report &&
              featuresAccess.report.canSeeInventoryStatusReport) ||
            false
          }
          name={"canSeeInventoryStatusReport"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeeProductReport")}
          checked={
            (featuresAccess.report &&
              featuresAccess.report.canSeeProductReport) ||
            false
          }
          name={"canSeeProductReport"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeePurchaseReport")}
          checked={
            (featuresAccess.report &&
              featuresAccess.report.canSeePurchaseReport) ||
            false
          }
          name={"canSeePurchaseReport"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeeRegisterReport")}
          checked={
            (featuresAccess.report &&
              featuresAccess.report.canSeeRegisterReport) ||
            false
          }
          name={"canSeeRegisterReport"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeeExpenseReport")}
          checked={
            (featuresAccess.report &&
              featuresAccess.report.canSeeExpenseReport) ||
            false
          }
          name={"canSeeExpenseReport"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeeSellVolumeReport")}
          checked={
            (featuresAccess.report &&
              featuresAccess.report.canSeeSellVolumeReport) ||
            false
          }
          name={"canSeeSellVolumeReport"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeeEmployeeReport")}
          checked={
            (featuresAccess.report &&
              featuresAccess.report.canSeeEmployeeReport) ||
            false
          }
          name={"canSeeEmployeeReport"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeeTopSellingReport")}
          checked={
            (featuresAccess.report &&
              featuresAccess.report.canSeeTopSellingReport) ||
            false
          }
          name={"canSeeTopSellingReport"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeePurchaseReturnReport")}
          checked={
            (featuresAccess.report &&
              featuresAccess.report.canSeePurchaseReturnReport) ||
            false
          }
          name={"canSeePurchaseReturnReport"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeeSellReturnReport")}
          checked={
            (featuresAccess.report &&
              featuresAccess.report.canSeeSellReturnReport) ||
            false
          }
          name={"canSeeSellReturnReport"}
          onClick={handleInpChg}
        />
      </div>
    </Collapse>
  );
};

export default ReportAccess;
