import {
  ClearRounded,
  EditRounded,
  HorizontalSplitRounded,
} from "@mui/icons-material";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Dialog } from "../../layout";
import { useContext } from "react";
import { AppContext } from "../../App";
import { DropLi } from "../../hooks";
import BarcodeGen from "./BarcodeGen";
import { useTranslation } from "react-i18next";
import UplProdList from "./UplProdList";
import { Button } from "../../controls";
import { CSVLink } from "react-csv";
import { action, controller, Server } from "../../api/Server";
import { no_img } from "../../assets";
import TableWithPg from "../../layout/TableWithPg";

const Products = () => {
  const { t } = useTranslation();
  const { UnitType, BoolType } = DropLi();
  const [showCsvUpl, setShowCsvUpl] = useState(false);
  const navigate = useNavigate();
  const { selStore, setLoader, settings, authUser } = useContext(AppContext);
  const [barCode, setBarCode] = useState("abcd");
  const [prods, setProds] = useState([]);
  const [showBarcode, setShowBarcode] = useState(false);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [access, setAccess] = useState({});
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const rowsPerTable = 10;

  const [totalRecord, setTotalRecord] = useState(null);
  const [activePgNum, setActivePgNum] = useState(0);

  useEffect(() => {
    if (authUser && authUser.featuresAccess && authUser.featuresAccess.product)
      setAccess({ ...authUser.featuresAccess.product });
    return () => {};
  }, [authUser]);

  useEffect(() => {
    selStore && getStock(0, rowsPerTable);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const getStock = (pageNum, rowsPerPage) => {
    setLoader(true);
    Server(controller.Product + action.GetAllByStorePg).getByIdAngPg(
      selStore,
      pageNum,
      rowsPerPage,
      (res) => {
        if (res && res.status === 200) {
          setProds([...res.result]);
          setTotalRecord(res.totalRecord);
        } else setProds([]);
        setLoader(false);
      }
    );
  };

  // const handleNextPg = (pageNum, rowsPerPage) => {
  //   if (fltStatus !== "all" || fltOutletId || searchType || searchVal) {
  //     handleSearch(
  //       searchType,
  //       searchVal,
  //       fltStatus,
  //       fltOutletId,
  //       pageNum,
  //       rowsPerPage
  //     );
  //   } else
  //    getStock(pageNum, rowsPerTable);
  // };

  // useEffect(() => {
  //   setPageTitle(t("products"));
  //   if (selStore) {
  //     setLoader(true);
  //     Server(controller.Product + action.GetAllByStore).getById(
  //       selStore,
  //       (res) => {
  //         setLoader(false);
  //         setProds([...res.result]);
  //       }
  //     );
  //   }

  //   return () => {};
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selStore]);

  const handleNextPg = (pageNum, rowsPerPage) => {
    // if (fltStatus !== "all" || fltOutletId || searchType || searchVal) {
    //   handleSearch(
    //     searchType,
    //     searchVal,
    //     fltStatus,
    //     fltOutletId,
    //     pageNum,
    //     rowsPerPage
    //   );
    // } else getStock(pageNum, rowsPerTable);
    getStock(pageNum, rowsPerTable);
  };

  const handleDlt = (id) => {
    access.isHaveDeleteAccess &&
      setAlert({
        isOpen: true,
        type: "confirmation",
        title: t("areYouSure"),
        subTitle: t("prodDltTitle"),
        onConfirm: () => {
          const onComplete = (res) => {
            if (res.status === 200) {
              setAlert({
                isOpen: true,
                type: "success",
                title: t("dltSuccessTitle"),
                subTitle: res.msg,
              });
              setProds((x) => [...x.filter((j) => j.id !== res.result.id)]);
            } else
              setAlert({
                isOpen: true,
                type: "error",
                title: t("error"),
                subTitle: res.msg,
              });
          };
          // Call delete on the server.
          Server(controller.Product).Delete(id, onComplete);
        },
      });
  };

  const handleBarcode = (barcode) => {
    setBarCode(barcode);
    setShowBarcode(true);
  };

  useEffect(() => {
    setCsvHeader([
      { key: "id", label: t("id") },
      { key: "name", label: t("name") },
      { key: "barcode", label: t("barcode") },
      { key: "category", label: t("category") },
      { key: "subcategory", label: t("subcategory") },
      { key: "unitType", label: t("unitType") },
      { key: "tax", label: t("tax") },
      { key: "purchPrice", label: t("purchasePrice") },
      { key: "sellPrice", label: t("sellPrice") },
      { key: "inventory", label: t("inventory") },
      { key: "alertQty", label: t("alertQty") },
    ]);

    return () => {
      setCsvHeader();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTblData([
      ...prods.map((obj) => ({
        ...obj,
        name: obj.name + (obj.localName ? ` | ${obj.localName}` : ""),
        category: obj.category !== null ? obj.category.name : "",
        subCat: obj.parentCat !== null ? obj.parentCat.name : "",
        unit: UnitType.find((j) => j.id === obj.unit).label,
        tax: BoolType.find((j) => j.id === obj.tax).label,
        purchPrice:
          settings &&
          settings.curSymbol + " " + obj.purchPrice.toFixed(settings.decimal),
        sellPrice:
          settings &&
          settings.curSymbol + " " + obj.sellPrice.toFixed(settings.decimal),
      })),
    ]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prods]);

  const handleImportExcel = () => {
    setShowCsvUpl(true);
  };

  const ActionComp = (props) => (
    <div className="bs-secondary br-8 w-fit">
      {access.isHaveBarcodePrintAccess && (
        <Tooltip arrow title={t("printBarcode")}>
          <IconButton
            onClick={() => handleBarcode(props.barcode)}
            size="small"
            color="primary"
            className="mr-5"
          >
            <HorizontalSplitRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      )}
      {access.isHaveEditAccess && (
        <Tooltip arrow title={t("edit")}>
          <IconButton
            onClick={() => navigate(`product-form/${props.id}`)}
            size="small"
            color="primary"
            className="mr-5"
          >
            <EditRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      )}
      {access.isHaveDeleteAccess && (
        <Tooltip arrow title={t("delete")}>
          <IconButton
            onClick={() => handleDlt(props.id)}
            size="small"
            color="error"
          >
            <ClearRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      {access.isHaveAddAccess && (
        <Button
          onClick={() => navigate("product-form")}
          variant="text"
          sx={{
            width: { md: "180px", xs: "100%" },
            mb: { xs: "10px", md: "0px" },
            fontSize: "17px",
            padding: "8px",
          }}
        >
          {t("addProduct")}
        </Button>
      )}
      {access.isHaveImportAccess && (
        <Button
          variant="text"
          sx={{ fontSize: "17px", width: { md: "180px", xs: "100%" } }}
          onClick={handleImportExcel}
        >
          {t("importExcel")}
        </Button>
      )}
      {access.isHaveExportAccess && (
        <Box
          className="d-flex"
          sx={{
            width: { xs: "100%", md: "auto" },
          }}
        >
          <CSVLink
            data={tblData}
            headers={csvHeader}
            filename={`${t("products")} (${new Date().toDateString()}).csv`}
            target="_blank"
            className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
          >
            {t("exportCSV")}
          </CSVLink>
        </Box>
      )}
    </div>
  );

  return (
    <>
      <TableWithPg
        // onChange={handleSearchChg}
        // onSubmit={handleSearchSubmit}
        showSearch={false}
        onPageChg={handleNextPg}
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        totalRecord={totalRecord}
        pageNum={activePgNum}
        setPageNum={setActivePgNum}
        searchOptions={[]}
        tblHead={[
          { id: "actions", label: t("actions") },
          { id: "prodInfo", label: t("product") },
          { id: "catInfo", label: t("category") },
          { id: "inv", label: t("inventory") },
          { id: "prodPrice", label: t("price") },
        ]}
        tblData={tblData.map((prod) => {
          var prodImg = prod.imgs[0];
          return {
            ...prod,
            textTerm: `tax ${prod.tax}`,
            prodInfo: (
              <div className="d-flex g-10">
                <Avatar
                  src={prodImg ? prodImg.imgUrl : no_img}
                  alt="Product"
                  variant="rounded"
                  sx={{ width: 45, height: 45 }}
                />
                <div className="d-grid g-2">
                  <span>{prod.name}</span>
                  <span className="fs-12 fw-5">{prod.barcode}</span>
                </div>
              </div>
            ),
            catInfo: (
              <div className="d-grid g-2">
                <span>{prod.category}</span>{" "}
                {prod.subCat && (
                  <span>
                    {" "}
                    {" > "} {prod.subCat}
                  </span>
                )}
              </div>
            ),
            inv: (
              <span className="fw-5 gray-color fs-18">{prod.inventory}</span>
            ),
            prodPrice: (
              <div className="d-grid g-2 fs-14">
                <div className="d-flex g-5">
                  <span>{t("purchasePrice")}: </span>
                  <span className="fw-5">{prod.purchPrice}</span>
                </div>
                <div className="d-flex g-5">
                  <span>{t("sellPrice")}: </span>
                  <span className="fw-5">{prod.sellPrice}</span>
                </div>
              </div>
            ),
          };
        })}
      />
      {/* <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          { id: "prodInfo", label: t("product") },
          { id: "catInfo", label: t("category") },
          { id: "inv", label: t("inventory") },
          { id: "prodPrice", label: t("price") },
        ]}
        tblData={tblData.map((prod) => {
          var prodImg = prod.imgs[0];
          return {
            ...prod,
            textTerm: `tax ${prod.tax}`,
            prodInfo: (
              <div className="d-flex g-10">
                <Avatar
                  src={prodImg ? prodImg.imgUrl : no_img}
                  alt="Product"
                  variant="rounded"
                  sx={{ width: 45, height: 45 }}
                />
                <div className="d-grid g-2">
                  <span>{prod.name}</span>
                  <span className="fs-12 fw-5">{prod.barcode}</span>
                </div>
              </div>
            ),
            catInfo: (
              <div className="d-grid g-2">
                <span>{prod.category}</span>{" "}
                {prod.subCat && (
                  <span>
                    {" "}
                    {" > "} {prod.subCat}
                  </span>
                )}
              </div>
            ),
            inv: (
              <span className="fw-5 gray-color fs-18">{prod.inventory}</span>
            ),
            prodPrice: (
              <div className="d-grid g-2 fs-14">
                <div className="d-flex g-5">
                  <span>{t("purchasePrice")}: </span>
                  <span className="fw-5">{prod.purchPrice}</span>
                </div>
                <div className="d-flex g-5">
                  <span>{t("sellPrice")}: </span>
                  <span className="fw-5">{prod.sellPrice}</span>
                </div>
              </div>
            ),
          };
        })}
      /> */}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {/* Generate Barcode */}
      <Dialog show={showBarcode} onClose={false} maxWidth="lg">
        <BarcodeGen value={barCode} setShowPopup={setShowBarcode} />
      </Dialog>

      {/* Upload File Form */}
      {showCsvUpl && (
        <Dialog show={showCsvUpl} onClose={false} maxWidth="md">
          <UplProdList
            {...{
              setShowForm: setShowCsvUpl,
              setAlert,
              alert,
              setProds,
            }}
          />
        </Dialog>
      )}
    </>
  );
};

export default Products;
