import React, { useState } from "react";
import { connect } from "react-redux";
import { Alert, Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { DropLi } from "../../hooks";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import { ChangeArrayKey } from "../../hooks/Method";
import { Box } from "@mui/material";
import { CSVLink } from "react-csv";

const EmployeeHistory = (props) => {
  const { _get } = props;
  const { t } = useTranslation();
  const { Periodic } = DropLi();
  const { setLoader } = useContext(AppContext);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    const onComplete = (res) => {
      setLoader(false);
      res.status === 200 &&
        setTblData(
          res.result.map((obj) => ({
            ...obj,
            salaryType:
              obj.salaryType &&
              Periodic.find((j) => j.id === obj.salaryType).name,
          }))
        );
    };

    setLoader(true);
    _get(endpoints.User + controller.GetAllEmployees, null, onComplete);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCsvHeader([
      { key: "fullName", label: t("fullName") },
      { key: "dob", label: t("dateOfBirth") },
      { key: "salaryType", label: t("salaryType") },
      { key: "salary", label: t("salary") },
      { key: "addDate", label: t("hireDate") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={tblData}
          headers={csvHeader}
          filename={`${t(
            "Employee History"
          )} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={() => <div></div>}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          ...ChangeArrayKey(csvHeader, "key", "id"),
        ]}
        tblData={tblData}
      />
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
});

const mapActionToProps = {
  _get: actions.get,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(EmployeeHistory);
