import React from "react";
import { Grid } from "@mui/material";
import { Input, InputDate, Select } from "../../../controls";
import { useTranslation } from "react-i18next";
import { DropLi } from "../../../hooks";

const ManagerFields = ({ values, errors, handleInpChg, handleInpDate }) => {
  const { t } = useTranslation();
  const { Gender } = DropLi();

  return (
    <Grid container spacing={2.5}>
      <Grid item xs={12} md={6}>
        <Input
          label={t("fullName")}
          name="fullName"
          value={values.fullName}
          error={errors.fullName}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 150 }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label={t("phone")}
          name="mngPhone"
          value={values.mngPhone}
          error={errors.mngPhone}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 50 }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputDate
          label={t("dateOfBirth")}
          name="dob"
          required={true}
          value={values.dob}
          error={errors.dob}
          maxDate={new Date()}
          onChange={(date) =>
            handleInpDate("dob", new Date(date).toLocaleDateString())
          }
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Select
          label={t("gender")}
          name="gender"
          fullWidth
          value={values.gender}
          error={errors.gender}
          onChange={handleInpChg}
          options={Gender}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Input
          label={t("SSN")}
          name="ssn"
          value={values.ssn}
          error={errors.ssn}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 100 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label={t("address")}
          name="mngAddress"
          value={values.mngAddress}
          error={errors.mngAddress}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 250 }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label={t("city")}
          name="mngCity"
          value={values.mngCity}
          error={errors.mngCity}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 200 }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label={t("state")}
          name="mngState"
          value={values.mngState}
          error={errors.mngState}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 200 }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label={t("ZIP")}
          name="mngZip"
          value={values.mngZip}
          error={errors.mngZip}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 50 }}
        />
      </Grid>
    </Grid>
  );
};

export default ManagerFields;
