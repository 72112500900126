import React, { useContext, useState } from "react";
import { Button } from "../../controls";
import { Form, Tabs } from "../../layout";
import { Model, UseForm } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { CleanObject, ToFormData } from "../../hooks/Method";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import GeneralFields from "./GeneralFields";
import AddressFields from "./AddressFields";
import PermissionsFields from "./PermissionsFields";
import SalaryFields from "./SalaryFields";
import { Divider, Paper } from "@mui/material";
import { action, controller, Server } from "../../api/Server";

const AddEditUser = () => {
  const { t } = useTranslation();
  const { UserMdl } = Model();
  const navigate = useNavigate();
  const { stores, setPageTitle, pageTitle } = useContext(AppContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [storeLi, setStoreLi] = useState([]);

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleInpDate,
    handleInpPass,
    passValid,
    resetFormControls,
  } = UseForm(UserMdl);

  useEffect(() => {
    setPageTitle &&
      setPageTitle((id ? t("update") : t("add")) + " " + t("user"));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      Server(controller.User + action.GetById).getById(id, (res) => {
        res.status === 200 &&
          setValues((j) => ({
            ...j,
            ...CleanObject({
              ...res.result,
              stores: res.result.stores.map((j) => j.storeId),
            }),
          }));
      });
    }
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setStoreLi([...stores.map((j) => ({ ...j, label: j.storeName }))]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores]);

  const checkUserName = (value) => {
    if (value !== "") {
      Server(controller.User + action.CheckUserName).post(
        { userId: values.id ? values.id : 0, value: value },
        (res) => {
          res.result === true &&
            setErrors({ ...errors, userName: t("userNameIsAlreadyExist") });
        }
      );
    }
  };

  const validateForm = () => {
    let temp = {};
    temp.fullName = values.fullName !== "" ? "" : t("nameIsRequired");
    temp.userName =
      values.userName !== ""
        ? errors.userName !== undefined
          ? errors.userName
          : ""
        : t("userNameIsRequired");
    temp.prefix = values.prefix !== "" ? "" : t("prefixIsRequired");
    temp.email =
      values.email !== ""
        ? /^$|.+@.+..+/.test(values.email)
          ? ""
          : t("emailIsNotValid")
        : t("emailIsRequired");
    temp.language = values.language !== "" ? "" : t("languageIsRequired");
    temp.role = values.role !== "" ? "" : t("roleIsRequired");
    temp.isActive = values.isActive !== "" ? "" : t("isActiveIsRequired");
    temp.stores =
      values.stores && values.stores.length > 0 ? "" : t("storeIsRequired");
    // Password Property required only account creating time.
    if (!id && values.role !== 4) {
      temp.password = values.password !== "" ? "" : t("passwordIsRequired");
      temp.confirmPass =
        values.confirmPass !== ""
          ? values.confirmPass === values.password
            ? ""
            : t("passwordAndConfirmPasswordDidNotMatch")
          : t("confirmPassIsRequired");
    }

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const onComplete = () => {
      navigate("/users");
      setLoading(false);
    };
    if (validateForm()) {
      setLoading(true);
      values.dob !== "" &&
        (values.dob = new Date(values.dob).toLocaleDateString());
      if (values.id === 0) {
        Server(controller.User).post(
          ToFormData({
            ...values,
            stores: values.stores.map((j) => ({ ...j, storeId: j })),
          }),
          onComplete
        );
      } else if (values.id !== 0) {
        Server(controller.User).put(
          ToFormData({
            ...values,
            stores: values.stores.map((j) => ({ ...j, storeId: j })),
            updateDate: new Date().toLocaleString(),
          }),
          onComplete
        );
      }
    }
  };

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Paper>
          <div>
            <div className="d-flex j-between a-center p-10 pb-10">
              <div>
                <span className="border-left fs-22 tx-upp fw-5 gray-color pl-10">
                  {pageTitle}
                </span>
              </div>

              <Button
                loading={loading}
                onClick={handleSubmit}
                size="large"
                sx={{ minWidth: "150px" }}
              >
                {id ? t("saveChanges") : t("submit")}
              </Button>
            </div>
          </div>
          <Divider />
          <div className="p-15">
            <Tabs
              className="mt-10"
              header={[
                { label: t("generalFields"), icon: "" },
                { label: t("salaryScale"), icon: "" },
                { label: t("addressFields"), icon: "" },
              ]}
            >
              <>
                {/* User Info. */}
                <GeneralFields
                  {...{ values, errors, t, handleInpChg, handleInpDate }}
                />
                {/* Roles and Permissions */}
                <PermissionsFields
                  {...{
                    t,
                    values,
                    setValues,
                    errors,
                    handleInpChg,
                    handleInpPass,
                    storeLi,
                    passValid,
                    checkUserName,
                  }}
                />
              </>
              {/* Salary Scale */}
              <SalaryFields {...{ t, values, errors, handleInpChg }} />
              {/* Address */}
              <AddressFields {...{ values, errors, t, handleInpChg }} />
            </Tabs>
          </div>
        </Paper>
      </Form>
    </>
  );
};

export default AddEditUser;
