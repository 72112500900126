import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "../../../controls";

const SMTPConfig = ({ values, setValues, errors }) => {
  const { t } = useTranslation();

  const handleInpChg = (e) => {
    setValues((j) => ({
      ...j,
      emailConfig: {
        ...j.emailConfig,
        [e.target.name]: e.target.value,
      },
    }));
  };

  return (
    values.useOwnEmail && (
      <Grid container spacing={2.5} className="mt-10">
        <Grid item xs={12} md={4}>
          <Input
            label={t("senderDisplayName")}
            name="senderDisplayName"
            value={values.emailConfig?.senderDisplayName}
            error={errors.emailConfig?.senderDisplayName}
            onChange={handleInpChg}
            fullWidth
            required
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label={t("senderAddress")}
            name="senderAddress"
            value={values.emailConfig?.senderAddress}
            error={errors.emailConfig?.senderAddress}
            onChange={handleInpChg}
            fullWidth
            required
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label={t("userName")}
            name="userName"
            value={values.emailConfig?.userName}
            error={errors.emailConfig?.userName}
            onChange={handleInpChg}
            fullWidth
            required
            inputProps={{ maxLength: 500 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label={t("password")}
            name="password"
            value={values.emailConfig?.password}
            error={errors.emailConfig?.password}
            onChange={handleInpChg}
            fullWidth
            required
            inputProps={{ maxLength: 500 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label={t("host")}
            name="host"
            value={values.emailConfig?.host}
            error={errors.emailConfig?.host}
            onChange={handleInpChg}
            fullWidth
            required
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label={t("port")}
            name="port"
            value={values.emailConfig?.port}
            error={errors.emailConfig?.port}
            onChange={handleInpChg}
            fullWidth
            required
            inputProps={{ maxLength: 100 }}
            type="number"
          />
        </Grid>
      </Grid>
    )
  );
};

export default SMTPConfig;
