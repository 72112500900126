import {
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { CloseRounded } from "@mui/icons-material";

const ViewDetails = (props) => {
  const { viewData, setShowForm } = props;
  const { t } = useTranslation();

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className="d-flex j-between a-center p-10 pb-5">
            <div>
              <span className="border-left fs-20 fw-5 gray-color">
                {viewData.product.name}
              </span>
            </div>
            <div className="d-flex g-10">
              <IconButton onClick={handleClose} color="error" size="small">
                <CloseRounded />
              </IconButton>
            </div>
          </div>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {viewData && viewData.invUsers.length > 0 ? (
            <TableContainer className="mt-20">
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("userName")}</TableCell>
                    <TableCell>{t("inventoryAdded")}</TableCell>
                    <TableCell>{t("date")}</TableCell>
                    <TableCell>{t("note")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {viewData.invUsers
                    .sort((a, b) => b.id - a.id)
                    .map((inv, i) => (
                      <TableRow key={i}>
                        <TableCell>{inv.user.fullName}</TableCell>
                        <TableCell>{inv.addedInv}</TableCell>
                        <TableCell>
                          {new Date(inv.date).toLocaleString()}
                        </TableCell>
                        <TableCell>{inv.note}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div className="tx-center p-20">
              <span className="fs-20 fw-5 gray-color tx-upp">No Data</span>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ViewDetails;
