import { useEffect, useState } from "react";

export const WinDims = () => {
  const hasWindow = typeof window !== "undefined";
  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasWindow]);
  return windowDimensions;
};

export const RandomCode = (length = 10) => {
  if (typeof length !== "number" || length <= 0) {
    throw new Error("Length must be a positive number");
  }

  const maxRandomValue = Math.pow(10, length) - 1; // Calculate the maximum random value
  const randomNum = Math.floor(Math.random() * maxRandomValue); // Generate a random number within the desired length

  return randomNum;
};

export const ChangeArrayKey = (array, oldKeyName, newKeyName) => {
  return array.map((item) => {
    const newItem = { ...item };
    newItem[newKeyName] = newItem[oldKeyName];
    delete newItem[oldKeyName];
    return newItem;
  });
};

export const GetMonths = () => {
  const date = new Date();
  return [
    `01/${date.getFullYear()}`,
    `02/${date.getFullYear()}`,
    `03/${date.getFullYear()}`,
    `04/${date.getFullYear()}`,
    `05/${date.getFullYear()}`,
    `06/${date.getFullYear()}`,
    `07/${date.getFullYear()}`,
    `08/${date.getFullYear()}`,
    `09/${date.getFullYear()}`,
    `10/${date.getFullYear()}`,
    `11/${date.getFullYear()}`,
    `12/${date.getFullYear()}`,
  ];
};

export const IsEmptyObj = (obj) =>
  obj !== undefined && obj !== null && Object.keys(obj).length === 0
    ? true
    : false;

export const CleanObject = (obj) => {
  if (!obj) return undefined;

  if (Array.isArray(obj)) {
    const cleanedArray = obj
      .map((item) => CleanObject(item))
      .filter((item) => item !== undefined);
    return cleanedArray.length ? cleanedArray : undefined;
  }

  if (typeof obj === "object" && !(obj instanceof File)) {
    const cleanedEntries = Object.entries(obj)
      .map(([key, value]) => [key, CleanObject(value)])
      .filter(([, value]) => value !== undefined);

    return cleanedEntries.length
      ? Object.fromEntries(cleanedEntries)
      : undefined;
  }

  return obj; // Primitive values (non-null, non-empty)
};

export const ToFormData = (obj, formData = new FormData(), parentKey = "") => {
  // Clean the object before converting to FormData
  const object = CleanObject(obj);

  if (object && typeof object === "object" && !(object instanceof File)) {
    Object.keys(object).forEach((key) => {
      const value = object[key];
      const newKey = parentKey ? `${parentKey}[${key}]` : key;

      if (Array.isArray(value)) {
        // Check if the array contains File objects
        if (value.every((item) => item instanceof File)) {
          // If all items are files, append them as multiple files
          value.forEach((item, index) => {
            formData.append(newKey, item); // Append each file in the array
          });
        } else {
          // Handle arrays of objects (like sellPrices)
          value.forEach((item, index) => {
            const arrayKey = `${newKey}[${index}]`;
            ToFormData(item, formData, arrayKey); // Recursively process each item
          });
        }
      } else if (value instanceof File) {
        formData.append(newKey, value); // Append single file
      } else if (typeof value === "object") {
        ToFormData(value, formData, newKey); // Recursively process nested objects
      } else {
        formData.append(newKey, value); // Append non-object values
      }
    });
  } else {
    formData.append(parentKey, object); // Append non-object values
  }

  return formData;
};

export const PrintDiv = (printDivId) => {
  var printContents = document.getElementById(printDivId).innerHTML;
  var frame1 = document.createElement("iframe");
  frame1.name = "frame1";
  frame1.style.position = "absolute";
  frame1.style.top = "-1000000px";
  document.body.appendChild(frame1);
  var frameDoc = frame1.contentWindow
    ? frame1.contentWindow
    : frame1.contentDocument.document
    ? frame1.contentDocument.document
    : frame1.contentDocument;

  frameDoc.document.open();
  frameDoc.document.write("<html><head><title></title>");
  frameDoc.document.write("</head><body>");
  frameDoc.document.write(printContents);
  frameDoc.document.write("</body></html>");
  frameDoc.document.close();

  setTimeout(function () {
    window.frames["frame1"].focus();
    window.frames["frame1"].print();
    document.body.removeChild(frame1);
  }, 100);

  return false;
};

export const MaxLength = (val, length = 5, maxVal) => {
  return val && val.replace(/[^0-9.]/g, "").slice(0, length);
};
