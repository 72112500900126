import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Alert, Form } from "../../../layout";
import { Button, InputPass } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { useNavigate } from "react-router-dom";

import { ToFormData } from "../../../hooks/Method";
import { PassValidation } from "../../../utils";
import { useTranslation } from "react-i18next";
import { action, controller, Server } from "../../../api/Server";

const ChangePass = (props) => {
  const { setActiveStep, setForgotVal, forgotVal } = props;
  const { t } = useTranslation();
  const { ForgotMdl } = Model();
  const navigate = useNavigate();
  const [err, setErr] = useState();
  const [loading, setLoading] = useState(false);
  const { values, setValues, errors, setErrors, handleInpPass, passValid } =
    UseForm(ForgotMdl);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const validateForm = () => {
    let temp = {};
    temp.password =
      values.password !== ""
        ? !passValid.passValid
          ? ""
          : ""
        : t("passwordIsRequired");
    temp.confirmPass =
      values.confirmPass !== ""
        ? !passValid.match
          ? t("passwordAndConfirmPasswordNotMatch")
          : ""
        : t("confirmPasswordIsRequired");
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      values.forgotStep = 3;
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 200) {
          navigate("/dashboard");
          window.location.reload();
        } else setErr(res.msg);
      };

      Server(controller.Auth + action.Forgot).post(
        ToFormData(values),
        onComplete
      );
    }
  };

  useEffect(() => {
    setValues(forgotVal);
    return () => {
      setValues();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setForgotVal({ ...values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <>
      <Form noValidate onSubmit={submitForm}>
        <Grid container className="p-15 pt-0" spacing={2}>
          <Grid item xs={12} className="tx-center mt-30 pb-30">
            <h2 className="fs-22 fw-6 tx-upp h gray-color">
              {t("changePassword")}
            </h2>
            <div className="pos-relative d-flex j-center mt-5">
              <div className="border-bottom w-60" />
            </div>
          </Grid>
          {err && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="error">
                <span className="fw-6 tx-upp"> {t("error")}:&nbsp;</span> {err}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <InputPass
              label={t("password")}
              name="password"
              value={values.password}
              onChange={handleInpPass}
              error={errors.password}
              className="input-primary w-100pr"
            />
            <PassValidation {...{ password: values.password, passValid }} />
          </Grid>
          <Grid item xs={12}>
            <InputPass
              label={t("confirmPassword")}
              name="confirmPass"
              value={values.confirmPass}
              onChange={(e) => handleInpPass(e, true)}
              error={errors.confirmPass}
              className="input-primary w-100pr"
            />
          </Grid>
          <Grid item xs={12} className="text-center mt-20">
            <Button
              type="submit"
              loading={loading}
              size="large"
              className="w-100pr"
            >
              {t("changePassword")}
            </Button>
          </Grid>
        </Grid>
      </Form>
      <Alert
        onClick={() => setActiveStep(undefined)}
        alert={alert}
        setAlert={setAlert}
      />
    </>
  );
};

export default ChangePass;
