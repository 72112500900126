import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Paper, Typography } from "@mui/material";
import moment from "moment/moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";

const PurchaseAndSellChart = ({ sellsPerMonth, purchPerMonth }) => {
  const { settings } = useContext(AppContext);
  const { t } = useTranslation();
  const [opt, setOpt] = useState({});

  useEffect(() => {
    settings &&
      setOpt({
        chart: {
          type: "bar",
          height: 350,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: "rounded",
          },
        },

        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: moment.monthsShort(),
        },
        yaxis: {
          title: {
            text: t("totalSales"),
            style: {
              color: "var(--gray-color)",
              fontFamily: "roboto",
            },
          },
          labels: {
            formatter: (val) =>
              `${settings.curSymbol} ${val.toFixed(settings.decimal)}`,
            style: {
              fontSize: "14px",
            },
          },
        },
        fill: {
          opacity: 1,
        },
      });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const series = [
    {
      name: t("sells"),
      data: sellsPerMonth,
    },
    {
      name: t("purchase"),
      data: purchPerMonth,
    },
  ];

  return (
    <Paper className="p-15">
      {/* Chart */}
      <Typography varint="h6" className="fs-18 fw-5 gray-color mb-10">
        {t("purchaseSellsReports")}
      </Typography>
      <ReactApexChart
        options={opt}
        series={series}
        type="bar"
        width="100%"
        height="300"
      />
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
  _defStore: state.pos.defStore,
});

const mapActiontoProps = {};

export default connect(mapStateToProps, mapActiontoProps)(PurchaseAndSellChart);
