import {
  Autocomplete,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import {
  Button,
  Input,
  InputDate,
  InputQty,
  LabelComp,
  Select,
} from "../../../controls";
import { Alert, Form } from "../../../layout";
import { DropLi, Model, UseForm } from "../../../hooks";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { ClearRounded } from "@mui/icons-material";
import { useEffect } from "react";
import { MaxLength, ToFormData } from "../../../hooks/Method";
import { AppContext } from "../../../App";
import { useTranslation } from "react-i18next";
import { action, controller, Server } from "../../../api/Server";

const AddEditPurchReturn = () => {
  const { t } = useTranslation();
  const { PurchReturnMdl } = Model();
  const { selStore, setPageTitle, settings, authUser } = useContext(AppContext);
  const navigate = useNavigate();
  const { ReturnReason } = DropLi();
  const { values, setValues, errors, setErrors, handleInpChg, handleInpDate } =
    UseForm(PurchReturnMdl);

  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const [searchVal, setSearchVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchLi, setSearchLi] = useState([]);
  const [netAmount, setNetAmount] = useState(0);
  const [supplier, setSupplier] = useState("");

  const { id } = useParams();

  useEffect(() => {
    setPageTitle && setPageTitle(t("purchaseReturn"));
    if (values.purchaseId) {
      const onComplete = (res) => {
        if (res.status !== 200) {
          setErrors((x) => ({ ...x, purchaseId: t("invalidReferenceNo") }));
          setValues((x) => ({ ...x, purchReturnItems: [] }));
        } else setErrors((x) => ({ ...x, purchaseId: "" }));

        setSupplier(res.result.supplier && res.result.supplier.company);
      };

      Server(controller.Purchase + action.GetById).post(
        {
          id: values.purchaseId,
          storeId: selStore,
        },
        onComplete
      );
    } else {
      setSupplier("");
      setValues((x) => ({ ...x, purchReturnItems: [] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.purchaseId, selStore]);

  useEffect(() => {
    if (values.purchReturnItems) {
      var netAmount = values.purchReturnItems
        .map((j) => j.qty * j.purchPrice)
        .reduce((x, y) => x + y, 0);
      var discount = (parseFloat(values.discount || 0) / 100) * netAmount;
      setNetAmount((netAmount - discount).toFixed(settings.decimal));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, values.discount]);

  useEffect(() => {
    const onComplete = (res) => {
      if (res.status !== 200 || !res.result?.purchReturnItems) return;

      const { purchase, purchReturnItems } = res.result;
      const purchItems = purchase?.purchItems || [];

      setValues((prev) => ({
        ...prev,
        ...res.result,
        purchReturnItems: purchReturnItems.map((j) => {
          const purchData = purchItems.find((x) => x.productId === j.productId);
          return {
            ...j,
            purchPrice: purchData?.purchPrice ?? j.product.purchPrice,
            purchQty: j.qty,
            returnReason: j.returnReason === 0 ? "" : j.returnReason,
          };
        }),
      }));

      setSupplier(purchase?.supplier?.company || "");
    };

    if (id && selStore) {
      Server(controller.PurchReturn + action.GetById).post(
        { id, storeId: selStore },
        onComplete
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selStore]);

  const validateForm = () => {
    let temp = {};
    temp.purchaseId =
      values.purchaseId !== "" ? "" : t("referenceNoRsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = () => {
    if (
      validateForm() &&
      values.purchReturnItems &&
      values.purchReturnItems.length > 0
    ) {
      // setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        res && (res.status === 201 || res.status === 200)
          ? navigate("/purchases-return")
          : setAlert({
              isOpen: true,
              type: "error",
              title: t("error"),
              subTitle: res.msg,
            });
      };

      if (values.purchReturnItems)
        values.purchReturnItems = values.purchReturnItems.map((j) => ({
          ...j,
          product: "",
        }));

      if (id) {
        Server(controller.PurchReturn).put(
          ToFormData({
            ...values,
            updateDate: new Date().toLocaleString(),
          }),
          onComplete
        );
      } else {
        Server(controller.PurchReturn).post(
          ToFormData({ ...values, userId: authUser.id, storeId: selStore }),
          onComplete
        );
      }
    } else if (values.purchReturnItems && values.purchReturnItems <= 0)
      setAlert({
        isOpen: true,
        type: "error",
        title: t("emptyReturnList"),
        subTitle: t("returnPurchWarning"),
      });
  };

  const GetDataFromServer = (val, onComplete) => {
    // Get Data From Server
    if (val) {
      Server(controller.PurchReturn + action.FindProducts).post(
        { id: values.purchaseId, value: val },
        onComplete
      );
    }
  };

  const handleSearch = (e, val) => {
    setSearchVal(val);
    const onComplete = (res) => {
      res.status === 200 &&
        res.result &&
        setSearchLi([
          ...res.result.map((j) => {
            return {
              ...j.product,
              id: j.product.id,
              label: `${j.product.name} (${j.product.barcode})`,
              purchQty: j.qty,
            };
          }),
        ]);
    };
    e && GetDataFromServer(e.target.value, onComplete);
  };

  const handleScan = (e) => {
    const onComplete = (res) => {
      if (res.status === 200 && res.result && res.result.length > 0) {
        const val = res.result.find((j) => j.product.barcode === searchVal);
        val.product.purchQty = val.qty;
        val && handleSelect(null, val.product);
      }
    };
    GetDataFromServer(e.target.value, onComplete);
    setSearchVal("");
  };

  const handleSelect = (e, product) => {
    if (product) {
      if (!values.purchReturnItems.some((j) => j.productId === product.id)) {
        if (product.purchQty > 0) {
          setValues((x) => ({
            ...x,
            purchReturnItems: [
              {
                productId: product.id,
                qty: product.purchQty >= 1 ? 1 : product.purchQty,
                purchQty: product.purchQty,
                purchPrice: product.purchPrice.toFixed(settings.decimal),
                product: product,
                returnReason: "",
              },
              ...x.purchReturnItems,
            ],
          }));
        } else
          setAlert({
            isOpen: true,
            type: "warning",
            title: "Not have purchase quantity",
            subTitle: "This product has no purchase quantity for return",
          });
      } else if (
        values.purchReturnItems.some((j) => j.productId === product.id)
      ) {
        setValues((x) => ({
          ...x,
          purchReturnItems: x.purchReturnItems.map((j) =>
            j.productId === product.id
              ? {
                  ...j,
                  qty:
                    parseInt(j.qty) + 1 <= j.purchQty
                      ? parseInt(j.qty) + 1
                      : j.qty,
                }
              : j
          ),
        }));
      }
    }
    setSearchVal("");
  };

  const handleQtyChg = (e, productId) => {
    setValues((x) => ({
      ...x,
      purchReturnItems: x.purchReturnItems.map((j) =>
        j.productId === productId
          ? {
              ...j,
              qty:
                Math.abs(e.target.value) <= j.purchQty ? e.target.value : j.qty,
            }
          : j
      ),
    }));
  };

  const handleInc = (productId) => {
    setValues((x) => ({
      ...x,
      purchReturnItems: x.purchReturnItems.map((j) =>
        j.productId === productId
          ? {
              ...j,
              qty:
                parseFloat(j.qty) + 1 <= j.purchQty
                  ? parseFloat(j.qty) + 1
                  : j.qty,
            }
          : j
      ),
    }));
  };
  const handleDec = (productId) => {
    setValues((x) => ({
      ...x,
      purchReturnItems: x.purchReturnItems.map((j) =>
        j.productId === productId
          ? { ...j, qty: j.qty >= 1 ? parseFloat(j.qty) - 1 : j.qty }
          : j
      ),
    }));
  };

  const handleRmvItem = (prodId) => {
    setValues((x) => ({
      ...x,
      purchReturnItems: x.purchReturnItems.filter(
        (j) => j.productId !== prodId
      ),
    }));
  };

  const handleReason = (e, productId) => {
    setValues((x) => ({
      ...x,
      purchReturnItems: x.purchReturnItems.map((j) =>
        j.productId === productId ? { ...j, returnReason: e.target.value } : j
      ),
    }));
  };

  return (
    <>
      <Form noValidate>
        <Box className="tx-center p-20">
          <Typography variant="h5" className="fs-26 gray-color fw-5 tx-upp">
            {t("purchaseReturn")}
          </Typography>
          <div className="bs-bottom mx-w-80 mt-5 m-auto" />
        </Box>
        {/* Purchase Details */}
        <div className="pt-20" />
        <Paper className="br-8 shadow-xs p-10">
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Input
                disabled={id === undefined ? false : true}
                label={t("referenceNo")}
                className="rmv-arrow"
                name="purchaseId"
                type="number"
                onInput={(e) => (e.target.value = MaxLength(e.target.value, 8))}
                value={values.purchaseId}
                error={errors.purchaseId}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LabelComp label="Supplier" value={supplier} />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputDate
                label={t("returnDate")}
                name="returnDate"
                value={values.returnDate}
                error={errors.returnDate}
                onChange={(date) =>
                  handleInpDate("returnDate", new Date(date).toLocaleString())
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>

        {/* Items */}
        <div className="pt-20" />
        <Paper className="br-8 shadow-xs p-20">
          {/* Title Bar */}
          <Box className="d-flex j-between">
            <Typography
              variant="subtitle1"
              className="fs-18 fw-5 gray-color pl-10 mb-20"
              sx={{ borderLeft: "5px double var(--primary-color)" }}
            >
              {t("returnPurchases")}
            </Typography>
          </Box>

          {/* Search Box */}
          <Autocomplete
            id="searchBox"
            disabled={supplier ? false : true}
            options={searchLi}
            onChange={handleSelect}
            inputValue={searchVal}
            onInputChange={handleSearch}
            isOptionEqualToValue={(opt, val) => opt.id === val.id}
            onKeyDown={(e) => e.which === 13 && handleScan(e, searchVal)}
            classes={{ paper: "shadow-sm" }}
            componentsProps={{
              paper: {
                sx: {
                  borderRadius: "0px 0px 10px 10px",
                  backgroundColor: "var(--white-color)",
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                variant="filled"
                label={t("searchByNameOrBarcode")}
                {...params}
              />
            )}
          />

          {/* Product Details */}
          <TableContainer className="mt-20">
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("actions")}</TableCell>
                  <TableCell>{t("productName")}</TableCell>
                  <TableCell>{t("returnReason")}</TableCell>
                  <TableCell>{t("quantity")}</TableCell>
                  <TableCell>{t("purchasePrice")}</TableCell>
                  <TableCell>{t("subtotal")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.purchReturnItems &&
                  values.purchReturnItems.map((prod, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Tooltip arrow title={t("delete")}>
                          <IconButton
                            onClick={() => handleRmvItem(prod.productId)}
                            size="small"
                            color="error"
                          >
                            <ClearRounded className="fs-14" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{prod.product && prod.product.name}</TableCell>
                      <TableCell sx={{ minWidth: "80px" }}>
                        <Select
                          variant="outlined"
                          name="returnReason"
                          size="small"
                          fullWidth
                          value={prod.returnReason}
                          onChange={(e) => handleReason(e, prod.productId)}
                          options={ReturnReason}
                          sx={{
                            "& .MuiInputBase-root": {
                              padding: "0px 0px",
                            },
                            "& .MuiInputBase-input": {
                              padding: "5.5px 0px",
                              textAlign: "center",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <InputQty
                          value={prod.qty}
                          onIncrease={() => handleInc(prod.productId)}
                          onDecrease={() => handleDec(prod.productId)}
                          onChange={(e) => handleQtyChg(e, prod.productId)}
                        />
                      </TableCell>
                      <TableCell className="fs-16">
                        {settings.curSymbol} {prod.purchPrice}
                      </TableCell>
                      <TableCell className="fs-16">
                        {settings.curSymbol}{" "}
                        {(prod.qty * prod.purchPrice).toFixed(settings.decimal)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Box className="tx-right p-10 tx-upp">
              <Typography className="fs-16 gray-color fw-5">
                {t("totalItem")}:&nbsp;
                {values.purchReturnItems && values.purchReturnItems.length > 0
                  ? values.purchReturnItems
                      .map((j) => j.qty)
                      .reduce((x, y) => x + y, 0)
                  : 0}
              </Typography>
              <Typography className="fs-20 gray-color fw-6">
                {t("netAmount")}: {settings.curSymbol}&nbsp; {netAmount}
              </Typography>
            </Box>
          </TableContainer>
        </Paper>

        <Grid item xs={12} className="tx-right mt-20">
          <Button
            loading={loading}
            onClick={handleSubmit}
            sx={{ width: { md: "200px", xs: "100%" } }}
            size="large"
          >
            {id ? t("saveChanges") : t("submit")}
          </Button>
        </Grid>
      </Form>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default AddEditPurchReturn;
