import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { useTranslation } from "react-i18next";

const ThermalPrinterRecept = ({ data }) => {
  const { settings } = useContext(AppContext);
  const [store, setStore] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setStore({ ...settings.store });
    return () => {};
  }, [settings]);

  return data.id && store.id ? (
    <div
      style={{
        maxWidth: "58mm",
        width: "100%",
        fontFamily: "'Courier New', Courier, monospace",
        fontSize: "10px",
        padding: "15px 10px",
        lineHeight: 1.4,
      }}
    >
      {/* Store Header */}
      <div
        style={{
          textAlign: "center",
          marginBottom: "15px",
          paddingBottom: "10px",
          borderBottom: "2px double #333",
        }}
      >
        {store.logo && store.showLogoOnReceipt === true && (
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={store.logo}
              alt="store_logo"
              style={{
                maxHeight: "60px",
                maxWidth: "120px",
                objectFit: "contain",
                filter: "grayscale(100%)",
              }}
            />
          </div>
        )}
        {store.showStoreNameOnReceipt && (
          <>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "600",
                letterSpacing: "0.5px",
              }}
            >
              {store.name.name}
            </div>
            {store.name.localName && (
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  letterSpacing: "0.5px",
                  marginBottom: "4px",
                }}
              >
                {store.name.localName}
              </div>
            )}
          </>
        )}
        {(store.showAddressOnReceipt || store.showPhoneOnReceipt) && (
          <div
            style={{
              fontSize: "10px",
              color: "#444",
              lineHeight: 1.3,
            }}
          >
            {store.showAddressOnReceipt && (
              <>
                {store.address && (
                  <span
                    style={{
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {store.address}
                  </span>
                )}
                <div>
                  {store.city ? `, ${store.city}` : ""}
                  {store.state ? `, ${store.state}` : ""}
                  {store.zip ? `, ${store.zip}` : ""}
                </div>
              </>
            )}
            <br />
            <div style={{ display: "flex", flexDirection: "column" }}>
              {store.showPhoneOnReceipt && store.phone && (
                <span>📞 {store.phone}</span>
              )}
              {store.showEmailOnReceipt && store.email && (
                <span>✉️ {store.email}</span>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Transaction Details */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "8px",
          marginBottom: "15px",
        }}
      >
        <div>
          <div style={{ fontWeight: "600" }}>{t("invoiceNo")}</div>
          <div>#{data.id}</div>
        </div>
        <div>
          <div style={{ fontWeight: "600" }}>{t("date")}</div>
          <div>{data.sellDate && new Date(data.sellDate).toLocaleString()}</div>
        </div>
        {data.cstmr && (
          <>
            <div>
              <div style={{ fontWeight: "600" }}>{t("customer")}</div>
              <div>{data.cstmr.fullName}</div>
            </div>
            <div>
              <div style={{ fontWeight: "600" }}>{t("phone")}</div>
              <div>{data.cstmr.phone}</div>
            </div>
          </>
        )}
      </div>

      {/* Items Table */}
      <div
        style={{
          marginBottom: "15px",
          borderBottom: "2px double #333",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "3fr 1fr 1.5fr",
            gap: "5px",
            fontWeight: "600",
            marginBottom: "8px",
          }}
        >
          <div>{t("itemName")}</div>
          <div style={{ textAlign: "center" }}>{t("qty")}</div>
          <div style={{ textAlign: "right" }}>{t("totalPrice")}</div>
        </div>

        {data.sellItems?.map((item, i) => (
          <div
            key={i}
            style={{
              display: "grid",
              gridTemplateColumns: "3fr 1fr 1.5fr",
              gap: "5px",
              marginBottom: "6px",
            }}
          >
            <div>{item.product.label}</div>
            <div style={{ textAlign: "center" }}>{item.qty}</div>
            <div style={{ textAlign: "right" }}>
              {settings.curSymbol}{" "}
              {(item.qty * item.price).toFixed(settings.decimal)}
            </div>
          </div>
        ))}
      </div>

      {/* Totals Section */}
      <div
        style={{
          display: "grid",
          gap: "6px",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{t("subtotal")}:</span>
          <span>
            {settings.curSymbol} {data.subtotal.toFixed(settings.decimal)}
          </span>
        </div>

        {data.tax > 0 && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              {t("tax")} ({data.taxPct}%):
            </span>
            <span>
              +{settings.curSymbol} {data.tax.toFixed(settings.decimal)}
            </span>
          </div>
        )}

        {data.disAmt > 0 && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>{t("discount")}:</span>
            <span>
              -{settings.curSymbol} {data.disAmt.toFixed(settings.decimal)}
            </span>
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "600",
            paddingTop: "8px",
            borderTop: "1px solid #ddd",
          }}
        >
          <span>{t("totalPay")}:</span>
          <span>
            {settings.curSymbol} {data.totalPay.toFixed(settings.decimal)}
          </span>
        </div>

        {data.payments.map((pay, ind) => (
          <div
            key={ind}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>{pay.payMethod?.title}:</span>
            <span>
              {settings.curSymbol} {pay.amount.toFixed(settings.decimal)}
            </span>
          </div>
        ))}

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{t("totalReceived")}:</span>
          <span>
            {settings.curSymbol} {data.received.toFixed(settings.decimal)}
          </span>
        </div>

        {data.dueAmt > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#d00",
            }}
          >
            <span>{t("due")}:</span>
            <span>
              {settings.curSymbol} {data.dueAmt.toFixed(settings.decimal)}
            </span>
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "600",
            paddingTop: "8px",
            borderTop: "1px solid #ddd",
          }}
        >
          <span>{data.changeAddedCredit ? t("credit") : t("change")}:</span>
          <span>
            {settings.curSymbol} {data.change.toFixed(settings.decimal)}
          </span>
        </div>
      </div>

      {/* Footer */}
      <div
        style={{
          fontSize: "10px",
          color: "#666",
          lineHeight: 1.3,
          borderTop: "2px double #333",
          paddingTop: "10px",
          whiteSpace: "pre-wrap",
          overflowWrap: "break-word",
          maxWidth: "100%",
        }}
      >
        {settings.salesTermForReceipt}
      </div>
    </div>
  ) : null;
};

export default ThermalPrinterRecept;
