import { Paper, Divider } from "@mui/material";
import React, { useContext } from "react";
import { Button } from "../../../controls";
import { Alert, Form, Tabs } from "../../../layout";
import { Model, UseForm } from "../../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { CleanObject, ToFormData } from "../../../hooks/Method";
import { useEffect } from "react";
import { useState } from "react";
import { AppContext } from "../../../App";
import { useTranslation } from "react-i18next";
import { action, controller, Server } from "../../../api/Server";
import GeneralFields from "./GeneralFields";
import ManagerFields from "./ManagerFields";
import AddressFields from "./AddressFields";
import SettingsFields from "./SettingsFields";

const AddEditStore = () => {
  const { t } = useTranslation();
  const { StoreMdl } = Model();
  const { id } = useParams();
  const navigate = useNavigate();
  const { org, selStore } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [currs, setCurrs] = useState([]);
  const [methods, setMethods] = useState([]);
  const { setStores, setPageTitle, pageTitle } = useContext(AppContext);
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleInpDate,
    handleFile,
    handleInpCheck,
    isEmpty,
    checkNoErrors,
  } = UseForm(StoreMdl);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (org && org.id) {
      // Get Currencies
      Server(controller.Currency + action.GetAllByOrg).getById(org.id, (res) =>
        setCurrs([
          ...res.result.map((j) => ({
            ...j,
            label: `${j.currency} (${j.symbol}) - ${j.name}`,
          })),
        ])
      );

      // Get PayMethods
      Server(controller.PayMethod + action.GetAllByStore).getById(
        selStore,
        (res) => {
          if (res && res.status === 200) {
            var result = res.result;
            setMethods([...result.map((j) => ({ ...j, label: j.title }))]);
          }
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org]);

  useEffect(() => {
    !values.id &&
      setValues((j) => ({
        ...j,
        currId: currs[0] ? currs[0].id : "",
        defaultPayMethodId: methods[0] ? methods[0].id : "",
      }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currs, methods]);

  useEffect(() => {
    setPageTitle &&
      setPageTitle(id ? t("update") : t("add") + " " + t("store"));
    if (id) {
      Server(controller.Store + action.GetById).getById(id, (res) => {
        if (res && res.status === 200) {
          setValues({
            ...values,
            ...CleanObject({ ...res.result }),
            tax: res.result.tax,
          });
        }
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateForm = () => {
    let temp = {
      storeName: isEmpty(values.storeName, t("storeNameIsRequired")),
      phone: isEmpty(values.phone, t("phoneIsRequired")),
      currId: isEmpty(values.currId, t("currencyIsRequired")),
      emailConfig: {},
    };

    if (values.useOwnEmail === true) {
      temp.emailConfig.senderAddress = isEmpty(
        values.emailConfig.senderAddress,
        t("senderAddressIsReq")
      );
      temp.emailConfig.senderDisplayName = isEmpty(
        values.emailConfig.senderDisplayName,
        t("senderDisplayNameIsReq")
      );
      temp.emailConfig.userName = isEmpty(
        values.emailConfig.userName,
        t("userNameIsRequired")
      );
      temp.emailConfig.password = isEmpty(
        values.emailConfig.password,
        t("passwordIsRequired")
      );
      temp.emailConfig.host = isEmpty(values.emailConfig.host, t("hostIsReq"));
      temp.emailConfig.port = isEmpty(values.emailConfig.port, t("portIsReq"));
    }
    setErrors({ ...temp });

    // Check for all errors including nested objects
    return checkNoErrors(temp);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const onComplete = (res) => {
      setLoading(false);
      if (res) {
        if (res.status === 201 || res.status === 200) {
          setAlert({
            isOpen: true,
            type: "success",
            title: t("success"),
            subTitle: `${t("store")} ${id ? t("updated") : t("added")} ${t(
              "successfully"
            )}`,
            onClick: () => navigate("/stores"),
          });
          id
            ? setStores((x) => [
                ...x.map((j) =>
                  j.id === res.result.id
                    ? { ...res.result, label: res.result.storeName }
                    : j
                ),
              ])
            : setStores((x) => [
                { ...res.result, label: res.result.storeName },
                ...x,
              ]);
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: t("error"),
            subTitle: res.msg,
          });
      }
    };
    if (validateForm()) {
      setLoading(true);
      if (!id) {
        Server(controller.Store + action.Post).post(
          ToFormData(values),
          onComplete
        );
      } else {
        Server(controller.Store + action.Put).put(
          ToFormData({ ...values, updateDate: new Date().toLocaleString() }),
          onComplete
        );
      }
    }
  };

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Paper>
          <div className="mb-10 d-flex j-between a-center pt-10 pl-10 pr-10">
            <div>
              <h1 className="form-title ml-10 fw-4 gray-color tx-upp border-left">
                {pageTitle}
              </h1>
            </div>

            <div>
              <Button type="submit" size="large" loading={loading}>
                {id ? t("saveChanges") : t("submit")}
              </Button>
            </div>
          </div>
          <Divider />
          <div className="pl-15 pr-15 pb-15">
            <Tabs
              className="mt-30"
              header={[
                { label: t("storeDetails"), icon: "" },
                { label: t("settings"), icon: "" },
                { label: t("addressAndLogo"), icon: "" },
                { label: t("managerDetails"), icon: "" },
              ]}
            >
              <GeneralFields
                {...{
                  values,
                  errors,
                  handleInpChg,
                  handleFile,
                  setValues,
                  handleInpCheck,
                }}
              />

              <SettingsFields
                {...{
                  values,
                  currs,
                  methods,
                  errors,
                  handleInpChg,
                  handleInpCheck,
                }}
              />

              <AddressFields
                {...{ values, setValues, errors, handleInpChg, handleFile }}
              />
              <ManagerFields
                {...{ values, errors, handleInpChg, handleInpDate }}
              />
            </Tabs>
          </div>
        </Paper>
      </Form>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default AddEditStore;
