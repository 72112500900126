import React, { useState, useContext } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Grid,
  Paper,
  InputAdornment,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { AppContext } from "../App";
import Form from "./Form";
import { Select } from "../controls";

export default function TableWithPg(props) {
  const {
    tblHead = [],
    tblData = [],
    FooterRow,
    showSearch = true,
    HeaderComp = <div></div>,
    ActionComp = <div></div>,
    searchOptions = [],
    totalRecord = 0,
    onChange,
    onPageChg,
    onSubmit,
    pageNum,
    setPageNum,
  } = props;
  const { rowsPerTable } = useContext(AppContext);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerTable);
  const [selOpt, setSelOpt] = useState(searchOptions[0]?.id);
  const [searchVal, setSearchVal] = useState("");

  const handleChangePage = (event, newPage) => {
    setPageNum(newPage);
    if (searchVal && onSubmit) {
      onSubmit(selOpt, searchVal, rowsPerPage);
    } else if (onPageChg) onPageChg(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    var rows = +event.target.value;
    setRowsPerPage(rows);
    setPageNum(0);
    if (searchVal && onSubmit) {
      onSubmit(selOpt, searchVal, rows);
    } else if (onPageChg) onPageChg(pageNum, rows);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit && onSubmit(selOpt, searchVal, rowsPerPage);
  };

  const handleSerach = (e) => {
    var _searchVal = e.target.value;
    setSearchVal(_searchVal);
    onChange(selOpt, _searchVal);
    // Reset Table, if search box will empty
    !_searchVal && onSubmit(selOpt, "", rowsPerPage);
  };

  const handleSearchOpt = (e) => {
    var _searchType = e.target.value;
    setSelOpt(_searchType);
    setSearchVal("");

    onChange(_searchType, searchVal);

    setPageNum(0);
    onPageChg(0, rowsPerPage);
  };

  return (
    <>
      <Box className="d-flex j-between items-center flex-wrap mb-15 gap-2 bg-white p-5 br-8">
        <Box
          className="flex !w-full"
          // sx={{
          //   minWidth: { xs: "100%", lg: "250px" },
          // }}
        >
          <HeaderComp />
        </Box>
        {showSearch && (
          <Form onSubmit={handleSubmit}>
            <Grid className="flex gap-2 w-full xl:w-auto">
              <Select
                onChange={handleSearchOpt}
                value={selOpt}
                options={searchOptions}
                size="small"
                fullWidth
              />

              <TextField
                name="search"
                placeholder="search"
                size="small"
                onChange={handleSerach}
                value={searchVal}
                sx={{
                  "& .MuiInputBase-root": {
                    paddingRight: "2px",
                  },
                }}
                className="w-[100%]"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit" size="small" sx={{ p: "5px" }}>
                        <SearchRoundedIcon size="inherit" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Form>
        )}
      </Box>

      {/* Table  */}
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <TableContainer sx={{ maxHeight: "calc(100vh - 184px)" }}>
          <MuiTable stickyHeader>
            <TableHead>
              <TableRow>
                {tblHead.map((item, i) => (
                  <TableCell
                    key={i}
                    sx={{
                      fontSize: "14px",
                      backgroundColor: "var(--white-color)",
                      color: "var(--primary-color)",
                      borderBottom: "1px solid var(--primaryMD-color)",
                      minWidth: "80px",
                      textTransform: "uppercase",
                      padding: "16px 8px 16px 16px",
                    }}
                  >
                    {item.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tblData.map((item, i) => {
                return (
                  <TableRow hover key={i}>
                    {tblHead.map((headItem, index) => {
                      return (
                        <TableCell
                          key={index}
                          sx={{
                            fontSize: "16px",
                            padding: "6px 16px 6px 16px",
                            whiteSpace: "nowrap",
                            color: "var(--gray-color)",
                          }}
                        >
                          {headItem.id === "actions" ? (
                            <ActionComp {...item} />
                          ) : (
                            item[headItem.id]
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              <TableRow>{FooterRow && <FooterRow />}</TableRow>
            </TableBody>
          </MuiTable>

          {!tblData.length && (
            <Typography
              variant="h6"
              className="tx-center p-30 tx-upp gray-color"
            >
              {totalRecord === null
                ? "Loading..."
                : totalRecord === 0 && "Data not avaiable"}
            </Typography>
          )}
        </TableContainer>
        {totalRecord > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            labelRowsPerPage="Row"
            count={totalRecord}
            rowsPerPage={rowsPerPage}
            page={pageNum}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </>
  );
}
