import React, { useEffect } from "react";
import { ClearRounded, EditRounded } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { Alert, Dialog, Table } from "../../layout";
import AddEditCat from "./AddEditCat";
import { useContext } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import { action, controller, Server } from "../../api/Server";

const Categories = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [updData, setUpdData] = useState();
  const [cats, setCats] = useState([]);
  const { selStore, setLoader, setPageTitle, authUser } =
    useContext(AppContext);
  const [access, setAccess] = useState({});
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setPageTitle(t("categories"));

    setLoader(true);
    Server(controller.Category + action.GetAllByStore).getById(
      selStore,
      (res) => {
        setLoader(false);
        res && res.status === 200 && setCats([...res.result]);
      }
    );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    if (authUser && authUser.featuresAccess && authUser.featuresAccess.category)
      setAccess({ ...authUser.featuresAccess.category });
    return () => {};
  }, [authUser]);

  const handleAddEdit = (val) => {
    setUpdData(val);
    setShowForm(true);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("catDltTitle"),
      onConfirm: () => {
        const onComplete = (res) => {
          if (res && res.status === 200) {
            setCats([...cats.filter((j) => j.id !== res.result.id)]);
            setAlert({
              isOpen: true,
              type: "success",
              title: t("dltSuccessTitle"),
              subTitle: res.msg,
            });
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: t("warning"),
              subTitle: res.msg,
            });
          }
        };
        Server(controller.Category).Delete(id, onComplete);
      },
    });
  };

  const getParentCat = (catId) => {
    const cat = cats.find((j) => j.id === catId);
    return cat !== undefined ? cat.name : "";
  };

  const ActionComp = (props) => (
    <Box>
      {access.isHaveEditAccess && (
        <Tooltip arrow title={t("edit")}>
          <IconButton
            onClick={() => handleAddEdit(props)}
            size="small"
            className="btn-primary mr-5"
          >
            <EditRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      )}
      {access.isHaveDeleteAccess && (
        <Tooltip arrow title={t("delete")}>
          <IconButton
            onClick={() => handleDlt(props.id)}
            size="small"
            color="error"
          >
            <ClearRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );

  const HeaderComp = () =>
    access.isHaveAddAccess ? (
      <Button
        onClick={() => handleAddEdit()}
        className="btn-secondary"
        sx={{
          width: { md: "180px", xs: "100%" },
          mb: { xs: "10px", md: "0px" },
          fontSize: "17px",
          padding: "8px",
        }}
      >
        {t("addCategory")}
      </Button>
    ) : (
      <></>
    );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          { id: "name", label: t("name") },
          { id: "descr", label: t("description") },
          { id: "parent", label: t("parentCategory") },
        ]}
        tblData={cats.map((obj) => {
          return {
            ...obj,
            parent: getParentCat(obj.parentCatId),
          };
        })}
      />
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {/* Edit Dialog */}
      {showForm && (
        <Dialog show={showForm} onClose={false}>
          <AddEditCat {...{ updData, setShowForm, setCats }} />
        </Dialog>
      )}
    </>
  );
};

export default Categories;
