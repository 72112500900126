import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { useTranslation } from "react-i18next";
import { ChangeArrayKey } from "../../../hooks/Method";
import { Box, IconButton, Tooltip } from "@mui/material";
import { CSVLink } from "react-csv";
import { Dialog, Table } from "../../../layout";
import { action, controller, Server } from "../../../api/Server";
import { Visibility } from "@mui/icons-material";
import ViewChanges from "./ViewChanges";

const ActivityLogs = () => {
  const { t } = useTranslation();
  const { setPageTitle } = useContext(AppContext);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [viewData, setViewData] = useState();
  const [showViewForm, setShowViewForm] = useState(false);

  useEffect(() => {
    setPageTitle && setPageTitle(t("activityLog"));
    setCsvHeader([
      { key: "userDetails", label: t("user") },
      { key: "actionName", label: t("action") },
      { key: "tableName", label: t("tableName") },
      { key: "recordId", label: t("recordId") },
      { key: "date", label: t("date") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Action = ({ type }) => {
    switch (type) {
      case 1:
        return (
          <span className="bg-successXS success-color fw-5 pr-10 pl-10 pt-5 pb-5 br-25 fs-12 tx-upp">
            {t("create")}
          </span>
        );
      case 2:
        return (
          <span className="bg-primarySM primary-color pr-10 pl-10 pt-5 pb-5 br-25 fs-12 tx-upp">
            {t("update")}
          </span>
        );
      case 3:
        return (
          <span className="bg-errorSM error-color pr-10 pl-10 pt-5 pb-5 br-25 fs-12 tx-upp">
            {t("delete")}
          </span>
        );

      default:
        break;
    }
  };

  useEffect(() => {
    Server(controller.ActivityLog + action.GetAllByOrg).get((res) => {
      res &&
        res.status === 200 &&
        setTblData([
          ...res.result.map((j) => ({
            ...j,
            actionName:
              j.action === 1 ? "create" : j.action === 2 ? "update" : "delete",
            userDetails: j.user
              ? `${j.user.fullName} (${j.user.phone || j.user.email})`
              : "",
            action: <Action type={j.action} />,
            date: j.timestamp && new Date(j.timestamp).toLocaleString(),
          })),
        ]);
    });
    return () => {};
  }, []);

  const handleView = (val) => {
    setViewData(val);
    setShowViewForm(true);
  };

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={tblData}
          headers={csvHeader}
          filename={`${t("productReport")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  const ActionComp = (props) => (
    <Tooltip arrow title={t("view")}>
      <IconButton
        onClick={() => handleView(props)}
        size="small"
        className="btn-primary mr-5"
      >
        <Visibility className="fs-18 m-2" />
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "action", label: t("action") },
          ...ChangeArrayKey(
            csvHeader.filter((j) => j.key !== "actionName"),
            "key",
            "id"
          ),
        ]}
        tblData={tblData}
      />

      {/* View Changes Dialog */}
      {showViewForm && (
        <Dialog show={showViewForm} onClose={false} maxWidth="md">
          <ViewChanges {...{ viewData, setShowForm: setShowViewForm }} />
        </Dialog>
      )}
    </>
  );
};

export default ActivityLogs;
