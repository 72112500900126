import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Input, InputImg } from "../../../controls";

const AddressFields = ({
  values,
  setValues,
  errors,
  handleInpChg,
  handleFile,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2.5}>
      <Grid item xs={12}>
        <Input
          label={t("address")}
          name="address"
          value={values.address}
          error={errors.address}
          onChange={handleInpChg}
          fullWidth
          multiline
          rows={3}
          inputProps={{ maxLength: 250 }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label={t("city")}
          name="city"
          value={values.city}
          error={errors.city}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 200 }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label={t("state")}
          name="state"
          value={values.state}
          error={errors.state}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 200 }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label={t("ZIP")}
          name="zip"
          value={values.zip}
          error={errors.zip}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 50 }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <InputImg
          label={t("logo")}
          name="logoFile"
          src={values.logo}
          value={values.logoFile}
          onChange={handleFile}
          error={errors.logoFile}
          rmv={() => {
            setValues({ ...values, logoFile: "", logo: "" });
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AddressFields;
