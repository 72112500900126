import React, { useContext, useEffect, useState } from "react";
import { Grid, InputAdornment, Typography } from "@mui/material";
import { Input, Button } from "../../controls";
import { Form } from "../../layout";
import { Model, UseForm } from "../../hooks";
import { CleanObject, ToFormData } from "../../hooks/Method";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import InputSwitch from "../../controls/InputSwitch";
import { PercentRounded } from "@mui/icons-material";
import { action, controller, Server } from "../../api/Server";

const AddEditMethod = (props) => {
  const { updData, setShowForm, setTblData, setAlert } = props;
  const { t } = useTranslation();
  const { selStore } = useContext(AppContext);
  const { PayMethodMdl } = Model();
  const [loading, setLoading] = useState(false);
  const { values, setValues, errors, setErrors, handleInpChg, handleInpCheck } =
    UseForm(PayMethodMdl);

  const validateForm = () => {
    let temp = {};
    temp.title = values.title !== "" ? "" : t("titleIsReq");
    if (values.isAddtlCharge) {
      temp.addtlChargeTitle =
        values.addtlChargeTitle !== "" ? "" : t("addtlChargeIsReq");
      temp.addtlCharge =
        values.addtlCharge !== "" ? "" : t("addtlChargeTitleIsReq");
    }

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowForm(true);
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res && (res.status === 201 || res.status === 200)) {
          if (setTblData && res.result.id) {
            var result = res.result;
            var val = {
              ...result,
              haveAdditionalCharge: result.isAddtlCharge ? "Yes" : "No",
              createdUser: result.user && result.user.fullName,
              createDate:
                result.createDate &&
                new Date(result.createDate).toLocaleString(),
              updateDate:
                result.updateDate &&
                new Date(result.updateDate).toLocaleString(),
            };
            updData
              ? setTblData((x) => [
                  ...x.map((j) => (j.id === val.id ? { ...val } : j)),
                ])
              : setTblData((x) => [{ ...val }, ...x]);
          }
          setAlert({
            isOpen: true,
            type: "success",
            title: t("success"),
            subTitle: res.msg,
          });
          setShowForm(false);
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: t("error"),
            subTitle: res.msg,
          });
      };

      if (updData && updData.id) {
        Server(controller.PayMethod + action.Put).put(
          ToFormData({
            ...values,
            updateDate: new Date().toLocaleString(),
            org: "",
            store: "",
          }),
          onComplete
        );
      } else {
        Server(controller.PayMethod + action.Post).post(
          ToFormData({ ...values, storeId: selStore, org: "", store: "" }),
          onComplete
        );
      }
    }
  };

  useEffect(() => {
    updData &&
      setValues((j) => ({
        ...j,
        ...CleanObject(updData),
      }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData]);

  return (
    <div className="bg-grayXS">
      <Form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2.5} className="p-20 pt-40">
          <Grid item xs={12} className="tx-center">
            <Typography variant="h5" className="fs-22 gray-color fw-5 tx-upp">
              {updData === undefined ? t("addNew") : t("update")} {t("method")}
            </Typography>
            <div className="bs-bottom mx-w-80 m-auto mt-10 mb-30" />
          </Grid>
          <Grid item xs={12}>
            <Input
              label={t("title")}
              name="title"
              required={true}
              value={values.title}
              error={errors.title}
              inputProps={{ maxLength: 50 }}
              onChange={handleInpChg}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label={t("subtitle")}
              name="subtitle"
              value={values.subtitle}
              error={errors.subtitle}
              inputProps={{ maxLength: 50 }}
              onChange={handleInpChg}
            />
          </Grid>
          <Grid item xs={12}>
            <InputSwitch
              label={t("addtlCharge")}
              checked={values.isAddtlCharge}
              name="isAddtlCharge"
              onChange={handleInpCheck}
              className="shadow-xs"
            />
          </Grid>
          {values.isAddtlCharge && (
            <>
              <Grid item xs={12} md={8}>
                <Input
                  label={t("addtlChargeTitle")}
                  name="addtlChargeTitle"
                  required
                  value={values.addtlChargeTitle}
                  error={errors.addtlChargeTitle}
                  inputProps={{ maxLength: 30 }}
                  onChange={handleInpChg}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  label={t("charge")}
                  name="addtlCharge"
                  required
                  value={values.addtlCharge}
                  error={errors.addtlCharge}
                  type="number"
                  onChange={handleInpChg}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PercentRounded />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          )}

          <Grid
            item
            xs={12}
            className="tx-right d-flex mt-20"
            sx={{ flexWrap: { xs: "wrap", md: "initial" } }}
          >
            <Button
              loading={loading}
              type="submit"
              className="w-100pr btn-primary"
              size="large"
            >
              {updData === undefined ? t("submit") : t("saveChanges")}
            </Button>
            <Button
              onClick={() => setShowForm(false)}
              className="error-color bg-errorSM w-100pr"
              size="large"
              sx={{ ml: { md: "10px" }, mt: { xs: "15px", md: "0px" } }}
            >
              {t("close")}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

export default AddEditMethod;
