import React, { useContext, useEffect, useState } from "react";
import { Divider, Paper } from "@mui/material";
import { Button } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { CleanObject, RandomCode, ToFormData } from "../../../hooks/Method";
import { Alert, Snackbar } from "../../../layout";
import { AppContext } from "../../../App";
import { useTranslation } from "react-i18next";
import { action, Server, controller } from "../../../api/Server";
import GeneralFields from "./GeneralFields";
import AdditionalFields from "./AdditionalFields";

const AddEditProd = (props) => {
  const { setShowPurchForm } = props;
  const { t } = useTranslation();
  const { ProdMdl } = Model();
  const { id } = useParams();
  const navigate = useNavigate();
  const { selStore, setPageTitle, authUser } = useContext(AppContext);
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    resetFormControls,
    handleFile,
  } = UseForm(ProdMdl);

  const [imgSrc, setImgSrc] = useState([]);
  const [loader, setLoader] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const [snackbar, setSnackbar] = useState({
    type: "success",
    msg: "",
    show: false,
  });

  useEffect(() => {
    setPageTitle((id ? t("update") : t("add")) + (" " + t("product")));
    if (
      authUser &&
      authUser.featuresAccess &&
      authUser.featuresAccess.product
    ) {
      var _access = authUser.featuresAccess.product;
      !_access.isHaveAddAccess &&
        !_access.isHaveEditAccess &&
        navigate("/dashboard");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  useEffect(() => {
    // Get product details.
    id &&
      Server(controller.Product + action.GetById).getById(id, (res) => {
        if (res && res.status === 200) {
          var val = CleanObject({ ...res.result });
          setValues((x) => ({
            ...x,
            ...val,
            sellPrices: val.sellPrices.map((price) => ({
              ...price,
              key: RandomCode(15),
            })),
          }));
          val.imgs && setImgSrc(val.imgs.map((j) => ({ ...j, src: j.imgUrl })));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateForm = () => {
    let temp = {};
    temp.name = values.name !== "" ? "" : t("nameIsRequired");
    temp.barcode = values.barcode !== "" ? "" : t("barcodeIsRequired");
    temp.unit = values.unit !== "" ? "" : t("unitTypeIsTequired");
    temp.purchPrice =
      values.purchPrice !== "" ? "" : t("purchasePriceIsIequired");
    temp.sellPrices =
      values.sellPrices && values.sellPrices.length > 0
        ? ""
        : t("sellPriceIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (isSaveExit = false) => {
    if (validateForm()) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        if (res.status === 201 || res.status === 200) {
          if (isSaveExit) {
            setAlert({
              isOpen: true,
              type: "success",
              title: t("success"),
              subTitle: res.msg,
              onClick: () => navigate("/products"),
            });
          } else {
            resetFormControls();
            setImgSrc([]);
            setSnackbar({
              type: "success",
              show: true,
              msg: t("prodAddTitle"),
            });
          }
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: t("error"),
            subTitle: res.msg,
          });
      };

      if (!id) {
        values.userId = authUser.id;
        values.storeId = selStore;
        Server(controller.Product).post(ToFormData(values), onComplete);
      } else if (id) {
        Server(controller.Product).put(
          ToFormData({ ...values, updateDate: new Date().toISOString() }),
          onComplete
        );
      }
    }
  };

  return (
    <>
      <Paper>
        <div className="mb-10 d-flex flex-wrap g-20 j-between a-center pt-10 pl-10 pr-10">
          <div>
            <span className="fs-20 fw-5 fw-4 gray-color tx-upp border-left">
              {id === undefined ? t("add") : t("update")} {t("product")}
            </span>
          </div>

          <div>
            {setShowPurchForm && (
              <Button
                loading={loader}
                onClick={() => setShowPurchForm(false)}
                sx={{
                  width: { md: "150px", xs: "100%" },
                  mr: { md: "10px", xs: "0px" },
                  mb: { md: "0px", xs: "10px" },
                }}
              >
                {t("close")}
              </Button>
            )}
            {id === undefined && !setShowPurchForm && (
              <Button
                loading={loader}
                onClick={() => handleSubmit(true)}
                sx={{
                  minWidth: { md: "150px", xs: "100%" },
                  mr: { md: "10px", xs: "0px" },
                  mb: { xs: "10px", md: "0px" },
                }}
                className="pr-10 pl-10"
              >
                {t("saveAndExit")}
              </Button>
            )}
            <Button
              loading={loader}
              onClick={() => handleSubmit(id === undefined ? false : true)}
              sx={{ minWidth: { md: "230px", xs: "100%" } }}
            >
              {id === undefined ? t("saveAndAddAnother") : t("saveChanges")}
            </Button>
          </div>
        </div>
        <Divider />
        <div className="p-15 d-grid g-25">
          <GeneralFields
            {...{ values, setValues, errors, setErrors, handleInpChg }}
          />
          <AdditionalFields
            {...{
              imgSrc,
              values,
              setValues,
              errors,
              setErrors,
              handleInpChg,
              handleFile,
            }}
          />
        </div>
      </Paper>
      <Snackbar {...{ snackbar, setSnackbar }} />
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default AddEditProd;
