import { Button, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Input, Select } from "../../../controls";
import { Form } from "../../../layout";
import { Model, UseForm } from "../../../hooks";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/pos";
import { endpoints } from "../../../api/actions/api";
import { MaxLength, ToFormData } from "../../../hooks/Method";
import { AppContext } from "../../../App";
import { useTranslation } from "react-i18next";

const AddEditBills = (props) => {
  const { updData, setShowForm, _post, _put, _get, _authUser, setTblData } =
    props;
  const { t } = useTranslation();
  const { ExpsMdl } = Model();
  const { selStore } = useContext(AppContext);
  const [expenseCats, setExpenseCats] = useState([]);

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    resetFormControls,
  } = UseForm(ExpsMdl);

  const validateForm = () => {
    let temp = {};
    temp.expenseCatId =
      values.expenseCatId !== "" ? "" : t("payTypeIsRequired");
    temp.amount = values.amount !== "" ? "" : t("totalIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      values.userId = _authUser.id;
      const onComplete = (res) => {
        setShowForm(false);
        if (res && res.result && res.status === 200)
          setTblData((j) => [
            ...j.map((x) => (x.id === res.result.id ? { ...res.result } : x)),
          ]);
        else if (res && res.result && res.status === 201)
          setTblData((j) => [res.result, ...j]);
      };
      if (updData === undefined) {
        values.storeId = selStore;
        _post(
          endpoints.Expense,
          ToFormData(values),
          actions.ActionType.AddItem,
          onComplete
        );
      } else if (updData !== undefined && updData.id !== undefined) {
        _put(
          endpoints.Expense,
          ToFormData(values),
          actions.ActionType.UpdateItem,
          onComplete
        );
      }
    }
  };

  useEffect(() => {
    // Set Expense Category
    const onComplete = (res) => {
      res.status === 200 &&
        res.result.length > 0 &&
        setExpenseCats(
          res.result
            .sort((f, s) => f.id - s.id)
            .map((j) => {
              return {
                id: j.id,
                label: j.name,
              };
            })
        );
    };
    _get(endpoints.ExpenseCat, null, onComplete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      updData && setValues(updData);
    }, 300);
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData]);

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={3} className="p-20 pt-40 bg-grayXS">
        <Grid item xs={12} className="tx-center">
          <Typography variant="h5" className="fs-22 gray-color fw-5 tx-upp">
            {updData === undefined ? t("add") : t("update")} {t("bill")}
          </Typography>
          <div className="bs-bottom mx-w-60 m-auto mb-20" />
        </Grid>
        <Grid item xs={12}>
          <Select
            label={t("payType")}
            name="expenseCatId"
            value={values.expenseCatId}
            error={errors.expenseCatId}
            onChange={handleInpChg}
            options={expenseCats}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={t("total")}
            name="amount"
            onInput={(e) => (e.target.value = MaxLength(e.target.value, 12))}
            value={values.amount}
            error={errors.amount}
            onChange={handleInpChg}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={t("note")}
            multiline
            rows={3}
            name="note"
            inputProps={{ maxLength: 400 }}
            value={values.note}
            error={errors.note}
            onChange={handleInpChg}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          className="tx-right d-flex mt-20"
          sx={{ flexWrap: { xs: "wrap", md: "initial" } }}
        >
          <Button type="submit" className="w-100pr" size="large">
            {updData === undefined ? t("submit") : t("saveChanges")}
          </Button>
          <Button
            onClick={() => setShowForm(false)}
            className="error-color bg-errorSM w-100pr"
            size="large"
            sx={{ ml: { md: "10px" }, mt: { xs: "15px", md: "0px" } }}
          >
            {t("close")}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _authUser: state.pos.authUser,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditBills);
