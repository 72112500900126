import { ForwardToInboxRounded, VisibilityRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { Dialog, Snackbar, Table } from "../../layout";
import ViewProd from "./ViewProd";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { ChangeArrayKey, IsEmptyObj } from "../../hooks/Method";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { action, controller, Server } from "../../api/Server";

const Sells = () => {
  const { t } = useTranslation();
  const { setLoader, loader, selStore, settings, setPageTitle, authUser } =
    useContext(AppContext);
  const [viewData, setViewData] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [access, setAccess] = useState({});
  const [snackbar, setSnackbar] = useState({
    show: false,
    type: "success",
    msg: "",
  });

  useEffect(() => {
    if (authUser && authUser.featuresAccess && authUser.featuresAccess.sells)
      setAccess({ ...authUser.featuresAccess.sells });
    return () => {};
  }, [authUser]);

  useEffect(() => {
    if (selStore) {
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 &&
          setTblData(
            res.result.map((obj) => {
              return {
                ...obj,
                sellType: obj.sellType === 1 ? "Paid" : "Due",

                totalItem: obj.sellItems.length,
                method:
                  obj.payments &&
                  obj.payments
                    .map((j) => (j.payMethod ? j.payMethod.title : ""))
                    .join(" | "),
                subtotal:
                  settings.curSymbol +
                  " " +
                  obj.subtotal.toFixed(settings.decimal),
                received:
                  settings.curSymbol +
                  " " +
                  obj.received.toFixed(settings.decimal),
                change:
                  settings.curSymbol +
                  " " +
                  obj.change.toFixed(settings.decimal),
                username: obj.user.fullName,
                customerName: obj.cstmr ? obj.cstmr.fullName : "-",
              };
            })
          );
      };

      setLoader(true);
      Server(controller.Sell + action.GetAllByStore).getById(
        selStore,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore, settings]);

  const handleEmailReceipt = (invoiceId) => {
    setLoader(true);
    Server(controller.Sell + action.EmailInvoice).getById(invoiceId, (res) => {
      setLoader(false);
      if (res && res.status === 200)
        setSnackbar({
          show: true,
          type: "success",
          msg: "You have successfully sent email the invoice.",
        });
      else
        setSnackbar({
          show: true,
          type: "error",
          msg: res.msg,
        });
    });
  };

  useEffect(() => {
    setPageTitle && setPageTitle(t("sells"));
    setCsvHeader([
      { key: "sellType", label: t("sellType") },
      { key: "sellDate", label: t("transectionDate") },
      { key: "id", label: t("invoice") },
      { key: "totalItem", label: t("totalItem") },
      { key: "method", label: t("method") },
      { key: "subtotal", label: t("subtotal") },
      { key: "received", label: t("paymentReceived") },
      { key: "change", label: t("change") },
      { key: "username", label: t("cashier") },
      { key: "customerName", label: t("customer") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleView = (data) => {
    setViewData(data);
    setShowForm(!showForm);
  };

  const ActionComp = (props) => (
    <Box className="d-flex g-5">
      {access.isHaveViewAccess && (
        <>
          <Tooltip arrow title={t("view")}>
            <IconButton
              onClick={() => handleView(props)}
              size="small"
              className="mr-5"
              color="primary"
            >
              <VisibilityRounded className="fs-18 m-2" />
            </IconButton>
          </Tooltip>

          <IconButton
            onClick={() => handleEmailReceipt(props.id)}
            size="small"
            className="mr-5"
            color="primary"
            disabled={loader}
          >
            <ForwardToInboxRounded className="fs-18 m-2" />
          </IconButton>
        </>
      )}
    </Box>
  );

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      {access.isHaveExportAccess && (
        <Box
          className="d-flex"
          sx={{
            width: { xs: "100%", md: "auto" },
          }}
        >
          <CSVLink
            data={tblData}
            headers={csvHeader}
            filename={`${t("sells")} (${new Date().toDateString()}).csv`}
            target="_blank"
            className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
          >
            {t("exportCSV")}
          </CSVLink>
        </Box>
      )}
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          ...ChangeArrayKey(csvHeader, "key", "id"),
        ]}
        tblData={tblData.map((item) => {
          return {
            ...item,
            searchTerm: `status ${item.sellType}`,
            sellDate: new Date(item.sellDate).toLocaleString(),
            sellType: (
              <div className="fw-5">
                {item.sellType === "Due" ? (
                  <span className="bg-errorSM error-color pr-10 pl-10 pt-5 pb-5 br-25 fs-12 tx-upp">
                    {t("due")}
                  </span>
                ) : (
                  <span className="bg-successXS success-color pr-10 pl-10 pt-5 pb-5 br-25 fs-12 tx-upp">
                    {t("paid")}
                  </span>
                )}
              </div>
            ),
          };
        })}
      />

      {/* Edit Dialog */}
      <Dialog show={showForm} maxWidth="lg" onClose={false}>
        {!IsEmptyObj(viewData) && (
          <ViewProd {...{ setShowForm, id: viewData.id, viewData }} />
        )}
      </Dialog>
      <Snackbar {...{ snackbar, setSnackbar }} />
    </>
  );
};

export default Sells;
