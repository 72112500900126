import { Divider, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../controls";
import { Model, UseForm } from "../../hooks";
import { Alert, Form, Tabs } from "../../layout";
import { Currencies } from "../../hooks/Currencies";
import { AppContext } from "../../App";
import { CleanObject, ToFormData } from "../../hooks/Method";
import { connect } from "react-redux";
import { endpoints } from "../../api/actions/api";
import * as actions from "../../api/actions/pos";
import { useTranslation } from "react-i18next";
import GeneralFields from "./GeneralFields";
import AddressFields from "./AdditionalFields";

const OrgForm = (props) => {
  const { _put } = props;
  const { t } = useTranslation();
  const { OrgMdl } = Model();
  const { org, setOrg, setPageTitle } = useContext(AppContext);
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    resetFormControls,
  } = UseForm(OrgMdl);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setPageTitle(t("organization"));
    org && setValues((x) => ({ ...x, ...CleanObject(org) }));
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org]);

  const validateForm = () => {
    let temp = {};
    temp.name = values.name !== "" ? "" : t("nameIsRequired");
    temp.email = values.email !== "" ? "" : t("emailIsRequired");
    temp.phone = values.phone !== "" ? "" : t("phoneIsRequired");
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const onComplete = (res) => {
        if (res && res.status === 200) {
          if (res.result) {
            setValues((x) => ({ ...x, ...res.result }));
            // Update Global Value
            var _org = res.result;
            var currency = Currencies.find((j) => j.id === _org.currency);
            setOrg({
              ..._org,
              curSymbol: currency && currency.symbol_native,
            });
          }
          setAlert({
            isOpen: true,
            type: "success",
            title: t("success"),
            subTitle: t("organizationDetailsUpdatedSuccessfully"),
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: t("error"),
            subTitle: res.msg,
          });
        setLoading(false);
      };
      _put(endpoints.Org, ToFormData(values), null, onComplete);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Paper>
          <div className="mb-10 d-flex j-between a-center pt-10 pl-10 pr-10">
            <div>
              <h1 className="form-title ml-10 fw-4 gray-color tx-upp border-left">
                {t("editOrganizationDetails")}
              </h1>
            </div>

            <div>
              <Button type="submit" loading={loading}>
                {t("saveChanges")}
              </Button>
            </div>
          </div>
          <Divider />
          <div className="pl-15 pr-15 pb-15 mt-30">
            <Tabs
              className="mt-10"
              header={[
                { label: t("generalFields"), icon: "" },
                { label: t("addressFields"), icon: "" },
              ]}
            >
              <GeneralFields
                {...{ values, setValues, errors, org, handleInpChg }}
              />
              <AddressFields {...{ values, setValues, errors, handleInpChg }} />
            </Tabs>
          </div>
        </Paper>
      </Form>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _put: actions.put,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(OrgForm);
